import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from "crypto-js"
import { Observable, throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { catchError } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";
import { graph } from "../../../constants/api/end-points";
import { respuestaActualizarDatos } from 'src/app/constants/interfaces/salesforceInterfaces';
import { initialSalesforce, B2C, oracle } from "./../../../constants/api/end-points";

@Injectable({
  providedIn: 'root'
})
export class ModalActualizarPerfilService {

  constructor(private http: HttpClient, private authService: MsalService) { }

  updateEmailAttribute(email: string, numeroCelular: string): Observable<{}> {
    //PRIMERO SE DEBE DE OBTENER EL NÚMERO DE INTENTOS PARA ACTUALIZAR EL CONTADOR
    return this.http.put<{}>(
      graph.userAttributes,
      {
        "userid": this.authService.getAccount().idTokenClaims.sub,
        "attributes": [
          { "key": "Correo", "value": `${email}` },
          { "key": "NumeroCelular", "value": `${numeroCelular}` }
        ]
      },
      { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}` }) }
    ).pipe(
      catchError(e => throwError({ origin: 'consulta de actualización de atributos', error: e }))
    )
  }
  updatePersonalData(document: string, correo: string, numeroCelular: string): Observable<respuestaActualizarDatos> {
    return this.http.post<respuestaActualizarDatos>(
      initialSalesforce.actualizarMovilCorreo,
      {
        "Encabezado": {
          "destino": "MICROSITIO"
        },
        "Cuerpo": {
          "tipoDocumento": document,
          "numeroDocumento": CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8),
          "celular": numeroCelular,
          "correo": correo
        }
      },
      {
        headers: new HttpHeaders({
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}`,
          'Header-SF': localStorage.getItem('tokenSalesforce')
        })
      }
    ).pipe(
      catchError(e => throwError({ origin: "update personal data", error: e }))
    )
  }
  savePersonalData(email: string, numeroCelular: string): Observable<{}> {
    return this.http.put<{}>(
      graph.userAttributes,
      {
        "userid": this.authService.getAccount().idTokenClaims.sub,
        "attributes": [
          { "key": "ActualizarDatos", "value": `${numeroCelular}` + "_" + `${email}` },
        ]
      },
      { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}` }) }
    ).pipe(
      catchError(e => throwError({ origin: 'actualización de atributos', error: e }))
    )
  }
}
