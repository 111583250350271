import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from "./components/home/home.component";
import { CambiarMontoComponent } from "./components/cambiar-monto/cambiar-monto.component";
import { NotFoundComponentComponent } from "./components/not-found-component/not-found-component.component";
import { AdminComponent } from "./components/admin/admin.component";
import { SolicitarCreditoComponent } from "./components/solicitar-credito/solicitar-credito.component";
import { SolicitarcreditoSinpreaprobadoCambiomontoComponent } from "./components/solicitarcredito-sinpreaprobado-cambiomonto/solicitarcredito-sinpreaprobado-cambiomonto.component";
import { DatosSeguroComponent } from './components/solicitar-credito/datos-seguro/datos-seguro.component';
import { DatosParaDesembolsoComponent } from './components/solicitarcredito-sinpreaprobado-cambiomonto/datos-para-desembolso/datos-para-desembolso.component';
import { DatosDelSeguroComponent } from './components/solicitarcredito-sinpreaprobado-cambiomonto/datos-del-seguro/datos-del-seguro.component';
import { ResumenCreditoComponent } from './components/solicitarcredito-sinpreaprobado-cambiomonto/resumen-credito/resumen-credito.component';
import { PazysalvoComponent } from './components/certificados/pazysalvo/pazysalvo.component'
import { AnograbableComponent } from './components/certificados/anograbable/anograbable.component'
import { EstadocreditoComponent } from './components/certificados/estadocredito/estadocredito.component'

import { AdminGuard } from "./guards/admin.guard";
import { userGuard } from "./guards/user.guard"

const routes: Routes = [
  { path: 'validacion', redirectTo: '' },
  { path: '', component: HomeComponent, pathMatch: 'full' /* canActivate: */ },
  { path: 'cambiarMonto', component: CambiarMontoComponent, canActivate: [userGuard] }, //Se tiene pendiente el canActive con login
  { path: 'solicitar-credito-1', component: SolicitarCreditoComponent, canActivate: [userGuard] }, //Solicitud de crédito preaprobado, SIN cambiar el monto ni plazo
  { path: 'solicitar-credito-2', component: DatosSeguroComponent, canActivate: [userGuard] },
  { path: 'cambiar-monto-1', component: SolicitarcreditoSinpreaprobadoCambiomontoComponent, canActivate: [userGuard] }, //Solicitud de créditos NO preaprobado o CON cambio de monto y/o plazo
  { path: 'cambiar-monto-2', component: DatosParaDesembolsoComponent, canActivate: [userGuard] }, //Solicitud de créditos NO preaprobado o CON cambio de monto y/o plazo
  { path: 'cambiar-monto-3', component: DatosDelSeguroComponent, canActivate: [userGuard] }, //Solicitud de créditos NO preaprobado o CON cambio de monto y/o plazo
  { path: 'cambiar-monto-4', component: ResumenCreditoComponent, canActivate: [userGuard] }, //Solicitud de créditos NO preaprobado o CON cambio de monto y/o plazo
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
  { path: 'admin/validacion', component: AdminComponent, canActivate: [AdminGuard] },
  { path: 'pazysalvo', component:PazysalvoComponent, canActivate:[userGuard] },
  { path: 'anogravable', component:AnograbableComponent, canActivate:[userGuard] },
  { path: 'estacredito', component:EstadocreditoComponent, canActivate:[userGuard] },
  //Not Found page
  { path: '**', redirectTo: '/404' },
  { path: '404', component: NotFoundComponentComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
