import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { AdminService } from './admin.service';
import { ModalEliminarUsuarioComponent } from './modal-eliminar-usuario/modal-eliminar-usuario.component';
import { MessagesService } from '../../reusableComponents/messages/messages.service';
import { LoadingInfoService } from '../../reusableComponents/loading-info/loading-info.service';
import { user } from '../../constants/interfaces/B2CInterfaces';
import * as CryptoJS from 'crypto-js';
import { AppService } from './../../app.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  displayedColumns: string[] = [
    'TipodeDocumento',
    'displayName',
    'accountEnabled',
    'TipoDeValidacion',
    'EstadoDeValidacion',
    'MotivoBloqueo',
    'action',
  ];
  dataSource: MatTableDataSource<user>;
  dataBusqueda: MatTableDataSource<user>;
  loadingDataTable: boolean = true;
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  loadingInfo: boolean = false; //Bandera del cargando
  loadingSubscription: Subscription;
  skipToken: string | null = null;
  formNumeroDocumento: FormGroup;
  buscando: boolean = false;
  resultadoDeBusqueda: boolean = false;
  busquenaNoEncontrada: boolean = false;
  errorDocumentoVacio: boolean = false;
  loadingMoreUsers: boolean = false;
  superAdmin: boolean = false;
  loadingDownloadFIle: boolean = false;
  tipoDocumento: string = '';
  usuario: string = '';
  nombreCliente: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminService: AdminService,
    private loadingService: LoadingInfoService,
    private messageService: MessagesService,
    private authService: MsalService,
    private Modal: MatDialog,
    private appService: AppService
  ) {
    this.formNumeroDocumento = new FormGroup({
      numeroDocumento: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.messageSubscription = this.messageService.messageStatus.subscribe(
      (value) => (this.messageVisble = value)
    );
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(
      (value) => (this.loadingInfo = value)
    );
    this.loadingService.stopLoading();
    this.actualizarData();

    let {
      idTokenClaims: { jobTitle },
    } = this.authService.getAccount();
    if (jobTitle === 'superadmin') {
      this.superAdmin = true;
      this.displayedColumns.push('options');
    }
    let getAccount = this.authService.getAccount();
    this.appService
      .getUserAttributes(getAccount.idTokenClaims.sub)
      .subscribe((data) => {
        this.usuario = data.userSignIn;
      });
  }

  actualizarData(): void {
    this.adminService
      .getUsersSinTokens(localStorage.getItem('tokenB2C'))
      .subscribe(
        (data) => {
          this.skipToken = data[0].skiptoken;
          this.dataSource = new MatTableDataSource(data.slice(1));
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loadingDataTable = false;
        },
        //En caso de error, se hace una nueva consulta, esta vez se hace la consulta de 0, consultando los tokens de B2C y salesforce
        () => {
          this.adminService.getUsers().subscribe(
            (data) => {
              this.skipToken = data[0].skiptoken;
              this.dataSource = new MatTableDataSource(data.slice(1));
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.loadingDataTable = false;
            },
            (error) =>
              this.messageService.visibleMessage(
                {
                  imgName: 'error.svg',
                  texto1: `Error ${error.origin}`,
                  texto2:
                    'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
                  button: 'Reintentar',
                  button2: 'Cerrar sesión',
                },
                'recargarPagina',
                'Close2'
              )
          );
        }
      );
  }

  habilitarOnCredit(id: string) {
    this.messageService.visibleMessage(
      {
        imgName: 'success.svg',
        texto1: '¡Será redireccionado a onCredit!',
        texto2: 'Por favor espere.',
        button: 'Aceptar',
      },
      'Info'
    );
    this.loadingService.startLoading();
    this.adminService
      .getAtrributes(id, localStorage.getItem('tokenB2C'))
      .subscribe(
        (data) => {
          const {
            userSignIn,
            atrributes: [
              {
                TipodeDocumento,
                extension_eca7dcdb18dc40cf9cb662513587a90a_Tipodevalidacion,
              },
            ],
          } = data;
          if (
            extension_eca7dcdb18dc40cf9cb662513587a90a_Tipodevalidacion ===
            'telefonica'
          ) {
            this.messageService.visibleMessage(
              {
                imgName: 'error.svg',
                texto1: '¡No se puede realizar validación telefónica!',
                texto2:
                  'El usuario ya realizó su único intento vía telefónica, se debe dirigir a una oficina para efectuar el desbloqueo.',
                button: 'Cerrar',
              },
              'Info'
            );
          } else {
            localStorage.setItem('id', id);
            let tipoDocumento: string;
            switch (TipodeDocumento) {
              case 'Cedula de Ciudadania':
                tipoDocumento = '1';
                break;
              case 'Cedula de Extranjeria':
                tipoDocumento = '2';
                break;
              case 'Pasaporte':
                tipoDocumento = '4';
                break;
              default:
                break;
            }
            this.adminService
              .disblockOnCredit(userSignIn, tipoDocumento)
              .subscribe(
                (data) => {
                  this.adminService
                    .updateAtrributesUsuarioDesbloqueo(
                      id,
                      localStorage.getItem('tokenB2C'),
                      this.usuario
                    )
                    .subscribe(null);
                },
                (error) =>
                  this.messageService.errorMessage({
                    title: `Error ${error.origin}`,
                    texto1:
                      'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
                  })
              );
          }
        },
        (error) =>
          this.messageService.errorMessage({
            title: `Error ${error.origin}`,
            texto1:
              'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
          })
      );
  }

  habilitarPresencial(id: string) {
    this.loadingDataTable = true;
    this.adminService
      .updateAtrributesUsuarioDesbloqueo(
        id,
        localStorage.getItem('tokenB2C'),
        this.usuario
      )
      .subscribe(() => {
        this.adminService
          .updateAtrributes(
            id,
            localStorage.getItem('tokenB2C'),
            true,
            'presencial',
            2
          )
          .subscribe(
            () => {
              if (this.resultadoDeBusqueda) {
                this.onSubmitSearch();
              } else {
                this.actualizarData();
              }
              this.messageService.visibleMessage(
                {
                  imgName: 'success.svg',
                  texto1: '¡Transacción exitosa!',
                  texto2: 'Usuario habilitado correctamente.',
                  button: 'Cerrar',
                },
                'Info'
              );
            },
            (error) =>
              this.messageService.errorMessage({
                title: `Error ${error.origin}`,
                texto1:
                  'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
              })
          );
      });
  }

  bloquearUsuario(id: string) {
    this.loadingDataTable = true;
    this.adminService
      .updateAtrributes(
        id,
        localStorage.getItem('tokenB2C'),
        false,
        'virtual',
        2
      )
      .subscribe(
        () => {
          if (this.resultadoDeBusqueda) {
            this.onSubmitSearch();
          } else {
            this.actualizarData();
          }
          this.messageService.visibleMessage(
            {
              imgName: 'success.svg',
              texto1: '¡Transacción exitosa!',
              texto2: 'Usuario bloqueado correctamente.',
              button: 'Cerrar',
            },
            'Info'
          );
        },
        (error) =>
          this.messageService.errorMessage({
            title: `Error ${error.origin}`,
            texto1:
              'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente.',
          })
      );
  }

  eliminarUsuario(userId: string, TipodeDocumento: string, usuario: string) {
    if (this.superAdmin) {
      this.Modal.open(ModalEliminarUsuarioComponent, {
        data: {
          userId,
          TipodeDocumento,
          usuario,
        },
        maxWidth: '100vw',
        maxHeight: '95vh',
      })
        .afterClosed()
        .subscribe((response) => {
          if (response?.update) this.actualizarData();
        });
    }
  }

  onSubmitSearch() {
    let getAccount = this.authService.getAccount();
    this.appService
      .getUserAttributes(getAccount.idTokenClaims.sub)
      .subscribe((data) => {
        this.usuario = data.userSignIn;
      });

    if (!this.formNumeroDocumento.valid) {
      this.errorDocumentoVacio = true;
    } else {
      this.errorDocumentoVacio = false;
      this.buscando = true;
      this.adminService
        .buscarDocumento(
          this.formNumeroDocumento.value.numeroDocumento,
          localStorage.getItem('tokenB2C')
        )
        .subscribe(
          (data) => {
            if (data[1] === undefined) {
              this.resultadoDeBusqueda = true;
              this.busquenaNoEncontrada = true;
            } else {
              this.dataBusqueda = new MatTableDataSource([data[1]]);
              this.resultadoDeBusqueda = true;
              this.busquenaNoEncontrada = false;
            }
            this.buscando = false;
            this.loadingDataTable = false;
          },
          () => {
            this.adminService.updateToken().subscribe(
              () => {
                this.adminService
                  .buscarDocumento(
                    this.formNumeroDocumento.value.numeroDocumento,
                    localStorage.getItem('tokenB2C')
                  )
                  .subscribe(
                    (data) => {
                      if (data[1] === undefined) {
                        this.resultadoDeBusqueda = true;
                        this.busquenaNoEncontrada = true;
                      } else {
                        this.dataBusqueda = new MatTableDataSource([data[1]]);
                        this.resultadoDeBusqueda = true;
                        this.busquenaNoEncontrada = false;
                      }
                      this.buscando = false;
                      this.loadingDataTable = false;
                    },
                    (error) => {
                      this.messageService.errorMessage({
                        title: `Error ${error.origin}`,
                        texto1:
                          'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente.',
                      });
                      this.buscando = false;
                      this.loadingDataTable = false;
                      this.resultadoDeBusqueda = false;
                    }
                  );
              },
              (error) => {
                this.messageService.errorMessage({
                  title: `Error ${error.origin}`,
                  texto1:
                    'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente.',
                });
                this.buscando = false;
                this.loadingDataTable = false;
                this.resultadoDeBusqueda = false;
              }
            );
          }
        );
    }
  }

  descargarUsuarios() {
    if (this.superAdmin) {
      this.loadingDownloadFIle = true;
      this.adminService
        .getUsersSinTokens(localStorage.getItem('tokenB2C'))
        .subscribe(
          (data) => this.procesarData(data),
          () => {
            //En caso de error, se hace una nueva consulta, esta vez se hace la consulta de 0, consultando los tokens de B2C y salesforce
            this.adminService.getUsers().subscribe(
              (data) => this.procesarData(data),
              (error) =>
                this.messageService.visibleMessage(
                  {
                    imgName: 'error.svg',
                    texto1: `Error ${error.origin}`,
                    texto2:
                      'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
                    button: 'Aceptar',
                  },
                  'info'
                )
            );
          }
        );
    }
  }

  async procesarData(data) {
    let SkipToken: string = data[0].skiptoken;
    data.splice(0, 1);

    while (SkipToken !== null) {
      //Mientras el token no sea nulo seguir consultando usuarios
      try {
        const dataRta = await this.adminService
          .loadMore100Users(SkipToken, localStorage.getItem('tokenB2C'))
          .toPromise();
        SkipToken = dataRta[0].skiptoken;
        dataRta.splice(0, 1);
        data = data.concat(dataRta);
      } catch (error) {
        this.messageService.errorMessage({
          title: `Error ${error.origin}`,
          texto1:
            'No se pudio obtener todos los usuarios. Por favor revisa tu conexión a internet e intenta nuevamente.',
        });
        SkipToken = null;
        this.loadingDownloadFIle = false;
        return;
      }
    }
    this.generarArchivo(data);
    this.loadingDownloadFIle = false;
  }

  generarArchivo(data) {
    const filterData = data.map(
      ({
        TipodeDocumento,
        identities,
        NombredeEmpresaoRazonSocial,
        givenName,
        surname,
        NumeroCelular,
        createdDateTime,
        accountEnabled,
        IdentidadValida,
        extension_eca7dcdb18dc40cf9cb662513587a90a_Tipodevalidacion,
        extension_eca7dcdb18dc40cf9cb662513587a90a_Numerodeintentos,
        FechaUltimoAcceso,
        MedioRegistro,
        CorreoRegistro,
        Correo,
        UsuarioDesbloqueo,
      }) => ({
        TipodeDocumento,
        identities: identities[0].issuerAssignedId,
        NombredeEmpresaoRazonSocial,
        givenName,
        surname,
        NumeroCelular,
        createdDateTime,
        accountEnabled,
        IdentidadValida,
        extension_eca7dcdb18dc40cf9cb662513587a90a_Tipodevalidacion,
        extension_eca7dcdb18dc40cf9cb662513587a90a_Numerodeintentos,
        FechaUltimoAcceso,
        MedioRegistro,
        CorreoRegistro,
        Correo,
        UsuarioDesbloqueo,
      })
    );
    const Heading = [
      [
        'Tipo de documento',
        'No. documento',
        'Nombre de Empresa',
        'Nombre',
        'Apellidos',
        'No. celular',
        'Fecha Creación',
        'Estado',
        'Validación',
        'Tipo',
        'No. Intentos',
        'Fecha Último Acceso',
        'Medio Registro',
        'Correo Registro',
        'Correo Actual',
        'Usuario Desbloqueo',
      ],
    ];
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(filterData);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataExcel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataExcel, 'Usuarios Crédito Digital.xlsx');
  }

  pageChange() {
    if (!this.dataSource.paginator.hasNextPage()) {
      if (this.skipToken !== undefined && this.skipToken !== null) {
        this.loadingMoreUsers = true;
        this.adminService
          .loadMore100Users(this.skipToken, localStorage.getItem('tokenB2C'))
          .subscribe(
            (data) => {
              this.dataSource = new MatTableDataSource([
                ...this.dataSource.data,
                ...data.slice(1),
              ]);
              this.skipToken = data[0].skiptoken;
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.loadingMoreUsers = false;
            },
            (error) => {
              this.loadingMoreUsers = false;
              this.messageService.errorMessage({
                title: `Error ${error.origin}`,
                texto1:
                  'No se pudieron obtener más usuarios. Por favor revisa tu conexión a internet e intenta nuevamente.',
              });
            }
          );
      }
    }
  }

  removeSearch() {
    this.formNumeroDocumento.controls.numeroDocumento.setValue('');
    this.errorDocumentoVacio = false;
    this.resultadoDeBusqueda = false;
  }

  saverange() {
    this.resultadoDeBusqueda = false;
    this.errorDocumentoVacio = false;
    this.resultadoDeBusqueda = false;
  }

  get documentoABuscar() {
    return this.formNumeroDocumento.get('numeroDocumento');
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }
}
