import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})

export class userGuard implements CanActivate {
  constructor(private router: Router, private authService: MsalService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let getAccount = this.authService.getAccount();

    if (getAccount.idTokenClaims.jobTitle === "admin" || getAccount.idTokenClaims.jobTitle === "superadmin") {
      //Si posee el atributo de B2C se retorna un false, y se redirecciona a la url de admin.
      this.router.navigate([window.location.href]);
      return false;
    }
    else {
      //Si no posee el atributo se retorna un home, indicando que puede acceder
      return true;
    }
  }
}