<div class="contenedor">
  <div class="header">
    <h1>
      {{
        this.data.fuente === "cambiarMonto"
          ? "Cambiar monto y plazo"
          : "Solicitar crédito"
      }}
    </h1>
    <hr />
    <mat-icon class="back-arrow" (click)="regresar()"
      >arrow_back_ios_new</mat-icon
    >
  </div>
  <div class="contenido">
    <h6>
      El monto mínimo que puedes solicitar para tu crédito es de
      <span style="color: #0064b1">
        {{ 800000 | currency : "USD" : "symbol" : "1.0" }}</span
      >
      y el máximo de
      <span style="color: #0064b1">
        {{ 6000000 | currency : "USD" : "symbol" : "1.0" }}</span
      >. Además, el plazo mínimo es de
      <span style="color: #0064b1"> 4 meses </span> y el máximo de
      <span style="color: #0064b1">36 meses. </span>Esta restricción de monto
      solo aplica por ahora por este canal digital, si necesitas montos
      superiores, por favor comunícate con nosotros al 018000 417 019,
      escríbenos al WhatsApp
      <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
        target="_blank"
      >
        320 606 4004</a
      >
      o dirígete a una oficina.
    </h6>
    <div class="formulario">
      <h5>Monto a solicitar<span style="color: red">*</span></h5>
      <form [formGroup]="formMontoSolicitar" (ngSubmit)="onSubmit()">
        <input
          formControlName="monto"
          type="text"
          placeholder="Ingresa el monto a solicitar"
        />
        <h6
          *ngIf="
            montoInput.invalid &&
            (montoInput.dirty || montoInput.touched || errorSubmit)
          "
          class="errorMonto"
        >
          *Debe de ingresar un monto válido, sin puntos ni comas.
        </h6>
        <h5 style="margin-top: 1em">
          Número de cuotas<span style="color: red">*</span>
        </h5>
        <input
          formControlName="plazo"
          type="text"
          placeholder="Ingresa el número de cuotas"
        />
        <h6
          *ngIf="
            cuotasSelector.invalid &&
            (cuotasSelector.dirty || cuotasSelector.touched || errorSubmit)
          "
          class="errorMonto"
        >
          *Debe de ingresar un número de cuotas válido.
        </h6>
        <button type="submit" *ngIf="!visibleSimulator">Simular</button>
      </form>
      <mat-icon class="down-arrow" *ngIf="visibleSimulator"
        >keyboard_arrow_down</mat-icon
      >
    </div>
    <br />
    <h6>
      Tu aprobación estará sujeta a un estudio de crédito. Los resultados del
      simulador no corresponden a una obligación de liquidación exacta o a la
      aprobación final de tu crédito.
    </h6>
  </div>
  <form [formGroup]="formMontoSolicitar" (ngSubmit)="onSubmit()">
    <div style="display: flex; flex-direction: column; align-items: center">
      <div class="simulacion" *ngIf="visibleSimulator">
        <div class="grid">
          <div class="monto">
            <h5>Monto</h5>
            <h3>{{ monto | currency : "USD" : "symbol" : "1.0" }}</h3>
          </div>
          <div class="cuotaMensual">
            <div
              style="display: flex; flex-direction: column; margin-right: 2vw"
            >
              <h5>Cuota</h5>
              <h5>mensual</h5>
            </div>
            <div class="valor" style="margin-right: 1vw">
              <div
                style="display: flex; flex-direction: column"
                *ngIf="data.simulador === null"
              >
                <ngx-skeleton-loader
                  count="1"
                  appearance="circle"
                  [theme]="{
                    width: '13em',
                    height: '3.5vh',
                    'background-color': '#d7d8d8',
                    'border-radius': '5px',
                    margin: '0px',
                    'margin-top': '1vh'
                  }"
                >
                </ngx-skeleton-loader>
                <ngx-skeleton-loader
                  count="1"
                  appearance="circle"
                  [theme]="{
                    width: '13em',
                    height: '2vh',
                    'background-color': '#d7d8d8',
                    'border-radius': '5px',
                    margin: '0px'
                  }"
                >
                </ngx-skeleton-loader>
              </div>
              <div *ngIf="data.simulador !== null">
                <h4>
                  {{ data.simulador | currency : "USD" : "symbol" : "1.0" }}
                </h4>
                <h6>aproximadamente<span style="color: #e31e3a">*</span></h6>
              </div>
            </div>
          </div>
          <div class="cuotas">
            <div
              style="display: flex; flex-direction: column; margin-right: 2vw"
            >
              <h5>Número</h5>
              <h5>de cuotas</h5>
            </div>
            <div class="meses">
              <h4>{{ plazo }}</h4>
              <h6>meses</h6>
            </div>
          </div>
          <!-- <div class="cargos-cuotaAprox">
                        <h5>Cargos fijos</h5>
                        <h4 *ngIf="data.simulador !== null">{{ data.Cargos | currency:'USD':'symbol':'1.0' }}</h4>
                        <div style="display: flex; flex-direction: column;" *ngIf="data.simulador === null">
                            <ngx-skeleton-loader count="1" appearance="circle"
                                [theme]="{ 'width': '13em', 'height': '3.5vh', 'background-color': '#d7d8d8', 'border-radius':'5px', 'margin': '0px', 'margin-top': '1vh' }">
                            </ngx-skeleton-loader>
                        </div>
                    </div> -->
          <!-- <div class="cargos-cuotaAprox">
                        <h5>Total cuota aproximada</h5>
                        <h4 *ngIf="data.simulador !== null">{{ data.Total | currency:'USD':'symbol':'1.0' }}</h4>
                        <div style="display: flex; flex-direction: column;" *ngIf="data.simulador === null">
                            <ngx-skeleton-loader count="1" appearance="circle"
                                [theme]="{ 'width': '13em', 'height': '3.5vh', 'background-color': '#d7d8d8', 'border-radius':'5px', 'margin': '0px', 'margin-top': '1vh' }">
                            </ngx-skeleton-loader>
                        </div>
                    </div> -->
        </div>
        <div class="buttons">
          <button type="submit" style="background-color: #1c98c9">
            <h6 style="margin-bottom: 0; text-align: center">Simular</h6>
            <h6 style="margin-bottom: 0; text-align: center">otro monto</h6>
          </button>
          <button
            style="background-color: #dd512c"
            (click)="solicitarCredito()"
            type="button"
            [disabled]="
              !(
                this.cuotasSelector.value === null &&
                this.montoInput.value === null &&
                data.simulador !== null
              )
            "
            class="continuar"
          >
            <h6 style="margin-bottom: 0">Solicitar</h6>
            <h6 style="margin-bottom: 0">crédito</h6>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
