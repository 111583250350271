import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog"

@Component({
  selector: 'app-error-certificados',
  templateUrl: './error-certificados.component.html',
  styleUrls: ['./error-certificados.component.css']
})
export class ErrorCertificadosComponent implements OnInit {
  FNGJuridico = false
  errorCreditos = false
  NoExisteData = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      TipoId: string,
      id: string,
      mensaje: string,
      credito: string,
      msnPersonalizado:boolean,
      msnPersonalizadoText:string
  }
  ) { 
    
    if(this.data.mensaje == 'Juridico' || this.data.mensaje == 'FNG'){
      this.FNGJuridico = true
    }else if(this.data.mensaje){
      this.errorCreditos = true;
    }else if(this.data.mensaje == 'NoExisteData'){
      this.NoExisteData = true
    }
  }

  ngOnInit(): void {
  }

}
