<div mat-dialog-title>
    <h6>Pago PSE</h6>
    <button mat-dialog-close>X</button>
</div>
<div mat-dialog-content>
    <p>
        Estás a punto de salir de la oficina virtual de Interactuar e ingresar a <a target="_blank"
            href="https://pse.interactuar.org.co/">https://pse.interactuar.org.co/</a>,
        si no deseas visitar esta página regresa a la página anterior, si el pago que vas a realizar es superior a tu
        cuota mensual o cancelas la totalidad de tu crédito, este se verá reflejado en tu saldo en las próximas 24
        horas. Una vez finalices tu transacción debes volver a ingresar a la oficina virtual.
    </p>
    <p>
        Recuerda tener a la mano la referencia de pago o código de crédito que vas a abonar.
    </p>
    <p style="font-size: 18px;">Si cancelaste un crédito recientemente es posible que aparezca vigente entre 24 y 120 horas después de haber realizado el pago</p>
</div>
<mat-dialog-actions align="center" class="buttonsPSE">
    <button mat-button mat-dialog-close class="closeButton">Cerrar</button>
    <button mat-button class="closeButton" onclick="window.open('https://pse.interactuar.org.co/','_blank')"
        style="background-color: #ffd100;">
        Aceptar
    </button>
</mat-dialog-actions>
