import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../../home/home.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import { environment } from "../../../../environments/environment";
import { MsalService } from '@azure/msal-angular';
import { MatDialog } from "@angular/material/dialog";
import { ErrorCertificadosComponent } from '../../certificados/error-certificados/error-certificados.component';
import { Subscription } from 'rxjs';
import { LoadingInfoService } from "../../../reusableComponents/loading-info/loading-info.service";
import { MessagesService } from "../../../reusableComponents/messages/messages.service";
import { ModalEstadoCreditoComponent } from '../modal-estado-credito/modal-estado-credito.component'
import { ModalCertificadoPazysalvoComponent } from '../modal-certificado-pazysalvo/modal-certificado-pazysalvo.component';
import * as moment from 'moment';


export class Credits {
  origenDatos: string;
  fechaDesembolso: string;
  fechaCancelacion: string;
  estado: string;
  credito: string;
  cliente: {
    tipoId: string;
    nroId: string;
  }
}

@Component({
  selector: 'app-estadocredito',
  templateUrl: './estadocredito.component.html',
  styleUrls: ['./estadocredito.component.css']
})
export class EstadocreditoComponent implements OnInit {

  tipoDocumento: string = "";
  numeroDocumento: string = "";
  descargar = false;
  datos = {}
  nombreCliente: string = "";
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  creditos: Credits
  dataPaz: any
  creditosYEstados = []
  fechaActual = new Date();

  displayedColumnsPaz: string[] = ['credito', 'estado', 'option'];
  datosPaz: Credits[] = [];
  dataSourcePaz: any;
  descargaPDF = 0

  seleccion = new SelectionModel<Credits>(false, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private router: Router,
    private authService: MsalService,
    private homeService: HomeService,
    public Modal: MatDialog,
    private loadingService: LoadingInfoService,
    private messageService: MessagesService
  ) { }

  regresar() {
    localStorage.setItem("origenCertificado", "1");
    this.router.navigate(["/"]);
  }

  getCreditos() {
    //const anoPasado = this.fechaActual.getFullYear() - 1
    const anoPasado = 2024;
    const anoGravable = anoPasado.toString()
    this.homeService.obtenerCreditos(this.tipoDocumento, this.numeroDocumento).subscribe(
      data => {
        const creditos = data.response.creditosVigCanClienteSalesForce.creditos
        //Todos los créditos cuyo origenDatos sea igual a ‘SIIF’
        this.creditosYEstados = creditos.filter(origenDatos => origenDatos.origenDatos == "SIIF" && (origenDatos.estado.toUpperCase() == "VIGENTE" || origenDatos.estado.toUpperCase() == "VENCIDO" || origenDatos.estado.toUpperCase() == "CASTIGADO" || origenDatos.estado.toUpperCase() == "ACTIVO" || origenDatos.estado.toUpperCase() == "CANCELADO"))

        this.creditosYEstados = this.creditosYEstados.filter(f => f.fechaCancelacion.substring(0, 4) <= anoGravable )

        this.dataSourcePaz = new MatTableDataSource<any>(this.creditosYEstados);
        this.dataSourcePaz.paginator = this.paginator;

      });
  }

  //seleccion de credito
  onCreditToggled(elemento: Credits) {
    this.seleccion.toggle(elemento);
    if (this.seleccion.selected.length > 0) {
      this.descargar = true;
    } else {
      this.descargar = false;
    }
  }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((value) => this.loadingInfo = value)
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value)
    const { idToken: { given_name, extension_TipodeDocumento } } = this.authService.getAccount();
    [this.numeroDocumento, this.tipoDocumento] = [CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8), extension_TipodeDocumento];
    [this.nombreCliente, this.numeroDocumento, this.tipoDocumento] = [given_name, CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8), extension_TipodeDocumento];
    this.getCreditos()
    //this.getToken()
    this.loadingService.stopLoading();

  }

  openModalEstadoCredito() {
    this.loadingService.startLoading();

    let seleccionado = this.seleccion.selected[0];
    var credito = this.zfill(Number(seleccionado.credito), 17)

    this.homeService.getToken().subscribe(
      data => {

      this.homeService.consultarCreditoDetalle(credito, seleccionado.cliente.tipoId, seleccionado.cliente.nroId, data.request.token).subscribe(
        data => {

          if (data.response.encabezado.mensaje.estado == "ERROR") {
            this.loadingService.stopLoading();

            if (data.response.encabezado.mensaje.descripcion == 'Credito Juridico' || data.response.encabezado.mensaje.descripcion == 'Credito FNG') {
              this.messageService.visibleMessage(
                {
                  title: '',
                  imgName: "error.svg",
                  texto1: ``,
                  msnCreditosJuridico: true,
                  button: "Cerrar",
                  enlace: "https://interactuar.org.co/oficinas/",
                  parametro: credito
                },
                "Info"
              )

            }else if(data.response.encabezado.mensaje.descripcion == 'No hay registros que cumplan las condiciones'){
              this.messageService.visibleMessage(
                {
                  title: '',
                  imgName: "error.svg",
                  texto1: ``,
                  msnCreditosinregistro: true,
                  button: "Cerrar",
                  parametro: credito
                },
                "Info"
              )
            } else {
              this.messageService.visibleMessage(
                {
                  title: 'Error inesperado',
                  imgName: "error.svg",
                  texto1: `${data.response.encabezado.mensaje.descripcion}`,
                  //msnServiceError: true,
                  msnCreditosSelect: true,
                  button: "Cerrar",
                },
                "Info"
              )
            }
          } else {
            this.getPDF(data.response.encabezado.mensaje.idDocumento);
          }

        }, error => {
          this.loadingService.stopLoading();
          this.messageService.visibleMessage(
            {
              //title: 'Error inesperado',
              imgName: "error.svg",
              texto1: ``,
              msnServiceError: true,
              //msnCreditosSelect: true,
              button: "Cerrar",
            },
            "Info"
          )
        })
    })

  }

  getPDF(idDocumento) {
    this.homeService.getPDFSIIF(idDocumento).subscribe(
      data => {
        if (data.length == 0) {
          this.descargaPDF++
          var idTimeout = setTimeout(() => {
            this.getPDF(idDocumento);
          }, 3000);

          if (this.descargaPDF >= 36) {
            this.descargaPDF = 0
            this.loadingService.stopLoading();
            clearTimeout(idTimeout);
            this.messageService.visibleMessage(
              {
                //title: 'Error inesperado',
                imgName: "error.svg",
                texto1: ``,
                msnServiceError: true,
                //msnCreditosSelect: true,
                button: "Cerrar",

              },
              "Info"
            )
          }

        } else if (data[0].request.encabezado.mensaje.estado == 'EXITO') {
          clearTimeout(idTimeout);
          this.descargaPDF = 0
          this.deletePDF(data[0].id)
          if (window.innerWidth <= 700) {
            this.decarga(data[0].request.documento)
          } else {
            this.datos = { base64: data[0].request.documento }
            this.loadingService.stopLoading();
            const dialogRef = this.Modal.open(ModalCertificadoPazysalvoComponent, {
              data: this.datos,
              maxWidth: '98vw',
              maxHeight: '95vh',
              panelClass: 'openDetalleContainer',
            });
          }

        } else if (data[0].request.encabezado.mensaje.estado == 'ERROR') {

          clearTimeout(idTimeout);
          this.descargaPDF = 0
          this.deletePDF(data[0].id)
          this.loadingService.stopLoading();
          this.messageService.visibleMessage(
            {
              //title: 'Error inesperado',
              imgName: "error.svg",
              texto1: ``,
              msnServiceError: true,
              //msnCreditosSelect: true,
              button: "Cerrar",
            },
            "Info"
          )
        }
      })
  }

  zfill(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */
    var zero = "0"; /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return ("-" + numberOutput.toString());
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
      } else {
        return ((zero.repeat(width - length)) + numberOutput.toString());
      }
    }
  }

  deletePDF(idDocumento) {
    this.homeService.deletePDFSIIF(idDocumento).subscribe(
      data => {
      })
  }

  decarga(base64: any) {
    const data = 'data:application/octet-stream;base64,' + base64
    const downloadLink = document.createElement('a');
    const fileName = `${new Date().toISOString()}.pdf`;
    downloadLink.href = data;
    downloadLink.download = fileName;
    downloadLink.click();
    this.loadingService.stopLoading();
  }

  getToken(){
    setTimeout(() => {
      this.getToken();
    }, 300000);

    this.homeService.getToken().subscribe(
      data => {
        var fecha = new Date().toLocaleString('es-CL', { timeZone: 'America/Bogota' })
    var fecha1 = moment(fecha, "YYYY-MM-DD HH:mm:ss");
    var fecha2 = moment(data.request.fecha, "YYYY-MM-DD HH:mm:ss");
    var minutos = fecha2.diff(fecha1, 'm')
    if (minutos < 0) {
      minutos = minutos * -1;
    }
     if (minutos >=15) {
      this.homeService.getTokenSIIF().subscribe(data => {
        localStorage.setItem("tokenSIIF", data.acces_token);
        this.homeService.setToken(data.acces_token).subscribe(data => {

        })
      })
     }else{
        localStorage.setItem("tokenSIIF", data.request.token);
     }

      },
      error => { }
    )
  }

}
