<div class="contenedor">
  <div class="header">
    <h1 *ngIf="nombreCliente || NombredeEmpresaoRazonSocial">
      Hola {{ nombreCliente }} {{ NombredeEmpresaoRazonSocial }}
    </h1>
    <hr />
  </div>
  <div class="creditos">
    <div class="mensajeBeta">
      <h6>
        <span
          >¡Bienvenidos a la versión Beta de Autogestión de Crédito Interactuar!
        </span>
      </h6>
      <h6 style="margin-bottom: 0.5em">
        Estamos trabajando para hacer más fácil nuestros procesos de crédito.
      </h6>
      <h6>
        Recuerda que la información o datos que obtengas de tus créditos por
        este medio pueden presentar imprecisiones en esta versión inicial, por
        lo tanto, es importante que valides cualquier inquietud o discrepancia
        con nosotros. Si tienes alguna inquietud o dificultad durante el
        proceso, te invitamos a llamarnos sin costo al 018000 417 019 o a
        escribirnos al WhatsApp,
        <a
          href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar"
          target="_blank"
        >
          320 606 4004</a
        >.
      </h6>
      <h6>Agradecemos tu comprensión.</h6>
    </div>
    <div class="solicitarCreditoCard" *ngIf="soliciteAquiSuCredito">
      <div>
        <h6>¿Necesitas fortalecer</h6>
        <h6>tu negocio?</h6>
      </div>
      <div class="descripcion">
        <h5>En interactuar te ofrecemos</h5>
        <h5>el crédito que necesitas.</h5>
      </div>
      <button (click)="solicitarCreditoDigital('SolicitaAquiTuCredito')">
        Solicita aquí tu crédito
      </button>
    </div>
    <h5
      class="title"
      *ngIf="CreditosEstados.preaprobado.length > 0"
      style="font-weight: 500"
    >
      ¡Tienes un crédito preaprobado!
    </h5>
    <div
      *ngIf="CreditosEstados.preaprobado.length > 0 && !soliciteAquiSuCredito"
      class="creditoPreaprobado"
    >
      <div *ngFor="let creditoPreaprobado of CreditosEstados.preaprobado">
        <div class="grid">
          <div class="monto">
            <h5>Monto</h5>
            <h3>
              {{
                creditoPreaprobado.monto | currency : "USD" : "symbol" : "1.0"
              }}
            </h3>
          </div>
          <div class="cuotaMensual">
            <div
              style="display: flex; flex-direction: column; margin-right: 2vw"
            >
              <h5>Cuota</h5>
              <h5>mensual</h5>
            </div>
            <div class="valor" style="margin-right: 1vw">
              <div
                style="display: flex; flex-direction: column"
                *ngIf="creditoPreaprobado.simulador === null"
              >
                <ngx-skeleton-loader
                  count="1"
                  appearance="circle"
                  [theme]="{
                    width: '13em',
                    height: '3.5vh',
                    'background-color': '#d7d8d8',
                    'border-radius': '5px',
                    margin: '0px',
                    'margin-top': '1vh'
                  }"
                >
                </ngx-skeleton-loader>
                <ngx-skeleton-loader
                  count="1"
                  appearance="circle"
                  [theme]="{
                    width: '13em',
                    height: '2vh',
                    'background-color': '#d7d8d8',
                    'border-radius': '5px',
                    margin: '0px'
                  }"
                >
                </ngx-skeleton-loader>
              </div>
              <div *ngIf="creditoPreaprobado.simulador !== null">
                <h4>
                  {{
                    creditoPreaprobado.simulador
                      | currency : "USD" : "symbol" : "1.0"
                  }}
                </h4>
                <h6>aproximadamente<span style="color: #e31e3a">*</span></h6>
              </div>
            </div>
          </div>
          <div class="cuotas">
            <div
              style="display: flex; flex-direction: column; margin-right: 2vw"
            >
              <h5>Número</h5>
              <h5>de cuotas</h5>
            </div>
            <div class="meses">
              <h4>{{ creditoPreaprobado.plazo }}</h4>
              <h6>meses</h6>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button
            style="background-color: #1c98c9"
            (click)="
              solicitarCreditoDigital('cambiarMonto', creditoPreaprobado)
            "
            [disabled]="creditoPreaprobado.disabledCambiarMonto"
          >
            <h6 style="margin-bottom: 0; text-align: center">Cambiar</h6>
            <h6 style="margin-bottom: 0; text-align: center">monto</h6>
          </button>
          <button
            style="background-color: #dd512c"
            (click)="solicitarCredito(creditoPreaprobado)"
            [disabled]="creditoPreaprobado.simulador === null"
          >
            <h6 style="margin-bottom: 0">Solicitar</h6>
            <h6 style="margin-bottom: 0">crédito</h6>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row pagos" *ngIf="tipoDocumento === 'Nit'">
    <div class="col-6" style="text-align: right">
      <button
        style="color: #1c98c9; border: solid 1px #1c98c9"
        (click)="openModalPagoManual()"
      >
        Otros medios de pago
      </button>
    </div>
    <div class="col-6" style="text-align: left">
      <button
        style="background-color: #dd512c; color: white; border: 0px"
        (click)="openModalPagoPSE()"
      >
        Pagos PSE
      </button>
    </div>
  </div>

  <div class="misCreditos" *ngIf="tipoDocumento !== 'Nit'">
    <h6>Mis créditos</h6>
    <hr />
  </div>
  <br *ngIf="tipoDocumento === 'Nit'" />
  <mat-accordion *ngIf="tipoDocumento !== 'Nit'">
    <mat-expansion-panel [expanded]="panelOpenState" style="box-shadow: none">
      <mat-expansion-panel-header>
        <mat-panel-title> Mis créditos vigentes </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        *ngIf="CreditosEstados.vigente.length > 0; else elsevigente"
        class="creditoVigente"
      >
        <div
          class="credito"
          *ngFor="let creditoVigente of CreditosEstados.vigente"
        >
          <div class="leftCard" style="width: 40vw">
            <h6 class="titulo">Referencia de pago</h6>
            <div
              style="display: flex; flex-direction: column"
              *ngIf="creditoVigente.estadoCartera.referenciaPago === null"
            >
              <ngx-skeleton-loader
                count="1"
                appearance="circle"
                [theme]="{
                  width: '11em',
                  height: '3.5vh',
                  'background-color': '#d7d8d8',
                  'border-radius': '5px',
                  margin: '0px',
                  'margin-top': '1vh'
                }"
              >
              </ngx-skeleton-loader>
            </div>
            <div *ngIf="creditoVigente.estadoCartera.referenciaPago !== null">
              <h6 class="contenido">
                {{ creditoVigente.estadoCartera.referenciaPago }}
              </h6>
            </div>
            <h6 class="titulo">Próxima fecha de pago</h6>
            <div
              style="display: flex; flex-direction: column"
              *ngIf="creditoVigente.estadoCartera.fechaProximoPago === null"
            >
              <ngx-skeleton-loader
                count="1"
                appearance="circle"
                [theme]="{
                  width: '11em',
                  height: '3.5vh',
                  'background-color': '#d7d8d8',
                  'border-radius': '5px',
                  margin: '0px',
                  'margin-top': '1vh'
                }"
              >
              </ngx-skeleton-loader>
            </div>
            <div *ngIf="creditoVigente.estadoCartera.fechaProximoPago !== null">
              <h6 class="contenido">
                {{ creditoVigente.estadoCartera.fechaProximoPago }}
              </h6>
            </div>
          </div>
          <div class="verticalLine"></div>
          <div class="rightCard" style="width: 40vw">
            <h6 class="titulo">Saldo</h6>
            <div
              style="display: flex; flex-direction: column; text-align: end"
              *ngIf="
                creditoVigente.estadoCartera.saldoCancelacionTotal === null
              "
            >
              <ngx-skeleton-loader
                count="1"
                appearance="circle"
                [theme]="{
                  width: '11em',
                  height: '3.5vh',
                  'background-color': '#d7d8d8',
                  'border-radius': '5px',
                  margin: '0px',
                  'margin-top': '1vh'
                }"
              >
              </ngx-skeleton-loader>
            </div>
            <div
              *ngIf="
                creditoVigente.estadoCartera.saldoCancelacionTotal !== null
              "
            >
              <h6 class="contenido">
                {{
                  creditoVigente.estadoCartera.saldoCancelacionTotal
                    | currency : "USD" : "symbol" : "1.0"
                }}
              </h6>
            </div>
            <h6 class="titulo">Cuota mensual</h6>
            <div
              style="display: flex; flex-direction: column; text-align: end"
              *ngIf="creditoVigente.estadoCartera.montoProximoPago === null"
            >
              <ngx-skeleton-loader
                count="1"
                appearance="circle"
                [theme]="{
                  width: '11em',
                  height: '3.5vh',
                  'background-color': '#d7d8d8',
                  'border-radius': '5px',
                  margin: '0px',
                  'margin-top': '1vh'
                }"
              >
              </ngx-skeleton-loader>
            </div>
            <div *ngIf="creditoVigente.estadoCartera.montoProximoPago !== null">
              <h6 class="contenido">
                {{
                  creditoVigente.estadoCartera.montoProximoPago
                    | currency : "USD" : "symbol" : "1.0"
                }}
              </h6>
            </div>
            <!-- <h6 (click)="openModalDetalle(creditoVigente)"
                            style="font-weight: 400; color: #0064b1; text-decoration: underline; cursor: pointer"
                            *ngIf="creditoVigente.estadoCartera.montoProximoPago !== null"> -->
            <h6
              (click)="openModalDetalle(creditoVigente)"
              style="
                font-weight: 400;
                color: #0064b1;
                text-decoration: underline;
                cursor: pointer;
              "
            >
              Ver detalle
            </h6>
          </div>
        </div>
        <div class="pagos">
          <button
            style="color: #1c98c9; border: solid 1px #1c98c9"
            (click)="openModalPagoManual()"
          >
            Otros medios de pago
          </button>
          <button
            style="background-color: #dd512c; color: white; border: 0px"
            (click)="openModalPagoPSE()"
          >
            Pagos PSE
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion *ngIf="tipoDocumento !== 'Nit'">
    <mat-expansion-panel
      [expanded]="panelOpenState"
      style="margin-top: 1vh; box-shadow: none"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Mis créditos solicitados </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        *ngIf="
          CreditosEstados.creditoEnProceso.length > 0;
          else elseSolicitados
        "
        class="creditoVigente"
      >
        <div
          class="credito"
          *ngFor="let creditoEnProceso of CreditosEstados.creditoEnProceso"
        >
          <div class="leftCard" style="width: 40vw">
            <!-- <h6 class="titulo">Referencia de pago</h6>
                        <h6 class="contenido">{{ creditoEnProceso.numeroCredito }}</h6> -->
            <h6 class="titulo">Plazo</h6>
            <h6 class="contenido">{{ creditoEnProceso.plazo }} meses</h6>
          </div>
          <div class="verticalLine"></div>
          <div class="rightCard" style="width: 40vw">
            <h6 class="titulo">Monto del crédito</h6>
            <h6 class="contenido">
              {{ creditoEnProceso.monto | currency : "USD" : "symbol" : "1.0" }}
            </h6>
            <!-- <h6 class="titulo">Cuota aproximada</h6>
                        <div style="display: flex; flex-direction: column;text-align: end"
                            *ngIf="creditoEnProceso.simulador === null">
                            <ngx-skeleton-loader count="1" appearance="circle"
                                [theme]="{ 'width': '11em', 'height': '3.5vh', 'background-color': '#d7d8d8', 'border-radius':'5px', 'margin': '0px', 'margin-top': '1vh' }">
                            </ngx-skeleton-loader>
                        </div> -->
            <!-- <div *ngIf="creditoEnProceso.simulador !== null">
                            <h6 class="contenido">
                                {{ creditoEnProceso.simulador | currency:'USD':'symbol':'1.0'}}
                            </h6>
                        </div> -->
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion *ngIf="tipoDocumento !== 'Nit'">
    <mat-expansion-panel
      [expanded]="panelOpenState"
      style="margin-top: 1vh; box-shadow: none"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Créditos negados </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        *ngIf="CreditosEstados.cancelado.length > 0; else elseOtrosCreditos"
        class="creditoVigente"
      >
        <div
          class="credito"
          *ngFor="let creditoCancelado of CreditosEstados.cancelado"
        >
          <div class="leftCard" style="width: 40vw">
            <!-- <h6 class="titulo">Referencia de pago</h6>
                        <h6 class="contenido">{{ creditoCancelado.numeroCredito }}</h6> -->
            <h6 class="titulo">Plazo</h6>
            <h6 class="contenido">{{ creditoCancelado.plazo }} meses</h6>
          </div>
          <div class="verticalLine"></div>
          <div class="rightCard" style="width: 40vw">
            <h6 class="titulo">Monto del crédito</h6>
            <h6 class="contenido">
              {{ creditoCancelado.monto | currency : "USD" : "symbol" : "1.0" }}
            </h6>
            <!-- <h6 class="titulo">Cuota aproximada</h6>
                        <div style="display: flex; flex-direction: column;text-align: end"
                            *ngIf="creditoCancelado.simulador === null">
                            <ngx-skeleton-loader count="1" appearance="circle"
                                [theme]="{ 'width': '11em', 'height': '3.5vh', 'background-color': '#d7d8d8', 'border-radius':'5px', 'margin': '0px', 'margin-top': '1vh' }">
                            </ngx-skeleton-loader>
                        </div> -->
            <div *ngIf="creditoCancelado.simulador !== null">
              <h6 class="contenido">
                {{
                  creditoCancelado.simulador
                    | currency : "USD" : "symbol" : "1.0"
                }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion>
    <mat-expansion-panel
      [expanded]="panelOpenStateCerticados"
      style="margin-top: 1vh; box-shadow: none"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Más servicios </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="creditoVigente">
        <div class="credito" *ngIf="btnDisabled" style="text-align: center">
          <div class="leftCard" style="width: 40vw">
            <button
              class="moreServicesButton"
              aria-hidden="true"
              style="font-size: 100px"
              (click)="toggleButton()"
            >
              <!--<i class="fa fa-file-text-o" aria-hidden="true"></i>-->
              <mat-icon svgIcon="icono_generar_certificados"></mat-icon>
              <span>Generar certificados</span>
            </button>
            <!-- <p>CERTIFICADOS DEUDA Y PAZ Y SALVOS </p>
                        <p>CERTIFICADOS AÑO GRAVAMEN </p>
                        <p>ESTADOS DE CRÉDITO</p> -->
          </div>
          <div class="verticalLine"></div>
          <div class="rightCard" style="width: 40vw">
            <button
              *ngIf="tipoDocumento !== 'Nit'"
              class="moreServicesButton"
              aria-hidden="true"
              style="font-size: 100px"
              (click)="actualizarPerfil()"
            >
              <!-- <i class="fa fa-pencil-square-o" aria-hidden="true"></i> -->
              <mat-icon svgIcon="icono_actualizacion_datos"></mat-icon>
              <span>Actualización de datos</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!btnDisabled">
        <div class="header" style="font-size: 20px">
          <i
            (click)="toggleButton()"
            class="fa fa-arrow-left pointerCursor"
            aria-hidden="true"
            style="text-align: left; margin: 100px"
          ></i>
        </div>
        <div>
          <div class="pagos">
            <button (click)="enableTablePazySalvo()">DEUDA PAZ Y SALVO</button>
          </div>
          <div class="pagos">
            <button (click)="enableAnoGravable()">AÑO GRAVABLE</button>
          </div>
          <div class="pagos">
            <button (click)="estadoCredito()">ESTADO DE CUENTA</button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div>
  <div class="accodion">
    <ng-template #elsePreAprobados>
      <div class="creditoPreaprobadoNull" *ngIf="!soliciteAquiSuCredito">
        <h6>No tienes créditos preaprobados</h6>
      </div>
    </ng-template>

    <ng-template #elsevigente>
      <div class="MisCreditosNull">
        <h6>No tienes créditos vigentes</h6>
      </div>
    </ng-template>

    <ng-template #elseSolicitados>
      <div class="MisCreditosNull">
        <h6>No tienes créditos solicitados</h6>
      </div>
    </ng-template>

    <ng-template #elseOtrosCreditos>
      <div class="MisCreditosNull">
        <h6>No tienes créditos negados</h6>
      </div>
    </ng-template>
  </div>
</div>
<br />
