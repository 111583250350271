import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export function salesforceEncabezado(integracion: string) {
    return {
        "fecha": moment().format('YYYY-MM-DD HH:mm:ss'),
        "identificador": uuidv4(),
        "integracion": integracion,
        "operacion": "0",
        "origen": "MICROSITIO CREDITO",
        "destino": "SALESFORCE"
    }
}