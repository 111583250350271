import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog"
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-modal-certificado-ano-grabable',
  templateUrl: './modal-certificado-ano-grabable.component.html',
  styleUrls: ['./modal-certificado-ano-grabable.component.css']
})
export class ModalCertificadoAnoGrabableComponent implements OnInit {
  fecha = new Date();
  hours = this.fecha.getHours();
  hoursF = ((this.hours + 11) % 12 + 1);
  hoursAMPM = (this.hours >= 12) ? 'PM' : 'AM';
  numeroDocumento
  totalPagado: number = 0
  totalCapital: number = 0
  totalInteresPagado: number = 0
  nombreCliente = ''
  public movil = false;
  data_1
  loadingDownloadFIle: boolean = true;
  dataPDF:any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      base64: string
    },
    public Modal: MatDialog,
  ) {

  }

  downloadPDF() {
    // Extraemos el
    const DATA = document.getElementById('htmlData');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}.pdf`);
    });
  }
  cerrarModal() {
    this.Modal.closeAll();
  }

  ngOnInit(): void {
    this.dataPDF = "data:application/pdf;base64," + this.data.base64
  }

  descargar(){

    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {

      let fileWidth = 193;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      PDF.addImage(FILEURI, 'PNG', 10, 10, fileWidth, fileHeight);
      if (window.innerWidth < 600) {
        window.open(PDF.output('bloburl'), '_blank');
      } else {
        PDF.save(`${new Date().toISOString()}.pdf`);
      }
    });

    if(!this.movil){
      this.cerrarModal();
    }
  }

  @ViewChild('htmlData') pdfTable: ElementRef;

  public downloadAsPDF() {
    const doc = new jsPDF();

    const pdfTable = this.pdfTable.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = {
      content: html,

      defaultStyle: {
        fontFamily: 'Calibri',
        fontSize: 14
      }
    };
    pdfMake.createPdf(documentDefinition).download();

  }

  export(){
    var docDefinition = {
      content: [{
        image: this.data_1,
        width: 500
      }]
    };
    pdfMake.createPdf(docDefinition).download(`${new Date().toISOString()}.pdf`);
  }

  cargarData(){
    html2canvas(document.getElementById('htmlData')).then((canvas) => {
      this.data_1 = canvas.toDataURL('image/png');
      setTimeout(() => {
        this.loadingDownloadFIle = false;
      }, 1000)    });
  }

  convetToNumber(Valor){
    return Number(Valor)
   }

}
