import { createReducer, on } from '@ngrx/store';
import { SolicitarCreditoInterface } from 'src/app/constants/interfaces/solicitar-credito.interface';
import { saveSolicitarCredito, saveCreditoPreaprobado } from './solicitar-credito.actions';
 
export const initialState: SolicitarCreditoInterface = {
    solicitarCreditoStep1: {},
    preaprobado: {}
};
 
const _solicitarCreditoReducer = createReducer(
  initialState,
  on(saveSolicitarCredito, (state, { form }) => {
    return {
        ...state,
        solicitarCreditoStep1: form
    };
    }),
  on(saveCreditoPreaprobado, (state, { data }) => {
    return {
        ...state,
        preaprobado: data
        };
    })
);

export function solicitarCreditoReducer(state, action) {
  return _solicitarCreditoReducer(state, action);
}