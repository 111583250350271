<div class="contenedor">
    <div class="header">
        <h1>Solicitar crédito</h1>
        <hr />
        <mat-icon class="back-arrow" (click)="regresar()">arrow_back_ios_new</mat-icon>
    </div>
    <div class="contenido">
        <div class="info-empresa">
            <h6 style="margin-bottom: 2vh">Estás solicitando el crédito para:</h6>
            <div class="nombre">
                <h6>Famiempresa</h6>
                <h6>{{creditoPreaprobado.famiempresa}}</h6>
            </div>
            <div class="nombre">
                <h6>Actividad económica</h6>
                <h6>{{creditoPreaprobado.actividadEconomica}}</h6>
            </div>
        </div>
        <div class="pasos">
            <div class="paso1">
                <div class="circle">
                    <div>1</div>
                </div>
                <h6 style="margin-top: 1vh">Actualizar</h6>
                <h6 style="text-align: center;">datos</h6>
            </div>
            <hr />
            <div class="paso2">
                <div class="circle">
                    <div>2</div>
                </div>
                <h6 style="margin-top: 1vh; padding-left: 0.3em;">Datos para</h6>
                <h6>desembolso</h6>
            </div>
            <hr />
            <div class="paso2">
                <div class="circle">
                    <div>3</div>
                </div>
                <h6 style="margin-top: 1vh">Datos del</h6>
                <h6 style="text-align: center;">seguro</h6>
            </div>
        </div>
        <div class="texto" style="margin-top: 3vh">
            <h6>Actualiza tus datos personales</h6>
        </div>
        <form [formGroup]="formActualizarDatos" (ngSubmit)="onSubmit()">
            <div class="field">
                <h5
                    *ngIf="datosPersonales.get('estadoCivil').invalid && (datosPersonales.get('estadoCivil').touched || datosPersonales.get('estadoCivil').dirty || errorSubmit )">
                    *Por favor seleccione un estado civil.
                </h5>
                <label>Estado civil<span style="color: red; font-weight: 500;">*</span></label>
                <select formControlName="estadoCivil" id="sel1" style="color: #757575"
                    onclick="document.getElementById('sel1').style.color='black'">
                    <option [ngValue]="null" selected disabled hidden>
                        Selecciona
                    </option>
                    <option *ngFor="let estado of estadoCivil" [ngValue]="estado.nombre">
                        {{estado.etiqueta}}
                    </option>
                </select>
            </div>
            <div class="field">
                <h5
                    *ngIf="datosPersonales.get('tipoVivienda').invalid && (datosPersonales.get('tipoVivienda').touched || datosPersonales.get('tipoVivienda').dirty || errorSubmit )">
                    *Por favor seleccione un tipo de vivienda.
                </h5>
                <label>Tipo de vivienda<span style="color: red; font-weight: 500;">*</span></label>
                <select formControlName="tipoVivienda" id="sel2" style="color: #757575"
                    onclick="document.getElementById('sel2').style.color='black'" (change)="onChange()">
                    <option [ngValue]="null" selected disabled hidden>
                        Selecciona
                    </option>
                    <option *ngFor="let vivienda of tipoVivienda" [ngValue]="vivienda.nombre">
                        {{vivienda.etiqueta}}
                    </option>
                </select>
            </div>
            <div class="field" *ngIf="datosPersonales.get('tipoVivienda').value === 'A'">
                <h5
                    *ngIf="datosPersonales.get('nombreArrendador').invalid && (datosPersonales.get('nombreArrendador').touched || datosPersonales.get('nombreArrendador').dirty || errorSubmit )">
                    *Por favor ingrese un nombre de arrendador válido.
                </h5>
                <label>Nombre del arrendador<span style="color: red; font-weight: 500;">*</span></label>
                <input formControlName="nombreArrendador" placeholder="Ingresa el nombre del arrendador">
            </div>
            <div class="field" *ngIf="datosPersonales.get('tipoVivienda').value === 'A'">
                <h5
                    *ngIf="datosPersonales.get('telefonoArrendador').invalid && (datosPersonales.get('telefonoArrendador').touched || datosPersonales.get('telefonoArrendador').dirty || errorSubmit )">
                    *Por favor valida el número de teléfono, debe ser entre 7 y 10 caracteres y contener solo valores
                    numéricos.
                </h5>
                <label>Teléfono del arrendador<span style="color: red; font-weight: 500;">*</span></label>
                <input formControlName="telefonoArrendador" placeholder="Ingresa el teléfono del arrendador">
            </div>
            <div class="buttons">
                <button class="cancelar" type="button" (click)="regresar()">Regresar</button>
                <button class="continuar" type="submit">Continuar</button>
            </div>
        </form>
    </div>
</div>

<div class="message" *ngIf="messageBack">
    <div class="card">
        <button class="x" (click)="continuarSolicitud()">X</button>
        <img src="../../../../assets/Images/warning.svg">
        <h6>Estás a punto de salir del proceso de solicitud, recuerda que este crédito sólo estará disponible por tiempo
            limitado.</h6>
        <h6>¿Estás seguro de que deseas abandonarlo?</h6>
        <div class="buttons">
            <button class="continuar" (click)="continuarSolicitud()">Continuar solicitud</button>
            <button class="cancelar" (click)="confirmarRegresar()">Si, regresar</button>
        </div>
    </div>
</div>
