import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})

export class AdminGuard implements CanActivate {
  constructor(private router: Router, private authService: MsalService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let getAccount = this.authService.getAccount();
    if (getAccount === null) {
      let getAccount = this.authService.getAccount();
      if (getAccount === null) {
        return true;
      } else {
        return this.validate(getAccount.idTokenClaims.jobTitle)
      }
    }
    else {
      return this.validate(getAccount.idTokenClaims.jobTitle)
    }

  }

  validate(jobTitle: string) {
    if (jobTitle === "admin" || jobTitle === "superadmin") {
      //Si posee el atributo de B2C se retorna un true, permitiendo así el paso a la url '/admin'.
      return true;
    }
    else {
      //Si no posee el atributo se le redirecciona a home.
      this.router.navigate(['']);
      return false;
    }
  }
}

