import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import { HomeService } from './home.service';
import { CreditoRender } from '../../constants/interfaces/salesforceInterfaces';
import { LoadingInfoService } from '../../reusableComponents/loading-info/loading-info.service';
import { MessagesService } from '../../reusableComponents/messages/messages.service';
import { ModalDetalleComponent } from './modal-detalle/modal-detalle.component';
import { ModalPagoManualComponent } from './modal-pago-manual/modal-pago-manual.component';
import { ModalPagoPseComponent } from './modal-pago-pse/modal-pago-pse.component';
import { ModalImagenPagoManualComponent } from './modal-imagen-pago-manual/modal-imagen-pago-manual.component';
import { environment } from '../../../environments/environment';
import { ModalActualizarPerfilComponent } from './modal-actualizar-perfil/modal-actualizar-perfil.component';
import { ErrorCertificadosComponent } from '../certificados/error-certificados/error-certificados.component';
import { AppService } from '../../app.service';

import { resultadoCreditos } from '../../constants/interfaces/salesforceInterfaces';

import { MatTableDataSource } from '@angular/material/table';
import { ModalCertificadoAnoGrabableComponent } from '../../components/certificados/modal-certificado-ano-grabable/modal-certificado-ano-grabable.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  dataBusqueda: MatTableDataSource<resultadoCreditos>;
  panelOpenState: boolean = false;
  panelOpenStateCerticados: boolean = false;
  soliciteAquiSuCredito: boolean = false;
  IdCampanaSoliciteAqui: string = '';
  codigoProductoSoliciteAqui: string = '';
  tipoDocumento: string = '';
  numeroDocumento: string = '';
  nombreCliente: string = '';
  empresa: { famiempresa: string; actividadEconomica: string };
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  descargar = false;
  descargarA = false;
  anosGrabableSelect = 0;
  getAccount: any;
  NombredeEmpresaoRazonSocial;
  creditosPazYSalvo = [];
  creditosYEstados = [];
  creditosAnoGrabable = [];
  fechaActual = new Date();
  disabledCambiarMontoTasa = false;

  CreditosEstados: CreditoRender = {
    vigente: [],
    preaprobado: [],
    preaprobadoEnProceso: [],
    preaprobadoPorDesembolsar: [],
    preaprobadoNoTomado: [],
    creditoEnProceso: [],
    cancelado: [],
  };
  panelExpanded = true;
  btnDisabled = true;
  tablePazySalvo = false;
  tableAnograbable = false;
  datos = {};

  constructor(
    private router: Router,
    private homeService: HomeService,
    private authService: MsalService,
    private loadingService: LoadingInfoService,
    private messageService: MessagesService,
    public Modal: MatDialog,
    private appService: AppService,
    private location: Location,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    //this.getToken()
    this.iconRegistry.addSvgIcon(
      'icono_actualizacion_datos',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/Images/Icono_Hoja_Lapiz.svg'
      )
    );

    this.iconRegistry.addSvgIcon(
      'icono_generar_certificados',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/Images/Icono_Hoja.svg'
      )
    );
  }

  ErrorCerrarSesionPorDobleSesion() {
    this.messageService.visibleMessage(
      {
        imgName: 'error.svg',
        texto1: `Sesión invalida`,
        texto2:
          'Al parecer ya tienes una sesión abierta, cierra la sesión e inicia de nuevo para continuar.',
        button: 'Cerrar sesión',
      },
      'CerrarSesion'
    );
  }

  ngOnInit(): void {
    let getAccount = this.authService.getAccount();
    let sub = getAccount.idTokenClaims.sub;
    this.appService.getUserAttributeSesion(sub).subscribe(
      (data) => {
        let sesionAtrributes = data.atrributes[0].Sesion;
        localStorage.setItem(
          'user',
          CryptoJS.AES.encrypt(
            data.userSignIn,
            environment.constantKey
          ).toString()
        );
        let sesion = sessionStorage.getItem('Sesion');
        if (getAccount == null || sesion != sesionAtrributes) {
          this.ErrorCerrarSesionPorDobleSesion();
        }
      },
      (error) => {}
    );

    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(
      (value) => (this.loadingInfo = value)
    );
    this.messageSubscription = this.messageService.messageStatus.subscribe(
      (value) => (this.messageVisble = value)
    );
    this.getAccount = this.authService.getAccount();
    const {
      idToken: { given_name, extension_TipodeDocumento },
    } = this.authService.getAccount();
    [this.nombreCliente, this.numeroDocumento, this.tipoDocumento] = [
      given_name,
      CryptoJS.AES.decrypt(
        localStorage.getItem('user'),
        environment.constantKey
      ).toString(CryptoJS.enc.Utf8),
      extension_TipodeDocumento,
    ];

    if (this.tipoDocumento === 'Nit') {
      this.appService
        .getUserAttributes(this.getAccount.idTokenClaims.sub)
        .subscribe(
          (data) => {
            this.NombredeEmpresaoRazonSocial =
              data.atrributes[0].NombredeEmpresaoRazonSocial;
          },
          (error) => {}
        );

      this.panelOpenState = true;
      this.panelOpenStateCerticados = true;
      this.loadingService.stopLoading();
    } else {
      this.OAuth2();
    }

    if (localStorage.getItem('origenCertificado') == '1') {
      localStorage.removeItem('origenCertificado');
      this.panelOpenStateCerticados = true;
      this.getCreditos();
    }
  }

  enableTablePazySalvo() {
    this.router.navigate(['/pazysalvo']);
  }

  enableAnoGravable() {
    this.router.navigate(['/anogravable']);
  }

  estadoCredito() {
    this.router.navigate(['/estacredito']);
  }

  toggleButton() {
    this.getCreditos();
  }

  getCreditos() {
    this.loadingService.startLoading();
    const anoPasado = this.fechaActual.getFullYear() - 1;
    const anoGravable = anoPasado.toString();
    this.homeService
      .obtenerCreditos(this.tipoDocumento, this.numeroDocumento)
      .subscribe(
        (data) => {
          if (data.response.encabezado.mensaje.estado !== 'OK') {
            this.messageService.visibleMessage(
              {
                imgName: 'error.svg',
                texto1: ``,
                msncreditos: true,
                enlace: 'https://interactuar.org.co/oficinas/',
                button: 'Aceptar',
              },
              'LoadingStop'
            );
          } else {
            const creditos =
              data.response.creditosVigCanClienteSalesForce.creditos;
            if (creditos.length > 0) {
              this.btnDisabled = !this.btnDisabled;
              this.creditosPazYSalvo = [];
              this.creditosYEstados = [];
              this.creditosAnoGrabable = [];

              //Todos los créditos en Estado ‘VIGENTE’,’VENCIDO’ o ‘CASTIGADO’
              const creditosPazYSalvoEstados = creditos.filter(
                (estado) =>
                  estado.estado == 'VIGENTE' ||
                  estado.estado == 'VENCIDO' ||
                  estado.estado == 'CASTIGADO'
              );
              if (creditosPazYSalvoEstados.length > 0) {
                this.creditosPazYSalvo.push(creditosPazYSalvoEstados);
                this.creditosAnoGrabable.push(creditosPazYSalvoEstados);
              }

              //Todos los créditos en Estado ‘CANCELADO’ y cuya fechaCancelacion sea menor o igual a 12 meses (valor 12 parametrizable).
              const creditosPazYSalvoCancelados = creditos.filter(
                (cancelado) =>
                  cancelado.estado == 'CANCELADO' &&
                  cancelado.fechaCancelacion.substring(0, 4) == anoGravable
              );
              if (creditosPazYSalvoCancelados.length > 0) {
                this.creditosPazYSalvo.push(creditosPazYSalvoCancelados);
                this.creditosAnoGrabable.push(creditosPazYSalvoCancelados);
              }
            }

            this.loadingService.stopLoading();
          }
        },
        (error) =>
          this.messageService.visibleMessage(
            {
              imgName: 'error.svg',
              texto1: ``,
              msncreditos: true,
              enlace: 'https://interactuar.org.co/oficinas/',
              button: 'Aceptar',
            },
            'LoadingStop'
          )
      );
  }

  OAuth2(): void {
    //this.loadingService.stopLoading();
    localStorage.removeItem('tasaPreAprobado');
    this.homeService
      .getParametrosCreditos(
        localStorage.getItem('tokenSalesforce'),
        localStorage.getItem('tokenB2C'),
        this.tipoDocumento,
        this.numeroDocumento
      )
      .subscribe(
        (object) => {
          //Si el objeto en la posicion 0 es undifined quiere decir que no se consultó los parametros generales
          if (object[0] === undefined) {
            this.soliciteAquiSuCredito =
              object.creditosEstadosAutogestion.soliciteAquiSuCredito; //Bandera de solicite aquí su crédito que entrega el servicio

            this.IdCampanaSoliciteAqui =
              object.creditosEstadosAutogestion.IdCampanaSoliciteAqui;
            this.codigoProductoSoliciteAqui =
              object.creditosEstadosAutogestion.codigoProductoSoliciteAqui;
            localStorage.setItem(
              'IdCampanaSoliciteAqui',
              this.IdCampanaSoliciteAqui
            );
            localStorage.setItem(
              'codigoProductoSoliciteAqui',
              this.codigoProductoSoliciteAqui
            );
            if ((this.IdCampanaSoliciteAqui = '')) {
              this.soliciteAquiSuCredito = false;
            }

            this.procesarCreditosEstados(
              object.creditosEstadosAutogestion.creditos
            );
          }
          //Si no quiere decir que se consultaron los parametros generales y los créditos, por lo cual se retorna un array con los dos objetos
          else {
            this.soliciteAquiSuCredito =
              object[1].creditosEstadosAutogestion.soliciteAquiSuCredito; //Bandera de solicite aquí su crédito que entrega el servicio
            this.IdCampanaSoliciteAqui =
              object[1].creditosEstadosAutogestion.IdCampanaSoliciteAqui;
            this.codigoProductoSoliciteAqui =
              object[1].creditosEstadosAutogestion.codigoProductoSoliciteAqui;
            localStorage.setItem(
              'IdCampanaSoliciteAqui',
              this.IdCampanaSoliciteAqui
            );
            localStorage.setItem(
              'codigoProductoSoliciteAqui',
              this.codigoProductoSoliciteAqui
            );

            if ((this.IdCampanaSoliciteAqui = '')) {
              this.soliciteAquiSuCredito = false;
            }

            this.procesarCreditosEstados(
              object[1].creditosEstadosAutogestion.creditos
            );
          }
          this.loadingService.stopLoading();
        },
        () => {
          //En caso de error, se hace una nueva consulta, esta vez se hace la consulta de 0, consultando los tokens de B2C y salesforce
          this.homeService.updateTokens().subscribe(
            () => {
              this.homeService
                .getParametrosCreditos(
                  localStorage.getItem('tokenSalesforce'),
                  localStorage.getItem('tokenB2C'),
                  this.tipoDocumento,
                  this.numeroDocumento
                )
                .subscribe(
                  (object) => {
                    this.soliciteAquiSuCredito =
                      object.creditosEstadosAutogestion.soliciteAquiSuCredito; //Bandera de solicite aquí su crédito que entrega el servicio
                    this.IdCampanaSoliciteAqui =
                      object.creditosEstadosAutogestion.IdCampanaSoliciteAqui;
                    this.IdCampanaSoliciteAqui =
                      object.creditosEstadosAutogestion.codigoProductoSoliciteAqui;
                    localStorage.setItem(
                      'IdCampanaSoliciteAqui',
                      this.IdCampanaSoliciteAqui
                    );
                    localStorage.setItem(
                      'codigoProductoSoliciteAqui',
                      this.codigoProductoSoliciteAqui
                    );

                    if ((this.IdCampanaSoliciteAqui = '')) {
                      this.soliciteAquiSuCredito = false;
                    }
                    this.procesarCreditosEstados(
                      object.creditosEstadosAutogestion.creditos
                    );
                    this.loadingService.stopLoading();
                  },
                  (error) =>
                    this.messageService.visibleMessage(
                      {
                        imgName: 'error.svg',
                        texto1: `Error ${error.origin}`,
                        texto2:
                          'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
                        button: 'Reintentar',
                        button2: 'Cerrar sesión',
                      },
                      'recargarPagina',
                      'Close2'
                    )
                );
            },
            (error) =>
              this.messageService.visibleMessage(
                {
                  imgName: 'error.svg',
                  texto1: `Error ${error.origin}`,
                  texto2:
                    'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
                  button: 'Reintentar',
                  button2: 'Cerrar sesión',
                },
                'recargarPagina',
                'Close2'
              )
          );
        }
      );
  }

  async procesarCreditosEstados(creditos) {
    this.empresa = {
      actividadEconomica: creditos[0].actividadEconomica,
      famiempresa: creditos[0].famiempresa,
    };

    for (let credito of creditos) {
      switch (credito.estado) {
        case 'Crédito vigente':
          this.CreditosEstados.vigente.push({
            ...credito,
            estadoCartera: {
              montoProximoPago: null,
              referenciaPago: null,
              fechaProximoPago: null,
              cuotasRestantes: null,
              saldoCancelacionTotal: null,
              estado: null,
              saldoEnMora: null,
              diasEnMora: null,
              plazo: null,
              montoDesembolsado: null,
            },
          });
          const index = this.CreditosEstados.vigente.length - 1; //Se guarda el indíce del nuevo elemento añadido, para después, una vez obtenida la respuesta del servico, reemplazar la información en el indíce correspondiente

          this.homeService.getToken().subscribe((data) => {
            var creditoRef = credito.referenciaPago;
            if (creditoRef == null) {
              creditoRef = credito.numeroCredito;
            }

            this.homeService
              .getEstadoCarteraCredito(creditoRef, data.request.token)
              .subscribe((data) => {
                this.CreditosEstados.vigente[index].estadoCartera = {
                  montoProximoPago:
                    data.productos.credito.estadoCartera.proximoPago.valor,
                  fechaProximoPago: moment(
                    data.productos.credito.estadoCartera.proximoPago.fecha
                  )
                    .locale('ES')
                    .format('DD/MMM/YYYY'),
                  cuotasRestantes:
                    data.productos.credito.estadoCartera.cuotasRestantes,
                  saldoCancelacionTotal:
                    data.productos.credito.estadoCartera.saldoCancelacionTotal,
                  estado: data.productos.credito.estado,
                  saldoEnMora: data.productos.credito.mora.valor,
                  diasEnMora: data.productos.credito.mora.dias,
                  plazo: data.productos.credito.plazo,
                  referenciaPago: data.productos.credito.referenciaPago,
                  montoDesembolsado: data.productos.credito.montoDesembolsado,
                };
              });
          });

          /* this.homeService.estadoCartera(credito.numeroCredito, localStorage.getItem('tokenB2C')).subscribe(
            data => {
              this.CreditosEstados.vigente[index].estadoCartera = {
                montoProximoPago: data.productos.credito.estadoCartera.proximoPago.valor,
                fechaProximoPago: moment(data.productos.credito.estadoCartera.proximoPago.fecha).locale('ES').format('DD/MMM/YYYY'),
                cuotasRestantes: data.productos.credito.estadoCartera.cuotasRestantes,
                saldoCancelacionTotal: data.productos.credito.estadoCartera.saldoCancelacionTotal,
                estado: data.productos.credito.estado,
                saldoEnMora: data.productos.credito.mora.valor,
                diasEnMora: data.productos.credito.mora.dias,
                plazo: data.productos.credito.plazo,
                referenciaPago: data.productos.credito.referenciaPago,
                montoDesembolsado: data.productos.credito.montoDesembolsado
              }
            },
          ) */
          break;
        case 'Preaprobado':
          const productosDigitales = JSON.parse(
            CryptoJS.AES.decrypt(
              localStorage.getItem('paramGen'),
              environment.constantKey
            ).toString(CryptoJS.enc.Utf8)
          )[8].valores;
          let disabledCambiarMonto = false;
          if (credito.indicadorCancelacion == false)
            disabledCambiarMonto = true;
          this.CreditosEstados.preaprobado.push({
            ...credito,
            simulador: null,
            disabledCambiarMonto,
          });
          const indx = this.CreditosEstados.preaprobado.length - 1; //Se guarda el indíce del nuevo
          this.homeService.getToken().subscribe((data) => {
            localStorage.setItem('IdCampanaPreaprobado', credito.idCampana);
            this.homeService
              .consultaTasaCampaña(data.request.token, credito.idCampana)
              .subscribe(
                (data) => {
                  if (
                    data.response.encabezado.mensaje.estado.trim() == 'ERROR'
                  ) {
                    this.disabledCambiarMontoTasa = true;
                    var msn = `Error: ${
                      data.response.encabezado.mensaje.descripcion +
                      ': ' +
                      credito.idCampana
                    }`;
                    this.loadingService.stopLoading();
                    this.messageService.visibleMessage(
                      {
                        title: msn,
                        imgName: 'error.svg',
                        texto1: ``,
                        msnCreditosSelect: true,
                        button: 'Cerrar',
                      },
                      'Info'
                    );
                  } else {
                    this.CreditosEstados.preaprobado[indx].simulador =
                      this.homeService.calcularCuota(
                        credito.monto,
                        credito.plazo,
                        data.response.consultaTasaCampanaSiif.campana.tasa
                      );
                    localStorage.setItem(
                      'tasaPreAprobado',
                      data.response.consultaTasaCampanaSiif.campana.tasa
                    );
                  }

                  this.loadingService.stopLoading();
                },
                (error) => {
                  this.loadingService.stopLoading();
                  this.messageService.visibleMessage(
                    {
                      //title: 'Error inesperado',
                      imgName: 'error.svg',
                      texto1: ``,
                      msnServiceError: true,
                      //msnCreditosSelect: true,
                      button: 'Cerrar',
                    },
                    'Info'
                  );
                }
              );
          });

          /* },
          error => {
            if (error?.error?.error?.Mensaje) {
              this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: error?.error?.error?.Mensaje })
            }
            else this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'En este momento no podemos establecer conexión con el simulador de créditos. Sin embargo puedes continuar solicitando créditos sin cambio de monto.' })
          }
        ) */
          break;
        case 'Preaprobado en proceso':
          this.CreditosEstados.creditoEnProceso.push({
            ...credito,
            simulador: null,
          });
          const indexPreaprobadoEnProceso =
            this.CreditosEstados.creditoEnProceso.length - 1; //Se guarda el indíce del nuevo elemento añadido, para después, una vez obtenida la respuesta del servico, reemplazar la información en el indíce correspondiente

          this.homeService.getToken().subscribe((data) => {
            localStorage.setItem('IdCampanaPreaprobado', credito.idCampana);
            this.homeService
              .consultaTasaCampaña(data.request.token, credito.idCampana)
              .subscribe(
                (data) => {
                  if (
                    data.response.encabezado.mensaje.estado.trim() == 'ERROR'
                  ) {
                    var msn = `Error: ${
                      data.response.encabezado.mensaje.descripcion +
                      ': ' +
                      credito.idCampana
                    }`;
                    this.loadingService.stopLoading();
                    this.messageService.visibleMessage(
                      {
                        title: msn,
                        imgName: 'error.svg',
                        texto1: ``,
                        msnCreditosSelect: true,
                        button: 'Cerrar',
                      },
                      'Info'
                    );
                  } else {
                    this.CreditosEstados.creditoEnProceso[
                      indexPreaprobadoEnProceso
                    ].simulador = this.homeService.calcularCuota(
                      credito.monto,
                      credito.plazo,
                      data.response.consultaTasaCampanaSiif.campana.tasa
                    );
                  }

                  this.loadingService.stopLoading();
                },
                (error) => {
                  this.loadingService.stopLoading();
                  this.messageService.visibleMessage(
                    {
                      //title: 'Error inesperado',
                      imgName: 'error.svg',
                      texto1: ``,
                      msnServiceError: true,
                      //msnCreditosSelect: true,
                      button: 'Cerrar',
                    },
                    'Info'
                  );
                }
              );
          });
          break;
        case 'Preaprobado por desembolsar':
          this.CreditosEstados.creditoEnProceso.push({
            ...credito,
            simulador: null,
          });
          const indexPreaprobadoPorDesembolsar =
            this.CreditosEstados.creditoEnProceso.length - 1; //Se guarda el indíce del nuevo elemento añadido, para después, una vez obtenida la respuesta del servico, reemplazar la información en el indíce correspondiente
          this.CreditosEstados.creditoEnProceso[
            indexPreaprobadoPorDesembolsar
          ].simulador = this.homeService.calcularCuota(
            credito.monto,
            credito.plazo
          );

          /*  this.homeService.simulator(credito.codigoProducto, credito.monto, credito.plazo, localStorage.getItem('tokenB2C'), this.tipoDocumento, this.numeroDocumento).subscribe(
             data => {
               this.CreditosEstados.creditoEnProceso[indexPreaprobadoPorDesembolsar].simulador = data.Cuota
             }
           ) */
          break;
        case 'Preaprobado no tomado':
          this.CreditosEstados.cancelado.push({ ...credito, simulador: null });
          const preaprobadoNoTomado = this.CreditosEstados.cancelado.length - 1; //Se guarda el indíce del nuevo elemento añadido, para después, una vez obtenida la respuesta del servico, reemplazar la información en el indíce correspondiente
          this.CreditosEstados.cancelado[preaprobadoNoTomado].simulador =
            this.homeService.calcularCuota(credito.monto, credito.plazo);
          /* this.homeService.simulator(credito.codigoProducto, credito.monto, credito.plazo, localStorage.getItem('tokenB2C'), this.tipoDocumento, this.numeroDocumento).subscribe(
            data => {
              this.CreditosEstados.cancelado[preaprobadoNoTomado].simulador = data.Cuota
            }
          ) */
          break;
        case 'Crédito en proceso':
          this.CreditosEstados.creditoEnProceso.push({
            ...credito,
            simulador: null,
          });
          const indexCreditoEnProceso =
            this.CreditosEstados.creditoEnProceso.length - 1; //Se guarda el indíce del nuevo elemento añadido, para después, una vez obtenida la respuesta del servico, reemplazar la información en el indíce correspondiente

          if (credito.idCampana !== null) {
            this.homeService.getToken().subscribe((data) => {
              localStorage.setItem('IdCampanaPreaprobado', credito.idCampana);
              this.homeService
                .consultaTasaCampaña(data.request.token, credito.idCampana)
                .subscribe(
                  (data) => {
                    if (
                      data.response.encabezado.mensaje.estado.trim() == 'ERROR'
                    ) {
                      var msn = `Error: ${
                        data.response.encabezado.mensaje.descripcion +
                        ': ' +
                        credito.idCampana
                      }`;
                      this.loadingService.stopLoading();
                      this.messageService.visibleMessage(
                        {
                          title: msn,
                          imgName: 'error.svg',
                          texto1: ``,
                          msnCreditosSelect: true,
                          button: 'Cerrar',
                        },
                        'Info'
                      );
                    } else {
                      this.CreditosEstados.creditoEnProceso[
                        indexCreditoEnProceso
                      ].simulador = this.homeService.calcularCuota(
                        credito.monto,
                        credito.plazo,
                        data.response.consultaTasaCampanaSiif.campana.tasa
                      );
                    }

                    this.loadingService.stopLoading();
                  },
                  (error) => {
                    this.loadingService.stopLoading();
                    this.messageService.visibleMessage(
                      {
                        //title: 'Error inesperado',
                        imgName: 'error.svg',
                        texto1: ``,
                        //msnCreditosSelect: true,
                        msnServiceError: true,
                        button: 'Cerrar',
                      },
                      'Info'
                    );
                  }
                );
            });
          }

          /* this.homeService.simulator(credito.codigoProducto, credito.monto, credito.plazo, localStorage.getItem('tokenB2C'), this.tipoDocumento, this.numeroDocumento).subscribe(
            data => {
              this.CreditosEstados.creditoEnProceso[indexCreditoEnProceso].simulador = data.Cuota
            }
          ) */
          break;
        case 'Crédito negado':
          this.CreditosEstados.cancelado.push({ ...credito, simulador: null });
          const cancelado = this.CreditosEstados.cancelado.length - 1; //Se guarda el indíce del nuevo elemento añadido, para después, una vez obtenida la respuesta del servico, reemplazar la información en el indíce correspondiente
          // this.homeService.simulator(credito.codigoProducto, credito.monto, credito.plazo, localStorage.getItem('tokenB2C'), this.tipoDocumento, this.numeroDocumento).subscribe(
          //   data => {
          //     this.CreditosEstados.cancelado[cancelado].simulador = data.Cuota
          //   }
          // )
          break;
        default:
          break;
      }

      if (credito.simulador === null) {
        credito.creditoPreaprobado.disabledCambiarMonto = true;
      }
    }
  }

  openModalDetalle(credito): void {
    this.Modal.open(ModalDetalleComponent, {
      data: credito,
      maxWidth: '100vw',
      maxHeight: '95vh',
      panelClass: 'openPazysalvoContainer',
    });
  }

  openModalPagoManual(): void {
    this.openModalImage();
  }

  openModalImage(): void {
    this.Modal.open(ModalImagenPagoManualComponent, {
      maxWidth: '100vw',

      panelClass: 'openImageContainer',
    });
  }

  openModalPagoPSE(): void {
    this.Modal.open(ModalPagoPseComponent, {
      panelClass: 'openPSEContainer',
    });
  }

  actualizarPerfil() {
    let user = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem('userMail'),
        environment.constantKey
      ).toString(CryptoJS.enc.Utf8)
    );
    this.Modal.open(ModalActualizarPerfilComponent, {
      data: {
        correoElectronico: user.Correo,
        numeroCelular: user.NumeroCelular,
        type: 1,
      },
      maxWidth: '100vw',
      maxHeight: '95vh',
      panelClass: 'actualizarPerfilMod',
    });
  }

  solicitarCredito(credito): void {
    this.homeService.saveCreditoPreaprobado(credito);
    this.router.navigate(['/solicitar-credito-1']);
  }

  //fuente: 1. cambiarMonto - 2. SolicitaAquíTuCrédito
  solicitarCreditoDigital(tipoCredito: string, creditoPreaprobado?): void {
    //monto, cambio de monto o solicitarCredito, famiempresa, actividadEconomica

    if (
      tipoCredito === 'SolicitaAquiTuCredito' ||
      !creditoPreaprobado.disabledCambiarMonto
    ) {
      var solicitaAqui = false;
      if (tipoCredito === 'SolicitaAquiTuCredito') {
        solicitaAqui = true;
      }
      this.homeService.saveCreditoPreaprobado({
        actividadEconomica: this.empresa.actividadEconomica,
        famiempresa: this.empresa.famiempresa,
        //"codigoProducto": JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("paramGen"), environment.constantKey).toString(CryptoJS.enc.Utf8))[7].valores[0].idProducto, //lista "Produtos digitales"
        /* "codigoProductoInicial": tipoCredito === "cambiarMonto" ? creditoPreaprobado.codigoProducto.toString() : null, */
        fuente: tipoCredito,
        idMiembroCampania:
          tipoCredito === 'cambiarMonto'
            ? creditoPreaprobado.idMiembroCampania
            : null,
        montoInicial:
          tipoCredito === 'cambiarMonto' ? creditoPreaprobado.monto : null,
        plazoInicial:
          tipoCredito === 'cambiarMonto' ? creditoPreaprobado.plazo : null,
        solicitaAqui: solicitaAqui,
      });
      this.router.navigate(['/cambiarMonto']);
    }
  }

  getToken() {
    setTimeout(() => {
      this.getToken();
    }, 300000);

    this.homeService.getToken().subscribe(
      (data) => {
        var fecha = new Date().toLocaleString('es-CL', {
          timeZone: 'America/Bogota',
        });
        var fecha1 = moment(fecha, 'YYYY-MM-DD HH:mm:ss');
        var fecha2 = moment(data.request.fecha, 'YYYY-MM-DD HH:mm:ss');
        var minutos = fecha2.diff(fecha1, 'm');
        if (minutos < 0) {
          minutos = minutos * -1;
        }
        if (minutos >= 10) {
          this.homeService.getTokenSIIF().subscribe((data) => {
            localStorage.setItem('tokenSIIF', data.acces_token);
            this.homeService.setToken(data.acces_token).subscribe((data) => {});
          });
        } else {
          localStorage.setItem('tokenSIIF', data.request.token);
        }
      },
      (error) => {}
    );
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
  }
}
