import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { MsalService } from '@azure/msal-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

import { onCredit } from './constants/api/end-points';
import { initialSalesforce, B2C, graph } from './constants/api/end-points';
import { environment } from '../environments/environment';
import { salesforceEncabezado } from './constants/api/encabezados';
import {
  OAuth2,
  respuestaSendNotification,
} from './constants/interfaces/salesforceInterfaces';
import { userAttributes } from './constants/interfaces/B2CInterfaces';
import { log } from 'console';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: HttpClient, private authService: MsalService) { }

  updateTokens(): Observable<any> {
    return this.http
      .post<any>(
        B2C.tokenApiManagment,
        {
          secret: environment.secretTokenB2C,
          clientId: environment.clientIdTokenB2C,
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          }),
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        mergeMap((tokenB2C) =>
          this.http
            .post<OAuth2>(initialSalesforce.OAuth2, null, {
              headers: new HttpHeaders({
                'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                Authorization: tokenB2C,
              }),
            })
            .pipe(
              tap((data) => {
                localStorage.setItem('tokenSalesforce', data.access_token);
                localStorage.setItem('tokenB2C', tokenB2C);
              }), //the RxJS tap() operator, which looks at the observable values, does something with those values, and passes them along. The tap() call back doesn't touch the values themselves.
              catchError((e) =>
                throwError({ origin: 'consulta token SalesForce', error: e })
              )
            )
        ),
        catchError((e) =>
          throwError({ origin: 'consulta token B2C', error: e })
        )
      );
  }

  enableForm(type: string = null): Observable<any> {
    const {
      idToken: { extension_TipodeDocumento },
    } = this.authService.getAccount();
    let tipoDocumento: string;
    switch (extension_TipodeDocumento) {
      case 'Cedula de Ciudadania':
        tipoDocumento = '1';
        break;
      case 'Cedula de Extranjeria':
        tipoDocumento = '2';
        break;
      case 'Pasaporte':
        tipoDocumento = '4';
        break;
      default:
        break;
    }
    return this.http
      .post<any>(
        onCredit.login,
        { email: environment.emailCoxti, password: environment.passCoxti },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        mergeMap((tokenLogin) =>
          this.http
            .post<any>(
              onCredit.enableValidate,
              {
                identification: CryptoJS.AES.decrypt(
                  localStorage.getItem('user'),
                  environment.constantKey
                ).toString(CryptoJS.enc.Utf8),
                id_type: tipoDocumento,
                id_url: '1',
              },
              {
                headers: new HttpHeaders({
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
                  'Auth-Coxti': tokenLogin.access_token.token,
                }),
              }
            )
            .pipe(
              tap(
                (data) =>
                (window.location.href =
                  window.location.href +
                  '/identityValidation?token=tokenDummie&stepValidation=' +
                  type)
              ),
              catchError((e) =>
                throwError({ origin: 'habilitando formulario', error: e })
              )
            )
        ),
        catchError((e) =>
          throwError({ origin: 'login formulario Coxti', error: e })
        )
      );
  }

  responseForm(tokenEnableForm: string): Observable<any> {
    return this.http
      .post<any>(
        onCredit.login,
        { email: environment.emailCoxti, password: environment.passCoxti },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        mergeMap((tokenLogin) =>
          this.http
            .post<any>(
              onCredit.validationStatus,
              { token: tokenEnableForm },
              {
                headers: new HttpHeaders({
                  Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  'Auth-Coxti': `${tokenLogin.access_token.token}`,
                }),
              }
            )
            .pipe(
              catchError((e) =>
                throwError({
                  origin: 'consulta del estado de validación',
                  message: 'Por favor vuelva a validar la identidad',
                })
              )
            )
        ),
        catchError((e) =>
          throwError({
            origin: 'login formulario Coxti',
            message: 'Por favor vuelva a validar la identidad',
          })
        )
      );
  }

  tipoCliente(numeroDocumento: string, tipoDocumento: string): Observable<any> {
    let hash = this.encrypt('Tipo Cliente Autogestión' + ',' + numeroDocumento);

    return this.http
      .post<any>(
        B2C.tokenApiManagment,
        {
          secret: environment.secretTokenB2C,
          clientId: environment.clientIdTokenB2C,
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          }),
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        mergeMap((tokenB2C) =>
          this.http
            .post<any>(initialSalesforce.OAuth2, null, {
              headers: new HttpHeaders({
                'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                Authorization: tokenB2C,
              }),
            })
            .pipe(
              tap((tokenSalesforce) => {
                localStorage.setItem(
                  'tokenSalesforce',
                  tokenSalesforce.access_token
                );
                localStorage.setItem('tokenB2C', tokenB2C);
              }),
              mergeMap((tokenSalesforce) =>
                this.http
                  .post<any>(
                    initialSalesforce.tipoCliente,
                    {
                      encabezado: salesforceEncabezado(
                        'Tipo Cliente Autogestión'
                      ),
                      tipoClienteAutogestion: {
                        tipoDocumento: tipoDocumento,
                        numeroDocumento: numeroDocumento, //15365843
                      },
                    },
                    {
                      headers: new HttpHeaders({
                        'Ocp-Apim-Subscription-Key':
                          environment.SubscriptionKey, //'132e3eb898954b06ab8bc6bef9e1901f
                        Authorization: `Bearer ${tokenB2C}`,
                        'Header-SF': tokenSalesforce.access_token,
                        hash: hash,
                      }),
                    }
                  )
                  .pipe(
                    catchError((e) =>
                      throwError({ origin: 'consulta tipo cliente', error: e })
                    ) //3ro
                  )
              ),
              catchError((e) =>
                throwError({ origin: 'consulta tipo cliente', error: e })
              ) //2do
            )
        ),
        catchError((e) =>
          throwError({ origin: 'consulta tipo cliente', error: e })
        ) //1ro
      );
  }

  tipoClienteSinTokens(
    numeroDocumento: string,
    tipoDocumento: string
  ): Observable<any> {
    let hash = this.encrypt('Tipo Cliente Autogestión' + ',' + numeroDocumento);

    return this.http
      .post<any>(
        initialSalesforce.tipoCliente,
        {
          encabezado: salesforceEncabezado('Tipo Cliente Autogestión'),
          tipoClienteAutogestion: {
            tipoDocumento: tipoDocumento,
            numeroDocumento: numeroDocumento, //15365843
          },
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, //'132e3eb898954b06ab8bc6bef9e1901f
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
            'Header-SF': localStorage.getItem('tokenSalesforce'),
            hash: hash,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({ origin: 'consulta tipo cliente', error: e })
        )
      );
  }

  getUserAttributes(user: string): Observable<userAttributes> {
    return this.http
      .post<any>(
        B2C.tokenApiManagment,
        {
          secret: environment.secretTokenB2C,
          clientId: environment.clientIdTokenB2C,
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          }),
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        tap((tokenB2C) => {
          localStorage.setItem('tokenB2C', tokenB2C);
        }),
        mergeMap((tokenB2C) =>
          this.http
            .post<userAttributes>(
              graph.userAttributes +
              '?attrs=IdentidadValida,NumeroDeIntentos,Correo,NumeroCelular,ActualizarDatos,NombredeEmpresaoRazonSocial,MotivoBloqueo',
              { userid: user },
              {
                headers: new HttpHeaders({
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  Authorization: `Bearer ${tokenB2C}`,
                }),
              }
            )
            .pipe(
              catchError((e) =>
                throwError({ origin: 'consulta obtener atributos', error: e })
              )
            )
        ),
        catchError((e) =>
          throwError({ origin: 'consulta token B2C', error: e })
        )
      );

    /* return this.http.post<userAttributes>(
      graph.userAttributes + '?attrs=IdentidadValida,NumeroDeIntentos,Correo,NumeroCelular,ActualizarDatos,NombredeEmpresaoRazonSocial,MotivoBloqueo',
      { "userid": user },
      { headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${localStorage.getItem('tokenB2C')}` }) }
    ).pipe(
      catchError(e => throwError({ origin: 'consulta obtener atributos', error: e }))
    ) */
  }
  getUserAttributeSesion(user: string): Observable<userAttributes> {
    return this.http
      .post<any>(
        B2C.tokenApiManagment,
        {
          secret: environment.secretTokenB2C,
          clientId: environment.clientIdTokenB2C,
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          }),
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        tap((tokenB2C) => {
          localStorage.setItem('tokenB2C', tokenB2C);
        }),
        mergeMap((tokenB2C) =>
          this.http
            .post<userAttributes>(
              graph.userAttributes + '?attrs=Sesion',
              { userid: user },
              {
                headers: new HttpHeaders({
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  Authorization: `Bearer ${tokenB2C}`,
                }),
              }
            )
            .pipe(
              catchError((e) =>
                throwError({ origin: 'consulta obtener atributos', error: e })
              )
            )
        ),
        catchError((e) =>
          throwError({ origin: 'consulta token B2C', error: e })
        )
      );
  }

  deleteUser(user: string): Observable<any> {
    return this.http
      .request<any>('delete', graph.listUsers, {
        headers: new HttpHeaders({
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
        }),
        body: { userid: user },
      })
      .pipe(
        catchError((e) =>
          throwError({ origin: 'consultando cuenta', error: e })
        )
      );
  }

  updateUserAttributes(
    valor: boolean,
    user: string,
    num: number,
    tipo: string,
    email: string
  ): Observable<{}> {
    //PRIMERO SE DEBE DE OBTENER EL NÚMERO DE INTENTOS PARA ACTUALIZAR EL CONTADOR
    return this.http
      .put<{}>(
        graph.userAttributes,
        {
          userid: user,
          attributes: [
            { key: 'IdentidadValida', value: `${valor}` },
            { key: 'NumeroDeIntentos', value: `${num}` },
            { key: 'TipoDeValidacion', value: `${tipo}` },
            { key: 'Correo', value: `${email}` },
          ],
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({
            origin: 'consulta de actualización de atributos',
            error: e,
          })
        )
      );
  }

  updateUserAttributeSesion(user: string, sesion: string): Observable<{}> {
    //primero Llama el token y luego se actualiza el parametro
    return this.http
      .post<any>(
        B2C.tokenApiManagment,
        {
          secret: environment.secretTokenB2C,
          clientId: environment.clientIdTokenB2C,
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          }),
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        tap((tokenB2C) => {
          localStorage.setItem('tokenB2C', tokenB2C);
        }),
        mergeMap((tokenB2C) =>
          this.http
            .put<{}>(
              graph.userAttributes,
              {
                userid: user,
                attributes: [{ key: 'Sesion', value: `${sesion}` }],
              },
              {
                headers: new HttpHeaders({
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  Authorization: `Bearer ${tokenB2C}`,
                }),
              }
            )
            .pipe(
              catchError((e) =>
                throwError({
                  origin: 'consulta de actualización de atributos',
                  error: e,
                })
              )
            )
        ),
        catchError((e) =>
          throwError({ origin: 'consulta token B2C', error: e })
        )
      );
  }

  updateNoUserAttributes(user: string): Observable<{}> {
    //PRIMERO SE DEBE DE OBTENER EL NÚMERO DE INTENTOS PARA ACTUALIZAR EL CONTADOR
    return this.http
      .put<{}>(
        graph.userAttributes,
        {
          userid: user,
          attributes: [{ key: 'TipoDeValidacion', value: `NO CLIENTE` }],
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({
            origin: 'consulta de actualización de atributos',
            error: e,
          })
        )
      );
  }
  sendNotification(
    document: string,
    email: string,
    phoneNumber: string,
    type: string
  ): Observable<respuestaSendNotification> {
    let hash = this.encrypt(
      type +
      ',' +
      CryptoJS.AES.decrypt(
        localStorage.getItem('user'),
        environment.constantKey
      ).toString(CryptoJS.enc.Utf8)
    );
    return this.http
      .post<respuestaSendNotification>(
        initialSalesforce.enviarNotificacion,
        {
          Encabezado: {
            destino: 'MICROSITIO',
            integracion: type,
          },
          Cuerpo: {
            tipoDocumento: document,
            numeroDocumento: CryptoJS.AES.decrypt(
              localStorage.getItem('user'),
              environment.constantKey
            ).toString(CryptoJS.enc.Utf8),
            celular: phoneNumber,
            correo: email,
          },
        },
        {
          headers: new HttpHeaders({
            // 'Access-Control-Allow-Origin:': '*', "Accept": "application/json",
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
            'Header-SF': localStorage.getItem('tokenSalesforce'),
            hash: hash,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({ origin: 'enviar notificación', error: e })
        )
      );
  }

  blockUser(
    user: string,
    state: boolean,
    motivo: string = ''
  ): Observable<any> {
    return this.http
      .put<any>(
        graph.userStatus,
        { userid: user, enable: state, MotivoBloqueo: motivo },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({ origin: 'consulta blockUser', error: e })
        )
      );
  }

  logout() {
    localStorage.removeItem('tokenSalesforce');
    localStorage.removeItem('tokenB2C');
    localStorage.removeItem('user');
    localStorage.removeItem('object');
    localStorage.removeItem('tasaPreAprobado');
    this.authService.logout();
  }

  updateFechaUltimoAccesoAttribute(): Observable<{}> {
    let fecha = new Date();
    let hours = fecha.getHours();
    let hoursF = ((hours + 11) % 12) + 1;
    let fechaFinal =
      fecha.getFullYear() +
      '-' +
      ('0' + (fecha.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + fecha.getDate()).slice(-2) +
      ' ' +
      ('0' + hoursF).slice(-2) +
      ':' +
      ('0' + fecha.getMinutes()).slice(-2) +
      ':' +
      fecha.getSeconds();
    return this.http
      .put<{}>(
        graph.userAttributes,
        {
          userid: this.authService.getAccount().idTokenClaims.sub,
          attributes: [{ key: 'FechaUltimoAcceso', value: fechaFinal }],
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({
            origin: 'consulta de actualización de atributos',
            error: e,
          })
        )
      );
  }

  validateIdentity(OTP: string, cedula: string): Observable<any> {
    const {
      idToken: { extension_TipodeDocumento },
    } = this.authService.getAccount();
    const {
      idToken: { extension_NumeroCelular },
    } = this.authService.getAccount();
    var data = this.authService.getAccount();
    var fecha = new Date().toLocaleString('es-CL', {
      timeZone: 'America/Bogota',
    });
    var integracion = 'Validación OTP registro en micrositio';
    var notificacion = 'NOTIFICACIONOTPREGISTRO';
    var email = 'SI';
    var numeroCelular = '';
    let OTPEncrit = this.encrypt(OTP);
    let OTPEncritEmail = OTPEncrit;

    if (localStorage.getItem('origenValidaridentidad') == 'ActualizarDatos') {
      integracion = 'Validación OTP actualización de datos en micrositio';
      notificacion = 'NOTIFICACIONOTPACTUALIZADATOS';
      email = 'NO';
      numeroCelular = localStorage.getItem('celularActualizar');
      OTPEncritEmail = '';
    }

    let hash = this.encrypt(notificacion + ',' + cedula);

    return this.http
      .post<any>(
        graph.sendNotification,
        {
          encabezado: {
            origen: 'MICROSITIO',
            destino: 'SALESFORCE',
            integracion: integracion,
            fecha: fecha,
          },
          notificacionesSMSEmail: {
            nombreNotificacion: notificacion,
            sms: 'SI',
            email: email,
            tipoDocumento: extension_TipodeDocumento,
            nroDocumento: cedula,
            mensajeSMS: OTPEncrit,
            mensajeEmail: OTPEncritEmail,
            celular: numeroCelular,
          },
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
            'Header-SF': localStorage.getItem('tokenSalesforce'),
            hash: hash,
          }),
        }
      )
      .pipe(
        catchError((e) => throwError({ origin: 'Validad identidad', error: e }))
      );
  }

  encrypt(value: string): string {
    const cipher = CryptoJS.AES.encrypt(
      value,
      CryptoJS.enc.Utf8.parse(environment.Semillasecreto),
      {
        iv: CryptoJS.enc.Utf8.parse(environment.Semillainicialización),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );

    return cipher.toString();
  }

  decrypt(textToDecrypt: string) {
    const cipher = CryptoJS.AES.decrypt(
      textToDecrypt,
      CryptoJS.enc.Utf8.parse(environment.Semillasecreto),
      {
        iv: CryptoJS.enc.Utf8.parse(environment.Semillainicialización),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );

    return cipher.toString();
  }
}
