<div mat-dialog-title style="text-align: end;">
  <button _ngcontent-qsw-c179="" class="x ng-star-inserted cerrar" (click)="cerrar()">X</button>
</div>
<div class="card">
  <img src="../../../assets/Images/info.svg">
  <h6 class="ng-star-inserted">{{textoBienvenida}}
    para continuar, por favor ingrese su código: </h6>
    <form [formGroup]="formNumeroDocumento" (ngSubmit)="validateCode()">
      <div class="row box">
        <div class="col-4"></div>
        <div class="col-1"><input formControlName="code1" type="text" tabindex="1" maxlength="1" style="text-align: center; font-weight: bold;" (keyup)="onKeypressEvent($event)" #input1>
        </div>
        <div class="col-1"><input formControlName="code2" type="text" (keyup)="onKeypressEvent2($event)" tabindex="2" maxlength="1" style="text-align: center; font-weight: bold;" #input2></div>
        <div class="col-1"><input formControlName="code3" type="text" (keyup)="onKeypressEvent3($event)" tabindex="3" maxlength="1" style="text-align: center; font-weight: bold;" #input3></div>
        <div class="col-1"><input formControlName="code4" type="text" tabindex="4" maxlength="1" style="text-align: center; font-weight: bold;" #input4></div>
        <div class="col-4"></div>

        <button type="submit" class="continuar" style="background-color: #007bff; color: white;">Validar Código</button>
        <button type="button" (click)="cerrar()" class="continuar" style="margin-top: 4% !important;">Cerrar</button>

      </div>
    </form>
    <p *ngIf="ingresoCodigo" style="color: red; text-align: center;">Aún no ingresa ningún código</p>

  <p style="text-align: center;">*Si no recibiste ningún código o éste se encuentra vencido dar <a [routerLink]="" (click)="validateIdentity()">clic aquí</a> </p>
</div>
