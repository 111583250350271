import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ReactiveFormsModule } from "@angular/forms"
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { Configuration } from 'msal';
import { MsalModule, MsalInterceptor, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalService, MsalAngularConfiguration } from '@azure/msal-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { msalConfig, msalAngularConfig } from './constants/api/app-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { UnauthHomeComponent } from './components/unauth-home/unauth-home.component';
import { CalculoCuotaMensualPipe } from './customPipe/calculo-cuota-mensual.pipe';
import { LoadingInfoComponent } from './reusableComponents/loading-info/loading-info.component';
import { MessagesComponent } from './reusableComponents/messages/messages.component';
import { ModalDetalleComponent } from './components/home/modal-detalle/modal-detalle.component';
import { CambiarMontoComponent } from './components/cambiar-monto/cambiar-monto.component';
import { NotFoundComponentComponent } from './components/not-found-component/not-found-component.component';
import { AdminComponent } from './components/admin/admin.component';
import { SolicitarCreditoComponent } from './components/solicitar-credito/solicitar-credito.component';
import { DatosDesembolsoComponent } from './components/solicitar-credito/datos-desembolso/datos-desembolso.component';
import { DatosSeguroComponent } from './components/solicitar-credito/datos-seguro/datos-seguro.component';
import { SolicitarcreditoSinpreaprobadoCambiomontoComponent } from './components/solicitarcredito-sinpreaprobado-cambiomonto/solicitarcredito-sinpreaprobado-cambiomonto.component';
import { ActualizarDatosComponent } from './components/solicitarcredito-sinpreaprobado-cambiomonto/actualizar-datos/actualizar-datos.component';
import { DatosParaDesembolsoComponent } from './components/solicitarcredito-sinpreaprobado-cambiomonto/datos-para-desembolso/datos-para-desembolso.component';
import { DatosDelSeguroComponent } from './components/solicitarcredito-sinpreaprobado-cambiomonto/datos-del-seguro/datos-del-seguro.component';
import { ResumenCreditoComponent } from './components/solicitarcredito-sinpreaprobado-cambiomonto/resumen-credito/resumen-credito.component';
import { ModalPagoManualComponent } from './components/home/modal-pago-manual/modal-pago-manual.component';
import { ModalActualizarPerfilComponent } from './components/home/modal-actualizar-perfil/modal-actualizar-perfil.component';
import { ModalEliminarUsuarioComponent } from './components/admin/modal-eliminar-usuario/modal-eliminar-usuario.component';
import { ModalPagoPseComponent } from './components/home/modal-pago-pse/modal-pago-pse.component';
import { ModalImagenPagoManualComponent } from './components/home/modal-imagen-pago-manual/modal-imagen-pago-manual.component';
import { solicitarCreditoReducer } from './components/solicitar-credito/state/solicitar-credito.reducer';
import { solicitarCreditoSinPreaprobadoReducer } from './components/solicitarcredito-sinpreaprobado-cambiomonto/state/solicitar-credito-sin-preabrobado.reducer';
import { environment } from 'src/environments/environment';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ModalCertificadoPazysalvoComponent } from './components/certificados/modal-certificado-pazysalvo/modal-certificado-pazysalvo.component';
import { registerLocaleData } from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import { ErrorCertificadosComponent } from './components/certificados/error-certificados/error-certificados.component';
import { ModalCertificadoAnoGrabableComponent } from './components/certificados/modal-certificado-ano-grabable/modal-certificado-ano-grabable.component';
import { PazysalvoComponent } from './components/certificados/pazysalvo/pazysalvo.component';
import { AnograbableComponent } from './components/certificados/anograbable/anograbable.component';
import { EstadocreditoComponent } from './components/certificados/estadocredito/estadocredito.component';
import { ModalEstadoCreditoComponent } from './components/certificados/modal-estado-credito/modal-estado-credito.component';

import { Pipe, PipeTransform } from '@angular/core';import { DomSanitizer } from '@angular/platform-browser';
import { ValidateIdentityComponent } from './components/validate-identity/validate-identity.component';
import { ReenviarCodigoComponent } from './components/reenviar-codigo/reenviar-codigo.component';
import { CanceladoComponent } from './components/cancelado/cancelado.component'; @Pipe({ name: 'safe'})export class SafePipe implements PipeTransform { constructor(private sanitizer: DomSanitizer) { } transform(url) { return this.sanitizer.bypassSecurityTrustResourceUrl(url); } }

    registerLocaleData('es');


function MSALConfigFactory(): Configuration {
  return msalConfig;
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return msalAngularConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UnauthHomeComponent,
    CalculoCuotaMensualPipe,
    LoadingInfoComponent,
    MessagesComponent,
    ModalDetalleComponent,
    CambiarMontoComponent,
    NotFoundComponentComponent,
    AdminComponent,
    SolicitarCreditoComponent,
    DatosDesembolsoComponent,
    DatosSeguroComponent,
    SolicitarcreditoSinpreaprobadoCambiomontoComponent,
    ActualizarDatosComponent,
    DatosParaDesembolsoComponent,
    DatosDelSeguroComponent,
    ResumenCreditoComponent,
    ModalPagoManualComponent,
    ModalActualizarPerfilComponent,
    ModalEliminarUsuarioComponent,
    ModalPagoPseComponent,
    ModalImagenPagoManualComponent,
    ModalCertificadoPazysalvoComponent,
    ErrorCertificadosComponent,
    ModalCertificadoAnoGrabableComponent,
    PazysalvoComponent,
    AnograbableComponent,
    EstadocreditoComponent,
    ModalEstadoCreditoComponent,
    SafePipe,
    ValidateIdentityComponent,
    ReenviarCodigoComponent,
    CanceladoComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    HttpClientModule,
    MsalModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    MatCheckboxModule,
    MatSelectModule,
    BrowserModule,
    StoreModule.forRoot({ solicitarCredito: solicitarCreditoReducer, solicitarCreditoSinPreaprobado: solicitarCreditoSinPreaprobadoReducer }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },

    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
