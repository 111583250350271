import { Component, OnInit } from '@angular/core';
import { MessagesService } from './messages.service';
import { LoadingInfoService } from '../loading-info/loading-info.service';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { ReenviarCodigoComponent } from 'src/app/components/reenviar-codigo/reenviar-codigo.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
})
export class MessagesComponent implements OnInit {
  datos = {};
  registroinicial = true;
  constructor(
    public messageService: MessagesService,
    public Modal: MatDialog,
    private loadingService: LoadingInfoService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('origenValidaridentidad') == 'ActualizarDatos') {
      this.registroinicial = false;
    }
  }

  validateIdentity() {
    this.loadingService.startLoading();
    //Se cambia lo que se envia para redirección OTP a true

    const OTP = this.getRandomInt(9999, 1000);
    this.appService
      .getUserAttributes(localStorage.getItem('idUsuario'))
      .toPromise()
      .then((data) => {
        this.appService
          .validateIdentity(OTP.toString(), data.userSignIn)
          .toPromise()
          .then(
            (data) => {
              if (
                data.notificaciones.sms.estado == 'OK' ||
                data.notificaciones.email.estado == 'OK'
              ) {
                var texto1 = `Código enviado a su celular y/o correo electrónico`;
                if (
                  localStorage.getItem('origenValidaridentidad') ==
                  'ActualizarDatos'
                ) {
                  texto1 = `Código enviado a su celular`;
                }
                this.messageService.visibleMessage(
                  {
                    imgName: 'info.svg',
                    texto1: texto1,
                    texto3: OTP.toString(),
                    button: 'Cerrar',
                    parametro: '1',
                  },
                  'infoOTP2'
                );
              } else {
                this.messageService.visibleMessage(
                  {
                    imgName: 'error.svg',
                    texto1: ``,
                    button: 'Cerrar',
                    msnNoficacionFallida: true,
                  },
                  'CerrarSesion'
                );
              }
            },
            (error) =>
              this.messageService.errorMessage({
                title: `Error ${error.origin}`,
                texto1:
                  'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
              })
          );
      });
  }

  getRandomInt(max, min) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  reenviarCodigo() {
    this.messageService._messageVisible = false;
    const dialogRef = this.Modal.open(ReenviarCodigoComponent, {
      data: { OTP: 'VENCIDO', intetos: '1' },
      maxWidth: '70vh',
      maxHeight: '95vh',
      panelClass: 'openpazysalvoContainer',
      disableClose: true,
    });
  }
}
