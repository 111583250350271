import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MessagesService } from 'src/app/reusableComponents/messages/messages.service';
import { CanceladoComponent } from '../../cancelado/cancelado.component';

@Component({
  selector: 'app-modal-detalle',
  templateUrl: './modal-detalle.component.html',
  styleUrls: ['./modal-detalle.component.css'],
})
export class ModalDetalleComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      codigoProducto: number;
      estado: string;
      fechaDesembolso: string;
      monto: number;
      numeroCredito: string;
      plazo: number;
      estadoCartera: {
        montoProximoPago: number | null;
        fechaProximoPago: string | null;
        cuotasRestantes: number | null;
        saldoCancelacionTotal: number | null;
        estado: string | null;
        saldoEnMora: number | null;
        diasEnMora: number | null;
        plazo: number | null;
        montoDesembolsado: number | null;
        referenciaPago: string | null;
      };
    },
    private messageService: MessagesService,
    public Modal: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.data.estadoCartera.saldoCancelacionTotal == 0) {
      this.Modal.open(CanceladoComponent, {
        data: {},
        maxWidth: '50vw',
        maxHeight: '95vh',
        panelClass: 'openCancveladosContainer',
      });
    }
  }
}
