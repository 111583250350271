import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatDialog } from "@angular/material/dialog";

import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import * as CryptoJS from "crypto-js";
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { MessagesService } from "../../../reusableComponents/messages/messages.service";
import { LoadingInfoService } from "../../../reusableComponents/loading-info/loading-info.service";
import { ModalActualizarPerfilComponent } from "../../home/modal-actualizar-perfil/modal-actualizar-perfil.component";
import { environment } from "../../../../environments/environment";
import { ResumenCreditoService } from "./resumen-credito.service";
import { StateCreditos } from 'src/app/constants/interfaces/solicitar-credito.interface';

@Component({
  selector: 'app-resumen-credito',
  templateUrl: './resumen-credito.component.html',
  styleUrls: ['./resumen-credito.component.css']
})

export class ResumenCreditoComponent implements OnInit {
  messageBack: boolean = false;
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  creditoPreaprobado: { codigoProducto: string, estado: string, fechaDesembolso: null | string, monto: number, numeroCredito: null | number, plazo: number, simulador: number | null, idMiembroCampania: string | null, Cargos: number | null, Total: number | null, actividadEconomica: string, famiempresa: string, fuente: string, tipoRespuesta?: string };
  creditoPreaprobado$: Observable<any> = this.store.select(state => state.solicitarCreditoSinPreaprobado.preaprobado);
  dataFormDesembolso: { banco: string, tipoCuenta: string, numeroCuenta: string } | null;
  dataFormDesembolso$: Observable<any> = this.store.select(state => state.solicitarCreditoSinPreaprobado.formDesembolso);
  dataFormActualizarDatos: { estadoCivil: string, tipoVivienda: string, telefonoArrendador: string, nombreArrendador: string };
  dataFormActualizarDatos$: Observable<any> = this.store.select(state => state.solicitarCreditoSinPreaprobado.formActualizarDatos);
  dataFormSeguro: { nombreCompleto: string, tipoDocumento: string, numeroDocumento: string, parentesco: string, porcentaje: number }[];
  dataFormSeguro$: Observable<any> = this.store.select(state => state.solicitarCreditoSinPreaprobado.formSeguro);

  constructor(private router: Router, private ResumenCreditoService: ResumenCreditoService, private messageService: MessagesService, private authService: MsalService, private loadingService: LoadingInfoService, private Modal: MatDialog, private store: Store<StateCreditos>,) {
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value);
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((value) => this.loadingInfo = value)
    this.creditoPreaprobado$.pipe(take(1)).subscribe((data: any) => this.creditoPreaprobado = data);
    this.dataFormDesembolso$.pipe(take(1)).subscribe((data: any) => this.dataFormDesembolso = data);
    this.dataFormActualizarDatos$.pipe(take(1)).subscribe((data: any) => this.dataFormActualizarDatos = data);
    this.dataFormSeguro$.pipe(take(1)).subscribe((data: any) => this.dataFormSeguro = data);
    if (Object.keys(this.creditoPreaprobado).length === 0 || Object.keys(this.dataFormSeguro).length === 0) this.router.navigate(["/"]);
  }

  ngOnInit() {
    window.scroll(0, 0)
  }

  regresar() {
    this.messageBack = true;
  }

  continuarSolicitud() {
    this.messageBack = false;
  }

  solicitarCredito() {
    const { idToken: { extension_TipodeDocumento } } = this.authService.getAccount();
    let user = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("userMail"), environment.constantKey).toString(CryptoJS.enc.Utf8));
    let respuesta;

    if (this.creditoPreaprobado.fuente === "cambiarMonto") {
      respuesta = {
        "tipoDocumento": extension_TipodeDocumento,
        "numeroDocumento": CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8),
        "monto": this.creditoPreaprobado.monto,
        "plazo": this.creditoPreaprobado.plazo,
        "correoElectronico": user.Correo,
        "numeroCelular": user.NumeroCelular,
        "producto": parseInt(this.creditoPreaprobado.codigoProducto),
        "aceptoCliente": true,
        "tipoRespuesta": this.creditoPreaprobado.tipoRespuesta,
        "medioRespuesta": "Respuesta Web",
        "fechaRespuesta": moment().format('YYYY-MM-DD HH:mm:ss'),
        "tipoCuenta": this.dataFormDesembolso.tipoCuenta,
        "numeroCuenta": this.dataFormDesembolso.numeroCuenta,
        "banco": this.dataFormDesembolso.banco,
        "tipoVivienda": this.dataFormActualizarDatos.tipoVivienda.toUpperCase(),
        "telefonoArrendador": this.dataFormActualizarDatos.telefonoArrendador ? parseInt(this.dataFormActualizarDatos.telefonoArrendador) : null,
        "nombreArrendador": this.dataFormActualizarDatos.nombreArrendador ? this.dataFormActualizarDatos.nombreArrendador : null,
        "estadoCivil": this.dataFormActualizarDatos.estadoCivil,
        "beneficiarios": this.dataFormSeguro,
        "idMiembroCampania": this.creditoPreaprobado.idMiembroCampania
      }
    }
    else {
      respuesta = {
        "tipoDocumento": extension_TipodeDocumento,
        "numeroDocumento": CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8),
        "monto": this.creditoPreaprobado.monto,
        "plazo": this.creditoPreaprobado.plazo,
        "correoElectronico": user.Correo,
        "numeroCelular": user.NumeroCelular,
        "producto": localStorage.getItem('codigoProductoSoliciteAqui'),
        "aceptoCliente": true,
        "tipoRespuesta": "Solicite Aquí su Crédito",
        "medioRespuesta": "Respuesta Web",
        "fechaRespuesta": moment().format('YYYY-MM-DD HH:mm:ss'),
        "tipoCuenta": this.dataFormDesembolso.tipoCuenta,
        "numeroCuenta": this.dataFormDesembolso.numeroCuenta,
        "banco": this.dataFormDesembolso.banco,
        "tipoVivienda": this.dataFormActualizarDatos.tipoVivienda.toUpperCase(),
        "telefonoArrendador": this.dataFormActualizarDatos.telefonoArrendador ? parseInt(this.dataFormActualizarDatos.telefonoArrendador) : null,
        "nombreArrendador": this.dataFormActualizarDatos.nombreArrendador ? this.dataFormActualizarDatos.nombreArrendador : null,
        "estadoCivil": this.dataFormActualizarDatos.estadoCivil,
        "beneficiarios": this.dataFormSeguro,
      }
    }

    this.loadingService.startLoading();
    this.ResumenCreditoService.sendResponseCredito(localStorage.getItem("tokenSalesforce"), localStorage.getItem("tokenB2C"), respuesta).subscribe(
      data => {
        if (data.encabezado.mensaje.estado === "OK") {
          this.messageService.visibleMessage({
            imgName: "success.svg",
            texto1: "Tú solicitud de crédito está en proceso.",
            texto2: "Pronto nos comunicaremos contigo para continuar con el desembolso.",
            button: "Finalizar",
          }, "Info");
          this.router.navigate(["/"], { queryParams: { success: 'cambiar-monto' } });
        }
        else {
          this.loadingService.stopLoading()
          this.messageService.errorMessage({ title: `Error enviando la solicitud`, texto1: `${data.encabezado.mensaje.descripcion}` })
          this.router.navigate([`/cambiar-monto-4`], { queryParams: { error: 'cambiar-monto' } });
        }
      },
      error => {
        this.loadingService.stopLoading()
        this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
      }
    )
  }

  confirmarRegresar() {
    this.router.navigate([`/cambiar-monto-3`]);
  }

  actualizarPerfil() {
    let user = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("userMail"), environment.constantKey).toString(CryptoJS.enc.Utf8));
    this.Modal.open(ModalActualizarPerfilComponent, {
      data: {
        correoElectronico: user.Correo,
        numeroCelular: user.NumeroCelular,
        type: 0
      },
      maxWidth: '100vw',
      maxHeight: '95vh'
    });
  }
}
