import { environment } from "../../../environments/environment";

export const B2C = {
    userFlowsLogin: environment.userFlowsLogin,
    userFlowRecoverPassword: environment.userFlowRecoverPassword,
    b2cScopes: environment.b2cScopes,
    webApi: environment.webApi,
    tokenApiManagment: environment.tokenApiManagment
}

// function tokenApiManagement() {
//     if (environment.ambiente === "prd") {
//         return (environment.tokenApiManagment)
//     }
//     return ('') //No modificar a QA
// }

export const graph = graphEndPoints();

function graphEndPoints() {
    if (environment.ambiente === "prd") {
        return ({
            userAttributes: environment.userAttributes,
            userStatus: environment.userStatus,
            listUsers: environment.listUsers,
            sendNotification: environment.sendNotification,
        })
    }
    return ({
        userAttributes: `https://${environment.urlBaseApiManagement}/api/graph/dev/v1.0.0/UserAttributes`, //No modificar a QA
        userStatus: `https://${environment.urlBaseApiManagement}/api/graph/dev/v1.0.0/UserStatus`, //No modificar a QA
        listUsers: `https://${environment.urlBaseApiManagement}/api/graph/dev/v1.0.0/Users`,
        sendNotification: `https://apimplataformadigitalbasic.azure-api.net/api/salesforce/qa/v1.0.0/services/apexrest/notification`,
    })
}

export const onCredit = {
    login: `https://${environment.urlBaseApiManagement}/coxti/${environment.ambiente}/v1.0.0/users/login`,
    enableValidate: `https://${environment.urlBaseApiManagement}/coxti/${environment.ambiente}/v1.0.0/enableValidate?token=`,
    identityValidation: environment.identityValidationCoxti,
    validationStatus: `https://${environment.urlBaseApiManagement}/coxti/${environment.ambiente}/v1.0.0/validationStatus`,
    urlBaseCreditos: `https://${environment.urlBaseApiManagement}/api/talend/${environment.ambiente}/v1.0.0/services/api/web/core/micrositio/generacion/certificados/`,
    urlDescargarPDFOracle: `https://${environment.urlBaseApiManagement}/api/talend/pru/v1.0.0/services/api/oracle/certificado/`
}

export const initialSalesforce = {
    OAuth2: `https://${environment.urlBaseApiManagement}/api/salesforce/${environment.ambiente}/v1.0.0/services/oauth2/token`,
    parametrosGenerales: `https://${environment.urlBaseApiManagement}/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/ParametrosGeneralesAutogestion`,
    creditosEstados: `https://${environment.urlBaseApiManagement}/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/CreditosYEstadosAutogestion`,
    tipoCliente: `https://${environment.urlBaseApiManagement}/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/TipoClienteAutogestion`,
    respuestaCliente: `https://${environment.urlBaseApiManagement}/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/RespuestaDelClienteAutogestion`,
    estadoCarteraCliente: `https://${environment.urlBaseApiManagement}/api/talend/${environment.ambiente}/v1.0.0/services/api/cobranza/consultaEstadoCarteraCliente/v1-0`,
    enviarNotificacion: `https://${environment.urlBaseApiManagement}/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/EnviaNotificaciones`,
    urlDesudas: `https://${environment.urlBaseApiManagement}/api/talend/dev/v1.0.0/services/api/web/core/micrositio/generacion/certificados/consultacreditos/v1-0`,
    actualizarMovilCorreo: `https://${environment.urlBaseApiManagement}/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest/ActualizaMovilCorreoCliente`,
    creditosVigentesSalesforce : `https://${environment.urlBaseApiManagement}/api/salesforce/${environment.ambiente}/v1.0.0/services/apexrest`
}


export const oracle = {
    simulador: environment.simulador
}

/* function oracleEndPoint() {
    if (environment.ambiente === "prd") {
        return ""
    }
    return `` //No modificar a QA
} */

export const SIIF = {
  tokenSIIF: `https://${environment.urlBaseApiManagement}/api/siif/auth/${environment.ambiente}/v3.0.0/WSJWTR_INT/Seguridad/Autenticacion`,
  estadoCarteraCredito: `https://${environment.urlBaseApiManagement}/api/siif/${environment.ambiente}/v1.0.0/WSGYG62_INT/ConsultaEstadoCarteraCredito`,
  consultarCampana: `https://${environment.urlBaseApiManagement}/api/siif/${environment.ambiente}/`,
  estadoCredito: `https://${environment.urlBaseApiManagement}/api/siif/${environment.ambiente}/v1.0.0/WSGYG64_INT/GeneracionReporte`,
  deudaPazYSalvo:`https://${environment.urlBaseApiManagement}/api/siif/${environment.ambiente}/v1.0.0/WSGYG71_INT/GenerarCertificado`

}

export const ORACLE = {
  pdfOracle: `https://${environment.urlBaseApiManagement}/api/talend/${environment.ambiente}/v1.0.0/services/api/oracle/certificado/`
}


