<div mat-dialog-title>
  <h6></h6>
  <button mat-dialog-close>X</button>
</div>
<div class="card">
  <img src="../../../assets/Images/info.svg" alt="">
  <h6>Si cancelaste un crédito recientemente, tu crédito puede aparecer vigente entre 24 y 120 horas. </h6>
</div>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close class="closeButton">Cerrar</button>
</mat-dialog-actions>
