<div class="nav" *ngIf="!isIframe && loggedIn">
  <!-- !isIframe && loggedIn && !disabledLogin -->
  <a *ngIf="!admin" routerLink="/">
    <img src="../assets/Images/INT_logo_RGB_positivo.svg" />
  </a>
  <a *ngIf="admin" routerLink="/admin">
    <img src="../assets/Images/INT_logo_RGB_positivo.svg" />
  </a>
  <button class="menuButton" mat-button [matMenuTriggerFor]="menu">
    <div class="iconos">
      <mat-icon class="user" svgIcon="icono_user"></mat-icon>
      <mat-icon class="downArrow">keyboard_arrow_down</mat-icon>
    </div>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <a href="https://interactuar.org.co/" target="_blank" class="link"
        >Interactuar.com</a
      >
    </button>
    <button *ngIf="loggedIn" (click)="logout()" mat-menu-item>
      Cerrar Sesión
    </button>
  </mat-menu>
</div>

<div>
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <app-unauth-home *ngIf="!loggedIn"></app-unauth-home
  ><!-- *ngIf="!loggedIn && !disabledLogin" -->
  <router-outlet
    class="router"
    *ngIf="!isIframe && loggedIn && passHome"
  ></router-outlet>
  <!-- //!isIframe && loggedIn && !disabledLogin && passHome" -->
  <!-- <ng-container [ngTemplateOutlet]="disabled" *ngIf="loggedIn && disabledLogin"></ng-container> -->
  <app-loading-info *ngIf="loadingInfo && loggedIn"></app-loading-info>
  <app-messages></app-messages>
</div>

<!-- <ng-template #disabled>
  <div class="disabledContainer">
    <img src="https://interactuar.org.co/wp-content/uploads/2020/04/logo-interactuar.svg" />
  </div>
  <div class="shadowBackground">
    <div class="modalError">
      <img src="../assets/Images/error.svg">
      <h2>
        Identificamos que eres una persona jurídica, por esa razón, uno de nuestros asesores se comunicará contigo para
        brindarte el acompañamiento que necesitas.
      </h2>
      <button (click)="acceptDisabled()"> Aceptar </button>
    </div>
  </div>
</ng-template> -->
