import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-pago-pse',
  templateUrl: './modal-pago-pse.component.html',
  styleUrls: ['./modal-pago-pse.component.css']
})
export class ModalPagoPseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
