import { HostListener, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import * as CryptoJS from 'crypto-js';
import { AppService } from '../../app.service';
import { HomeService } from '../home/home.service';
import { MessagesService } from '../../reusableComponents/messages/messages.service';
import { CambiarMontoService } from './cambiar-monto.service';
import { LoadingInfoService } from '../../reusableComponents/loading-info/loading-info.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cambiar-monto',
  templateUrl: './cambiar-monto.component.html',
  styleUrls: ['./cambiar-monto.component.css'],
})
export class CambiarMontoComponent implements OnInit {
  visibleSimulator: boolean = false;
  solicitudCredito: boolean = false;
  cargandoSimulador: boolean = false;
  tipoDocumento: string = '';
  numeroDocumento: string = '';
  data: {
    codigoProducto: string;
    estado: string;
    codigoProductoInicial?: string;
    fechaDesembolso: null | string;
    monto: number;
    numeroCredito: null | number;
    plazo: number;
    simulador: number | null;
    idMiembroCampania: string | null;
    Cargos: number | null;
    Total: number | null;
    fuente: string;
    montoInicial?: number;
    plazoInicial?: number;
    tipoRespuesta?: string;
  };
  formMontoSolicitar: FormGroup;
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  monto: number;
  errorSubmit: boolean = false;
  plazo: number;
  tasaInteres = 0;
  dataCuota: { intereses: number; cuota: number };
  montoInicial = 0;
  plazoInicial = 0;
  codigoProductoInicial = '';
  solicitaAqui: boolean;

  constructor(
    private router: Router,
    private authService: MsalService,
    private homeService: HomeService,
    private messageService: MessagesService,
    private cambiarMonto: CambiarMontoService,
    private loadingService: LoadingInfoService,
    private appService: AppService
  ) {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(
      (value) => (this.loadingInfo = value)
    );
    this.messageSubscription = this.messageService.messageStatus.subscribe(
      (value) => (this.messageVisble = value)
    );
    this.formMontoSolicitar = new FormGroup({
      monto: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern('^[0-9]*$'),
        this.validateMonto,
      ]),
      plazo: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern('^[0-9]*$'),
        this.validatePlazo,
      ]),
    });
  }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(
      (value) => (this.loadingInfo = value)
    );
    let consultaPreaprobado = this.homeService.fetchCreditoPreaprobado();
    const {
      idToken: { given_name, extension_TipodeDocumento },
    } = this.authService.getAccount();
    [this.numeroDocumento, this.tipoDocumento] = [
      CryptoJS.AES.decrypt(
        localStorage.getItem('user'),
        environment.constantKey
      ).toString(CryptoJS.enc.Utf8),
      extension_TipodeDocumento,
    ];
    if (consultaPreaprobado === undefined) {
      //Si la consulta trae un indefinido quiere decir que se recargó la página y por ende no se tiene el crédito
      this.router.navigate(['/']);
      // this.data = { ...JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("object"), environment.constantKey).toString(CryptoJS.enc.Utf8)), simulador: null, Cargos: null, Total: null };
    } else {
      this.data = {
        ...consultaPreaprobado,
        simulador: null,
        Cargos: null,
        Total: null,
      };
    }

    this.codigoProductoInicial = consultaPreaprobado.codigoProducto;
    this.montoInicial = consultaPreaprobado.montoInicial;
    this.plazoInicial = consultaPreaprobado.plazoInicial;
    this.solicitaAqui = consultaPreaprobado.solicitaAqui;
    this.loadingService.stopLoading();
    this.messageService.visibleMessage(
      {
        imgName: 'warning.svg',
        title: 'Mensaje informativo simulador de crédito',
        texto1: '',
        msnSimulador: true,
        enlace: 'https://interactuar.org.co/oficinas/',
        button: 'Cerrar',
      },
      'Info'
    );
  }

  validateMonto(control: AbstractControl) {
    if (
      parseInt(control.value) >= 800000 &&
      parseInt(control.value) <= 6000000
    ) {
      return null;
    }
    return { invalidUrl: true };
  }

  validatePlazo(control: AbstractControl) {
    if (parseInt(control.value) >= 4 && parseInt(control.value) <= 36) {
      return null;
    }
    return { invalidUrl: true };
  }

  @HostListener('window:focus', ['$event'])
  tabActivation(event) {
    let getAccount = this.authService.getAccount();
    let sub = getAccount.idTokenClaims.sub;
    this.appService.getUserAttributeSesion(sub).subscribe(
      (data) => {
        let sesionAtrributes = data.atrributes[0].Sesion;
        let sesion = sessionStorage.getItem('Sesion');
        if (getAccount == null || sesion != sesionAtrributes) {
          this.ErrorCerrarSesionPorDobleSesion();
        }
      },
      (error) => {}
    );
  }

  ErrorCerrarSesionPorDobleSesion() {
    this.messageService.visibleMessage(
      {
        imgName: 'error.svg',
        texto1: `Sesión invalida`,
        texto2:
          'Al parecer ya tienes una sesión abierta, cierra la sesión e inicia de nuevo para continuar.',
        button: 'Cerrar sesión',
      },
      'CerrarSesion'
    );
  }

  onSubmit() {
    if (this.formMontoSolicitar.valid) {
      this.loadingService.startLoading();
      this.monto = parseInt(this.formMontoSolicitar.value.monto);
      this.plazo = parseInt(this.formMontoSolicitar.value.plazo);
      this.visibleSimulator = true;
      this.data.simulador = null; //Cuota mensual aproximada - Se ponen en null para que aparezca cargando el valor

      this.homeService.getToken().subscribe((data) => {
        this.homeService
          .consultaTasaCampaña(data.request.token, '', this.solicitaAqui)
          .subscribe(
            (data) => {
              if (data.response.encabezado.mensaje.estado.trim() == 'ERROR') {
                // this.messageService.errorMessage({ title: `Error: ${data.response.encabezado.mensaje.descripcion + ': '+ environment.idCampanaSinPreaprobados}`, texto1: '' })
                //localStorage.getItem('IdCampanaSoliciteAqui')
                var msn = `Error: ${
                  data.response.encabezado.mensaje.descripcion +
                  ': ' +
                  localStorage.getItem('IdCampanaSoliciteAqui')
                }`;
                this.loadingService.stopLoading();
                this.messageService.visibleMessage(
                  {
                    title: msn,
                    imgName: 'error.svg',
                    texto1: ``,
                    msnCreditosSelect: true,
                    button: 'Cerrar',
                  },
                  'Info'
                );
              } else {
                this.data.Cargos = null; //Cargos fijos
                this.data.Total = null; //Cuota total aproximada
                let tasa = data.response.consultaTasaCampanaSiif.campana.tasa;
                if (localStorage.getItem('tasaPreAprobado')) {
                  tasa = localStorage.getItem('tasaPreAprobado');
                }

                this.cargandoSimulador = true;
                this.data.simulador = this.calcularCuota(
                  this.monto,
                  this.plazo,
                  tasa
                );
                this.data.monto = this.monto;
                this.data.plazo = this.plazo;

                if (this.monto > this.montoInicial)
                  this.data.tipoRespuesta = 'Monto Mayor/Cambio de Plazo';
                else if (this.monto < this.montoInicial) {
                  this.data.tipoRespuesta = 'Monto Menor/Cambio de Plazo';
                  if (this.data.plazo === this.plazoInicial)
                    this.data.codigoProducto = this.codigoProductoInicial;
                } else {
                  if (this.data.plazo > this.plazoInicial)
                    this.data.tipoRespuesta = 'Monto Mayor/Cambio de Plazo';
                  else if (this.data.plazo < this.plazoInicial)
                    this.data.tipoRespuesta = 'Monto Menor/Cambio de Plazo';
                  else {
                    this.data.tipoRespuesta = 'Condiciones Iguales';
                    this.data.codigoProducto = this.codigoProductoInicial;
                  }
                }
              }
              this.loadingService.stopLoading();
            },
            (error) => {
              this.loadingService.stopLoading();
              this.messageService.visibleMessage(
                {
                  imgName: 'error.svg',
                  texto1: ``,
                  msnServiceError: true,
                  //msnCreditosSelect: true,
                  button: 'Cerrar',
                },
                'Info'
              );
            }
          );
      });

      this.cargandoSimulador = false;
      if (this.solicitudCredito) {
        this.solicitarCredito();
      }

      this.errorSubmit = false;
      this.formMontoSolicitar.reset(); //Para que haya concordancia entre lo que se muestra en el simulador y lo que se ingresa. De lo contrario el usuario podría ingresar un monto y presionar solicitar crédito.
    } else {
      this.errorSubmit = true;
    }
  }

  calcularCargos(valor: number, plazo: number, tasa: any) {
    let tasaInteres = parseFloat(tasa.replace(',', '.'));
    let intereses = Math.floor(valor * tasaInteres) / 100;
    let cargo = intereses * plazo;
    return cargo;
  }

  calcularCuota(valor: number, plazo: number, tasa: any) {
    let tasaInteres = parseFloat(tasa.replace(',', '.'));
    tasaInteres = tasaInteres / 100;
    let TasaMV = Math.pow(1 + tasaInteres, 1 / 12) - 1;
    let numerador = TasaMV * Math.pow(1 + TasaMV, plazo);
    let denominador = Math.pow(1 + TasaMV, plazo) - 1;
    let cuotafinal = this.monto * (numerador / denominador);
    return Math.round(cuotafinal);
  }

  solicitarCredito() {
    this.solicitudCredito = true;
    this.loadingService.startLoading();
    //if (!this.cargandoSimulador) {
    this.loadingService.stopLoading();
    this.homeService.saveCreditoPreaprobado(this.data);
    this.router.navigate(['/cambiar-monto-1']);
    // }
  }

  regresar() {
    this.router.navigate(['/']);
  }

  get montoInput() {
    return this.formMontoSolicitar.get('monto');
  }
  get cuotasSelector() {
    return this.formMontoSolicitar.get('plazo');
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
  }
}
