<div class="card">
  <img src="../../../assets/Images/info.svg">
  <div style="text-align: center;">
    <p style="font-weight: bold;"> Seleccione una opción: </p>
  </div>


  <div class="row">
    <div class="col-2">

    </div>
    <div class="col">
      <div class="form-check" >
        <input class="form-check-input"  type="checkbox" value="" id="flexCheckCheckedDisabled" checked
          disabled>
        <p style="color: black;">Reenviar código</p>
      </div>
    </div>
  </div><br>

  <div class="row">
    <div class="col-6 contenedor">
      <button style="width: 90%;" (click)="cerrar()" class="btn btn-warning">Cerrar</button>
    </div>
    <div class="col-6 contenedor">
      <button style="width: 90%;" (click)="reenviar()" class="btn btn-primary">Reenviar</button>
    </div>
  </div><br>
  <div class="row">
    <h6>
      * Si tienes inconvenientes contáctanos al WhatsApp <a
        href="https://api.whatsapp.com/send?phone=573206064004&text=Bienvenido%20a%20Interactuar" target="_blank">320
        606 4004</a> o llámanos al <strong style="color: #007bff;">018000417019</strong>
    </h6>
  </div>
</div>
