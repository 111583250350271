import { HostListener, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { Logger, CryptoUtils } from 'msal';

import { b2cPolicies } from './constants/api/app-config';
import { LoadingInfoService } from './reusableComponents/loading-info/loading-info.service';
import { AppService } from './app.service';
import { MessagesService } from './reusableComponents/messages/messages.service';
import { AdminService } from '../app/components/admin/admin.service';
import { environment } from '../environments/environment';
import { userAttributes } from './constants/interfaces/B2CInterfaces';
import { ModalActualizarPerfilService } from './components/home/modal-actualizar-perfil/modal-actualizar-perfil.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  subscriptions: Subscription[] = [];
  title = 'Interactuar';

  isIframe = false;
  admin: boolean = false;
  loggedIn = false; //Bandera de login
  //disabledLogin: boolean = false; //Bandera para indicar que se le muestre al usuario el html de deshabilitado
  tipoCliente: boolean = false; //Bandera para la validación de tipo cliente
  passHome: boolean = false; //Bandera para permitir que aparezca el home cuando ya se hayan completado las consultas
  changePassword: boolean = false;
  loadingInfo: boolean = false; //Bandera del cargando
  loadingSubscription: Subscription;
  messageVisble: boolean = false; //Bandera para la visualización de mensajes
  messageSubscription: Subscription;
  loginIsNew: boolean = false;
  nombreCliente: string = '';

  constructor(
    private router: Router,
    private adminService: AdminService,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private loadingService: LoadingInfoService,
    private appService: AppService,
    private messageService: MessagesService,
    private ModalActualizarPerfilService: ModalActualizarPerfilService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon(
      'icono_user',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '../assets/Images/Icono_Persona.svg'
      )
    );
  }

  ngOnInit() {
    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(
      (value) => (this.loadingInfo = value)
    );
    this.messageSubscription = this.messageService.messageStatus.subscribe(
      (value) => (this.messageVisble = value)
    );

    this.isIframe = window !== window.parent && !window.opener;
    this.checkAccount();
    //this.loadingService.stopLoading() //PARA DESARROLLO, EN PROD COMENTAR

    // event listeners for authentication status
    loginSuccessSubscription = this.broadcastService.subscribe(
      'msal:loginSuccess',
      (success) => {
        let tokenSesion = CryptoUtils.createNewGuid();
        sessionStorage.setItem('Sesion', tokenSesion);
        let id = success.idTokenClaims.sub;
        this.loginIsNew = true;
        this.appService
          .updateUserAttributeSesion(id, tokenSesion)
          .toPromise()
          .then(
            () => { },
            (error) =>
              this.messageService.errorMessage({
                title: `Error ${error.origin}`,
                texto1:
                  'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
              })
          );

        // We need to reject id tokens that were not issued with the default sign-in policy. "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr"). To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
        if (success.idToken.claims['acr'] === b2cPolicies.names.resetPassword) {
          window.alert(
            'Password has been reset successfully. \nPlease sign-in with your new password'
          );
          return this.authService.logout();
        }
      }
    );

    loginFailureSubscription = this.broadcastService.subscribe(
      'msal:loginFailure',
      (error) => {
        // Check for forgot password error. Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (error.errorMessage.indexOf('AADB2C90118') > -1) {
          this.changePassword = true;
          this.authService.loginRedirect(b2cPolicies.authorities.resetPassword);
        }
      }
    );

    // redirect callback for redirect flow (IE)
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
    });

    this.authService.setLogger(
      new Logger((logLevel, message, piiEnabled) => { }, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false,
      })
    );

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    if (!this.loggedIn && !this.changePassword) {
      this.login(); //Se redirecciona al login
    }
  }

  login() {
    this.authService.loginRedirect(); //El authService.loginRedirect() hace que se redirecciones la página
  }

  logout() {
    localStorage.removeItem('tokenSalesforce');
    localStorage.removeItem('tokenB2C');
    localStorage.removeItem('user');
    localStorage.removeItem('object');
    localStorage.removeItem('sendNoti');
    localStorage.removeItem('tasaPreAprobado');
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  @HostListener('window:focus', ['$event'])
  tabActivation(event) {
    let getAccount = this.authService.getAccount();
    let sub = getAccount.idTokenClaims.sub;
    this.appService.getUserAttributeSesion(sub).subscribe(
      (data) => {
        let sesionAtrributes = data.atrributes[0].Sesion;
        localStorage.setItem(
          'user',
          CryptoJS.AES.encrypt(
            data.userSignIn,
            environment.constantKey
          ).toString()
        );
        let sesion = sessionStorage.getItem('Sesion');
        if (getAccount == null || sesion != sesionAtrributes) {
          this.ErrorCerrarSesionPorDobleSesion();
        }
      },
      (error) => { }
    );
  }

  /*   @HostListener('click', ['$event.target']) onClick(e) {
    this.nombreCliente = CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8);
console.log(this.nombreCliente);

    let getAccount = this.authService.getAccount();
    let sub = getAccount.idTokenClaims.sub
    console.log(getAccount);
    console.log(sub);

    this.appService.getUserAttributeSesion(sub).subscribe(
      data => {
        let sesionAtrributes = (data.atrributes[0].Sesion);
        let sesion = sessionStorage.getItem('Sesion');
        if ((getAccount == null || sesion != sesionAtrributes)) {
          this.ErrorCerrarSesionPorDobleSesion()
        }
      },
      error => { }
    )

  } */

  // other methods
  checkAccount() {
    this.loadingService.startLoading();
    let getAccount = this.authService.getAccount();
    this.loggedIn = !!getAccount; //Si el usuario está logeado, el servicio getAccount() retornará un objeto. Por lo cual la bandera loggedIn estará en verdadero.
    var urlParams = new URLSearchParams(window.location.search);
    if (this.loggedIn) {
      let Dispositivo = 'PC';
      if (window.innerWidth <= 700) {
        Dispositivo = 'Móvil';
      }

      if (getAccount?.idToken?.newUser) {
        this.adminService
          .updateAtrributesUsuarioNew(
            getAccount.accountIdentifier,
            getAccount.idToken.emails[0],
            Dispositivo
          )
          .subscribe(
            (data) => {
              null;
            },
            () => {
              this.appService.updateTokens().subscribe(
                () => {
                  this.adminService
                    .updateAtrributesUsuarioNew(
                      getAccount.accountIdentifier,
                      getAccount.idToken.emails[0],
                      Dispositivo
                    )
                    .subscribe((data) => {
                      null;
                    });
                },
                (error) => this.ErrorReintentarCerrarSesión(error)
              );
            }
          );
      }
      if (
        getAccount.idTokenClaims.jobTitle === 'admin' ||
        getAccount.idTokenClaims.jobTitle === 'superadmin'
      ) {
        this.admin = true;
        if (window.location.href.indexOf('?token=') > 10) {
          let token = urlParams.get('token');
          this.adminService.responseForm(token).subscribe(
            (data) => {
              switch (data.status) {
                case 1:
                  this.adminService
                    .updateAtrributes(
                      localStorage.getItem('id'),
                      localStorage.getItem('tokenB2C'),
                      true,
                      'telefonica',
                      2
                    )
                    .subscribe(
                      () => {
                        localStorage.removeItem('id');
                        this.messageService.visibleMessage(
                          {
                            imgName: 'success.svg',
                            texto1: '¡Identidad validada!',
                            texto2: 'Usuario desbloqueado correctamente.',
                            button: 'Cerrar',
                          },
                          'Info'
                        );
                        this.passHome = true;
                        this.router.navigate(['/admin']);
                      },
                      (error) =>
                        this.messageService.errorMessage({
                          title: `Error ${error.origin}`,
                          texto1:
                            'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
                        })
                    );
                  break;
                case 0:
                  this.adminService
                    .updateAtrributes(
                      localStorage.getItem('id'),
                      localStorage.getItem('tokenB2C'),
                      false,
                      'telefonica',
                      2
                    )
                    .subscribe(
                      () => {
                        localStorage.removeItem('id');
                        this.messageService.visibleMessage(
                          {
                            imgName: 'error.svg',
                            texto1: '¡Identidad no validada!',
                            texto2:
                              'El usuario debe de asistir de forma presencial para ser desbloqueado.',
                            button: 'Cerrar',
                          },
                          'Info'
                        );
                        this.passHome = true;
                        this.router.navigate(['/admin']);
                      },
                      (error) =>
                        this.messageService.errorMessage({
                          title: `Error ${error.origin}`,
                          texto1:
                            'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
                        })
                    );
                  break;
                default:
                  localStorage.removeItem('id');
                  this.router.navigate(['/admin']);
                  break;
              }
            },
            (error) => {
              localStorage.removeItem('id');
              this.passHome = true;
              this.router.navigate(['/admin']);
              this.messageService.errorMessage({
                title: `Error ${error.origin}`,
                texto1:
                  'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
              });
            }
          );
        } else {
          this.passHome = true;
          this.router.navigate(['/admin']);
        }
      } else {
        /* if (getAccount.idTokenClaims.extension_TipodeDocumento === "Nit") {
          {
            this.messageService.visibleMessage({
              imgName: "error.svg",
              texto1: "Identificamos que eres una persona jurídica, por esa razón, compártenos tus datos en el siguiente enlace y pronto uno de nuestros asesores se pondrá en contacto contigo para atender tu solicitud.",
              texto2: null,
              texto3: null,
              enlace: "https://interactuar.org.co/quiero-credito/",
              button: "Aceptar"
            }, "CerrarSesion")
          }
        }
        else*/ if (
          getAccount.idTokenClaims.extension_TipodeDocumento ===
          'Tarjeta de Identidad'
        ) {
          {
            this.messageService.visibleMessage(
              {
                imgName: 'error.svg',
                texto1:
                  'Identificamos que eres un menor de edad, por esa razón, compártenos tus datos en el siguiente enlace y pronto uno de nuestros asesores se pondrá en contacto contigo para atender tu solicitud.',
                texto2: null,
                texto3: null,
                enlace: 'https://interactuar.org.co/quiero-credito/',
                button: 'Aceptar',
              },
              'CerrarSesion'
            );
          }
        } else {
          //if (localStorage.getItem("user") === null || localStorage.getItem("userMail") === null) {
          this.appService
            .getUserAttributes(getAccount.idTokenClaims.sub)
            .subscribe(
              (data) => {
                this.createProfileUser(data, getAccount);
                this.validacionTipoCliente(
                  getAccount.idTokenClaims.sub,
                  getAccount.idTokenClaims.extension_TipodeDocumento,
                  data.userSignIn,
                  data.atrributes.length === 0
                    ? null
                    : data.atrributes[0].IdentidadValida
                      ? data.atrributes[0].IdentidadValida
                      : null,
                  getAccount
                );
              },
              () => {
                this.appService.updateTokens().subscribe(
                  () => {
                    this.appService
                      .getUserAttributes(getAccount.idTokenClaims.sub)
                      .subscribe(
                        (data) => {
                          this.createProfileUser(data, getAccount);
                          this.validacionTipoCliente(
                            getAccount.idTokenClaims.sub,
                            getAccount.idTokenClaims.extension_TipodeDocumento,
                            data.userSignIn,
                            data.atrributes.length === 0
                              ? null
                              : data.atrributes[0].IdentidadValida
                                ? data.atrributes[0].IdentidadValida
                                : null,
                            getAccount
                          );
                        },
                        (error) => this.ErrorReintentarCerrarSesión(error)
                      );
                  },
                  (error) => this.ErrorReintentarCerrarSesión(error)
                );
              }
            );
          //}
          /*else {
            this.validacionTipoCliente(getAccount.idTokenClaims.sub, getAccount.idTokenClaims.extension_TipodeDocumento, CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8), null, getAccount)
          }*/
        }
      }
    }
  }

  createProfileUser(data: userAttributes, getAccount: any) {
    let user = { Correo: '', NumeroCelular: '' };
    localStorage.setItem(
      'user',
      CryptoJS.AES.encrypt(data.userSignIn, environment.constantKey).toString()
    );
    if (typeof data.atrributes[0]?.Correo === 'undefined') {
      user.Correo = getAccount.idToken.emails[0];
      user.NumeroCelular = getAccount.idToken.extension_NumeroCelular;
    } else {
      user.Correo = data.atrributes[0].Correo;
      user.NumeroCelular = data.atrributes[0].NumeroCelular;
    }
    localStorage.setItem(
      'userMail',
      CryptoJS.AES.encrypt(
        JSON.stringify(user),
        environment.constantKey
      ).toString()
    );
  }

  async validacionTipoCliente(
    sub: string,
    TipodeDocumento: string,
    Numerodedocumento: string,
    identidadValida?: boolean,
    getAccount?: any
  ) {
    this.appService.getUserAttributeSesion(sub).subscribe(
      (data) => {
        let sesionAtrributes = data.atrributes[0].Sesion;
        let sesion = sessionStorage.getItem('Sesion');
        //Se valida la pestaña original donde se inicio sesión.
        if (
          (getAccount == null || sesion != sesionAtrributes) &&
          !this.loginIsNew
        ) {
          this.ErrorCerrarSesionPorDobleSesion();
        } else {
          //Almacena la fecha de ultimo ingreso
          this.appService.updateFechaUltimoAccesoAttribute().subscribe(null);

          //Validación tipo cliente, se consultan los tokens y se realiza la petición a la API de tipoCliente
          this.appService
            .tipoClienteSinTokens(Numerodedocumento, TipodeDocumento)
            .subscribe(
              (data) => {
                this.sendAdmissionNotification(TipodeDocumento, sub);

                //Si el parámetro clienteVigenteCredito es true se continua con la validación a onCredit, de lo contrario no
                if (data.tipoClienteAutogestion.clienteVigenteCredito) {
                  if (
                    TipodeDocumento === 'Nit' &&
                    getAccount?.idToken?.newUser
                  ) {
                    this.appService
                      .getUserAttributes(sub)
                      .toPromise()
                      .then(
                        (data) => {
                          this.appService
                            .updateUserAttributes(
                              false,
                              sub,
                              1,
                              'virtual',
                              getAccount.idTokenClaims.emails[0]
                            )
                            .toPromise()
                            .then(() => {
                              this.appService.blockUser(sub, false).subscribe();
                              this.messageService.visibleMessage(
                                {
                                  imgName: 'error.svg',
                                  texto1: ``,
                                  msnJuridicial: true,
                                  button: 'Aceptar',
                                },
                                'CerrarSesion'
                              );
                            });
                        },
                        (error) =>
                          this.messageService.errorMessage({
                            title: `Error ${error.origin}`,
                            texto1:
                              'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
                          })
                      );
                  } else {
                    this.checkResponseOnCredit(sub);
                  }
                } else {
                  if (identidadValida === undefined)
                    //Si el parámetro identidadValidad es undefined quiere decir que se debe de consultar el parámetro
                    this.appService.getUserAttributes(sub).subscribe(
                      (data) =>
                        this.eliminarCuenta(
                          sub,
                          data.atrributes[0].IdentidadValida ? undefined : null,
                          Numerodedocumento,
                          TipodeDocumento
                        ),
                      (error) => this.ErrorReintentarCerrarSesión(error)
                    );
                  else
                    this.eliminarCuenta(
                      sub,
                      identidadValida,
                      Numerodedocumento,
                      TipodeDocumento
                    );
                }
              },
              //En caso de error, se hace una nueva consulta, esta vez se hace la consulta de 0, consultando los tokens de B2C y salesforce
              () => {
                this.appService
                  .tipoCliente(Numerodedocumento, TipodeDocumento)
                  .subscribe(
                    (data) => {
                      this.sendAdmissionNotification(TipodeDocumento, sub);

                      //Si el parámetro clienteVigenteCredito es true se continua con la validación a onCredit, de lo contrario no
                      if (data.tipoClienteAutogestion.clienteVigenteCredito) {
                        if (
                          TipodeDocumento === 'Nit' &&
                          getAccount?.idToken?.newUser
                        ) {
                          this.appService
                            .getUserAttributes(sub)
                            .toPromise()
                            .then(
                              (data) => {
                                this.appService
                                  .updateUserAttributes(
                                    false,
                                    sub,
                                    1,
                                    'virtual',
                                    getAccount.idTokenClaims.emails[0]
                                  )
                                  .toPromise()
                                  .then(() => {
                                    this.appService
                                      .blockUser(sub, false)
                                      .subscribe();
                                    this.messageService.visibleMessage(
                                      {
                                        imgName: 'error.svg',
                                        texto1: ``,
                                        msnJuridicial: true,
                                        button: 'Aceptar',
                                      },
                                      'CerrarSesion'
                                    );
                                  });
                              },
                              (error) =>
                                this.messageService.errorMessage({
                                  title: `Error ${error.origin}`,
                                  texto1:
                                    'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
                                })
                            );
                        } else {
                          this.checkResponseOnCredit(sub);
                        }
                      } else {
                        if (identidadValida === undefined)
                          //Si el parámetro identidadValidad es undefined quiere decir que se debe de consultar el parámetro
                          this.appService.getUserAttributes(sub).subscribe(
                            (data) =>
                              this.eliminarCuenta(
                                sub,
                                data.atrributes[0].IdentidadValida
                                  ? undefined
                                  : null,
                                Numerodedocumento,
                                TipodeDocumento
                              ),
                            (error) => this.ErrorReintentarCerrarSesión(error)
                          );
                        else
                          this.eliminarCuenta(
                            sub,
                            identidadValida,
                            Numerodedocumento,
                            TipodeDocumento
                          );
                      }
                    },
                    (error) => this.ErrorReintentarCerrarSesión(error)
                  );
              }
            );
        }
      },
      (error) => { }
    );
  }

  eliminarCuenta(
    id: string,
    identidadValida: boolean,
    Numerodedocumento: string,
    TipodeDocumento: string
  ) {
    var tipoDoc;
    switch (TipodeDocumento) {
      case 'Cedula de Ciudadania':
        tipoDoc = 'C.C';
        break;
      case 'Cedula de Extranjeria':
        tipoDoc = 'C.E';
        break;
      case 'Tarjeta de Identidad':
        tipoDoc = 'T.I';
        break;
      default:
        tipoDoc = TipodeDocumento;
        break;
    }
    this.appService.updateNoUserAttributes(id).subscribe((data) => {
      this.appService.blockUser(id, true).subscribe();

      this.messageService.visibleMessage(
        {
          imgName: 'error.svg',
          texto1: `Los datos que ingresaste para el registro son: ${tipoDoc}, ${Numerodedocumento} si los datos NO son correctos ingresa nuevamente, SI son correctos al parecer aún no eres un cliente Interactuar o hace un tiempo no estás activo.`,
          texto2:
            'Compártenos tus datos en el siguiente enlace y pronto, uno de nuestros asesores, se pondrá en contacto contigo para atender tu solicitud.',
          enlace: 'https://interactuar.org.co/quiero-credito/',
          button: 'Aceptar',
        },
        'CerrarSesion'
      );
    });
  }

  getParams(id) {
    this.appService.getUserAttributes(id).subscribe(
      (data) => {
        if (!data) {
          this.validateIdentity(id);
        } else {
          if (data.atrributes[0] === undefined) {
            this.validateIdentity(id);
          } else {
            const {
              IdentidadValida,
              extension_ee056c143923412f9188ccd9cd06a04b_Numerodeintentos,
            } = data.atrributes[0];
            //Condicional para validar si el usuario tiene el párametro IdentidadValidada de Azure
            if (IdentidadValida) {
              this.passHome = true;
            }
            //Si el usuario no tiene la identidad validad en Azure
            else {
              this.passHome = false; //VALOR ORIGINAL FALSE. CON TRUE SE OMITE EL VALIDAR IDENTIDAD
              //Condicional para validar si es la primera vez que el usuario validará la identidad
              /* if (typeof extension_ee056c143923412f9188ccd9cd06a04b_Numerodeintentos !== "undefined") {
                //Si no el la primera vez que el usuario valida su identidad el numero de intentos no será undefined
                this.appService.blockUser(id, false).subscribe(
                  () => {
                    this.messageService.visibleMessage({
                      imgName: "error.svg",
                      texto1: "La validación de tus datos no fue exitosa.",
                      texto2: "Hemos bloqueado tu usuario para garantizar la seguridad de tus datos. Comunícate con nosotros para desbloquearlo, llamando al",
                      texto3: "01 8000 417 019 o ",
                      whatsapp: true,
                      button: "Cerrar"
                    }, "bloqueo")
                  },
                  () => {
                    this.appService.updateTokens().subscribe(
                      () => {
                        this.appService.blockUser(id, false).subscribe(
                          () => {
                            this.messageService.visibleMessage({
                              imgName: "error.svg",
                              texto1: "La validación de tus datos no fue exitosa.",
                              texto2: "Hemos bloqueado tu usuario para garantizar la seguridad de tus datos. Comunícate con nosotros para desbloquearlo, llamando al",
                              texto3: "01 8000 417 019 o ",
                              whatsapp: true,
                              button: "Cerrar"
                            }, "bloqueo")
                          },
                          error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
                        )
                      },
                      error => this.ErrorReintentarCerrarSesión(error)
                    )
                  }
                )
              }
              //Mensaje en caso tal de que sea la primera vez que el usuario valida su identidad
              else { */

              this.validateIdentity(id);

              //}
            }
          }
        }
      },
      (error) => this.ErrorReintentarCerrarSesión(error)
    );
  }

  ErrorReintentarCerrarSesión(error) {
    this.messageService.visibleMessage(
      {
        imgName: 'error.svg',
        texto1: `Error ${error.origin}`,
        texto2:
          'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
        button: 'Reintentar',
        button2: 'Cerrar sesión',
      },
      'recargarPagina',
      'Close2'
    );
  }

  ErrorCerrarSesionPorDobleSesion() {
    this.messageService.visibleMessage(
      {
        imgName: 'error.svg',
        texto1: `Sesión invalida`,
        texto2:
          'Al parecer ya tienes una sesión abierta, cierra la sesión e inicia de nuevo para continuar.',
        button: 'Cerrar sesión',
      },
      'CerrarSesion'
    );
  }

  acceptDisabled() {
    this.authService.logout();
  }

  checkResponseOnCredit(id) {
    var urlParams = new URLSearchParams(window.location.search);
    if (
      window.location.href.indexOf('?token=') > 10 &&
      window.location.href.indexOf('admin') === -1
    ) {
      let getAccount = this.authService.getAccount();
      let token = urlParams.get('token');
      let stepValidation = urlParams.get('stepValidation');
      // se comenta la consulta de estado de validación a oncredit y se pone un 1 para que siempre se valide
      // this.appService.responseForm(token).subscribe(
      //   data => {
      //     switch (data.status) {
      var data = 1;
      switch (data) {
        case 1:
          //if (stepValidation == 'true') {
          this.appService
            .getUserAttributes(id)
            .toPromise()
            .then(
              (data) => {
                this.appService
                  .updateUserAttributes(
                    true,
                    id,
                    1,
                    'virtual',
                    getAccount.idTokenClaims.emails[0]
                  )
                  .toPromise()
                  .then(
                    () => {
                      this.getParams(id);
                      this.appService.blockUser(id, true).subscribe();
                      this.messageService.visibleMessage(
                        {
                          imgName: 'success.svg',
                          texto1: '¡Bienvenido a Interactuar!',
                          texto2:
                            'La validación de su identidad ha sido exitosa.',
                          button: 'Continuar',
                        },
                        'Info'
                      );
                    },
                    (error) =>
                      this.messageService.errorMessage({
                        title: `Error ${error.origin}`,
                        texto1:
                          'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
                      })
                  );
              },
              (error) =>
                this.messageService.errorMessage({
                  title: `Error ${error.origin}`,
                  texto1:
                    'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente',
                })
            );
          // } else {
          //   this.appService.getUserAttributes(id).toPromise().then(
          //     data => {
          //       var datos = data.atrributes[0].ActualizarDatos.split("_");
          //       this.appService.updateUserAttributes(true, id, 1, "virtual", getAccount.idTokenClaims.emails[0]).toPromise().then(
          //         () => {
          //           this.getParams(id);
          //           this.updatePersonalData(datos[1], datos[0]);
          //         },
          //         error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
          //       )
          //     },
          //     error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
          //   );
          // }
          break;
        case 0:
          if (stepValidation == 'false') {
            this.appService.blockUser(id, false).subscribe(() => {
              this.messageService.visibleMessage(
                {
                  imgName: 'error.svg',
                  texto1: 'La actualización de tus datos no fue exitosa.',
                  texto2:
                    'Hemos bloqueado tu usuario para garantizar la seguridad de tus datos. Comunícate con nosotros para desbloquearlo, llamando al',
                  texto3: '01 8000 417 019 o ',
                  whatsapp: true,
                  button: 'Cerrar',
                },
                'bloqueo'
              );
            });
            setTimeout(function () {
              this.logout();
            }, 3000);
          } else {
            this.appService
              .getUserAttributes(id)
              .toPromise()
              .then(
                (data) => {
                  this.appService
                    .updateUserAttributes(
                      false,
                      id,
                      1,
                      'virtual',
                      getAccount.idTokenClaims.emails[0]
                    )
                    .toPromise()
                    .then(
                      () => {
                        this.getParams(id);
                      },
                      (error) =>
                        this.messageService.errorMessage({
                          title: `Error ${error.origin}`,
                          texto1:
                            'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente 1',
                        })
                    );
                },
                (error) =>
                  this.messageService.errorMessage({
                    title: `Error ${error.origin}`,
                    texto1:
                      'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente 2',
                  })
              );
          }
          break;
        default:
          break;
      }
      //   },
      //   error => this.messageService.errorMessage({ title: `Error ${error.origin}`, texto1: 'No se pudo establecer conexión con el servidor. Por favor revisa tu conexión a internet e intenta nuevamente' })
      // )
      //Eliminar token de la url
      window.history.pushState(
        'object or string',
        'Title',
        '/' +
        window.location.href
          .substring(window.location.href.lastIndexOf('/') + 1)
          .split('?')[0]
      );
    } else {
      this.getParams(id);
    }
  }

  validateIdentity(id) {
    this.appService.blockUser(id, true).subscribe();
    var texto2 =
      'Para continuar, se te enviará un código de verificación a tu número celular y/o correo electrónico';
    if (localStorage.getItem('origenValidaridentidad') == 'ActualizarDatos') {
      texto2 =
        'Para continuar, se te enviará un código de verificación a tu número celular';
    }
    localStorage.setItem('idUsuario', id);
    this.messageService.visibleMessage(
      {
        imgName: 'info.svg',
        texto1:
          'Por tu seguridad debemos validar tu identidad por ser la primera vez.',
        texto2: texto2,
        texto3: id,
        parametro: '1',
        button: 'Aceptar',
      },
      'validate',
      'Close'
    );
  }
  sendAdmissionNotification(tipoDocumento, id) {
    this.appService.getUserAttributes(id).subscribe((data) => {
      const {
        IdentidadValida,
        extension_ee056c143923412f9188ccd9cd06a04b_Numerodeintentos,
      } = data.atrributes[0];
      if (IdentidadValida) {
        let user = JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem('userMail'),
            environment.constantKey
          ).toString(CryptoJS.enc.Utf8)
        );

        if (parseInt(localStorage.getItem('sendNoti')) != 1) {
          this.appService
            .sendNotification(
              tipoDocumento,
              user.Correo,
              user.NumeroCelular,
              'Login'
            )
            .subscribe(
              (data) => {
                localStorage.setItem('sendNoti', '1');
              },
              () => {
                this.appService
                  .sendNotification(
                    tipoDocumento,
                    user.Correo,
                    user.NumeroCelular,
                    'Login'
                  )
                  .subscribe((data) => {
                    localStorage.setItem('sendNoti', '1');
                  });
              }
            );
        }
      }
    });
  }
  sendUpdateNotification() {
    const {
      idToken: { extension_TipodeDocumento },
    } = this.authService.getAccount();
    let user = JSON.parse(
      CryptoJS.AES.decrypt(
        localStorage.getItem('userMail'),
        environment.constantKey
      ).toString(CryptoJS.enc.Utf8)
    );
    this.appService
      .sendNotification(
        extension_TipodeDocumento,
        user.Correo,
        user.NumeroCelular,
        'Update'
      )
      .subscribe((data) => { });
  }

  updatePersonalData(correo, celular) {
    const {
      idToken: { extension_TipodeDocumento },
    } = this.authService.getAccount();
    this.ModalActualizarPerfilService.updatePersonalData(
      extension_TipodeDocumento,
      correo,
      celular
    ).subscribe(
      (data) => {
        if (data.respuesta.respuestaCelular == 'Número celular actualizado.') {
          this.sendUpdateNotification();
        }
        if (data.respuesta.estado == 'Error técnico') {
          this.messageService.errorMessage({
            title: `Error técnico`,
            texto1:
              'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
          });
        } else {
          this.updateEmailAttribute(correo, celular);
        }
      },
      (error) => {
        // this.dialogRef.close();
        this.messageService.errorMessage({
          title: `Error ${error.origin}`,
          texto1:
            'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
        });
      }
    );
  }

  updateEmailAttribute(correo, celular) {
    this.ModalActualizarPerfilService.updateEmailAttribute(
      correo,
      celular
    ).subscribe(
      () => {
        let user = {
          Correo: correo,
          NumeroCelular: celular,
        };
        localStorage.setItem(
          'userMail',
          CryptoJS.AES.encrypt(
            JSON.stringify(user),
            environment.constantKey
          ).toString()
        );
        // this.dialogRef.close();
        this.messageService.visibleMessage(
          {
            imgName: 'success.svg',
            texto1: 'Datos actualizados exitosamente',
            button: 'Cerrar',
          },
          'Info'
        );
      },
      (error) => {
        // this.dialogRef.close();
        this.messageService.errorMessage({
          title: `Error ${error.origin}`,
          texto1:
            'Tu solicitud no pudo ser completada. Por favor intente nuevamente.',
        });
      }
    );
  }
}
