import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from "rxjs";
import { catchError, tap } from 'rxjs/operators';

import { environment } from "../../../../environments/environment";
import { graph, initialSalesforce } from "../../../constants/api/end-points"
import { salesforceEncabezado } from "../../../constants/api/encabezados";
import { respuestaDelCliente } from "../../../constants/interfaces/salesforceInterfaces"
import * as CryptoJS from "crypto-js"


@Injectable({
  providedIn: 'root'
})
export class DatosSeguroService {

  constructor(private http: HttpClient) { }

  sendResponseCredito(tokenSalesforce: string, tokenB2C: string, body: {}):

  Observable<respuestaDelCliente> {
    let hash = this.encrypt('Respuesta Cliente Autogestion'+','+CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8))

    return this.http.post<respuestaDelCliente>(
      initialSalesforce.respuestaCliente,
      { "encabezado": salesforceEncabezado("Respuesta Cliente Autogestion"), "respuestaClienteAutogestion": body },
      { headers: new HttpHeaders({ 'Header-SF': tokenSalesforce, 'Ocp-Apim-Subscription-Key': environment.SubscriptionKey, 'Authorization': `Bearer ${tokenB2C}`, 'hash':hash }) }
    ).pipe(
      catchError(e => throwError({ origin: 'enviando la respuesta', error: e }))
    )
  }

  encrypt(value: string): string {
    const cipher = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(environment.Semillasecreto), {
      iv: CryptoJS.enc.Utf8.parse(environment.Semillainicialización),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    })

    return cipher.toString()
  }
}
