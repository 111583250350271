<div mat-dialog-title>
    <h6>Eliminar usuario</h6>
    <button mat-dialog-close>X</button>
</div>
<div mat-dialog-content>
    <div *ngIf="!loadingService">
        <p>Está seguro que desea eliminar usuario: {{data.TipodeDocumento}} - {{data.usuario}} </p>
        <div class="buttonsModal">
            <button (click)="confirmar()" class="closeButton" style="background-color: #ffd100">CONFIRMAR</button>
            <button (click)="cancelar()" style="background-color: white">CANCELAR</button>
        </div>
    </div>
    <div *ngIf="loadingService" class="loadingContainer">
        <h2>Eliminando</h2>
        <div class="uploader-status">
            <mat-spinner [diameter]="70"></mat-spinner>
        </div>
    </div>
</div>