import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  onCredit,
  graph,
  B2C,
  initialSalesforce,
} from '../../constants/api/end-points';
import { OAuth2 } from '../../constants/interfaces/salesforceInterfaces';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  updateToken(): Observable<any> {
    return this.http
      .post<any>(
        B2C.tokenApiManagment,
        {
          secret: environment.secretTokenB2C,
          clientId: environment.clientIdTokenB2C,
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          }),
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        mergeMap((tokenB2C) =>
          this.http
            .post<OAuth2>(initialSalesforce.OAuth2, null, {
              headers: new HttpHeaders({
                'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                Authorization: tokenB2C,
              }),
            })
            .pipe(
              tap((data) => {
                localStorage.setItem('tokenSalesforce', data.access_token);
                localStorage.setItem('tokenB2C', tokenB2C);
              }), //the RxJS tap() operator, which looks at the observable values, does something with those values, and passes them along. The tap() call back doesn't touch the values themselves.
              catchError((e) =>
                throwError({ origin: 'obteniendo tokensalesForce', error: e })
              )
            )
        ),
        catchError((e) =>
          throwError({ origin: 'obteniendo token B2C', error: e })
        )
      );
  }

  getUsers(): Observable<any> {
    return this.http
      .post<any>(
        B2C.tokenApiManagment,
        {
          secret: environment.secretTokenB2C,
          clientId: environment.clientIdTokenB2C,
        },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          }),
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        mergeMap((tokenB2C) =>
          this.http
            .post<any>(initialSalesforce.OAuth2, null, {
              headers: new HttpHeaders({
                'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                Authorization: tokenB2C,
              }),
            })
            .pipe(
              tap((tokenSalesforce) => {
                localStorage.setItem(
                  'tokenSalesforce',
                  tokenSalesforce.access_token
                );
                localStorage.setItem('tokenB2C', tokenB2C);
              }),
              mergeMap((tokenSalesforce) =>
                this.http
                  .get<any>(
                    `${graph.listUsers}?attrs=TipoDeValidacion,NumeroDeIntentos,IdentidadValida,NumeroCelular,TipodeDocumento,Correo,FechaUltimoAcceso,UsuarioDesbloqueo,CorreoRegistro,MedioRegistro`,
                    {
                      headers: new HttpHeaders({
                        'Ocp-Apim-Subscription-Key':
                          environment.SubscriptionKey,
                        Authorization: `Bearer ${tokenB2C}`,
                      }),
                    }
                  )
                  .pipe(
                    catchError((e) =>
                      throwError({ origin: 'consulta de usuarios', error: e })
                    ) //3ro
                  )
              ),
              catchError((e) =>
                throwError({ origin: 'consulta token SalesForce', error: e })
              ) //2do
            )
        ),
        catchError((e) =>
          throwError({ origin: 'consulta token B2C', message: e.message })
        ) //1ro
      );
  }

  getUsersSinTokens(tokenB2C: string): Observable<any> {
    return this.http
      .get<any>(
        `${graph.listUsers}?attrs=Tipodevalidacion,NumeroDeIntentos,IdentidadValida,NumeroCelular,TipodeDocumento,Correo,FechaUltimoAcceso,UsuarioDesbloqueo,CorreoRegistro,MedioRegistro,NombredeEmpresaoRazonSocial`,
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${tokenB2C}`,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({ origin: 'consulta de usuarios', error: e })
        )
      );
  }

  loadMore100Users(skipToken: string, tokenB2C: string): Observable<any> {
    return this.http
      .get<any>(
        `${graph.listUsers}?attrs=TipoDeValidacion,NumeroDeIntentos,IdentidadValida,NumeroCelular,TipodeDocumento,Correo,FechaUltimoAcceso,UsuarioDesbloqueo,CorreoRegistro,MedioRegistro,NombredeEmpresaoRazonSocial&skiptoken=${skipToken}`,
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${tokenB2C}`,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({ origin: 'consulta de usuarios adicionales', error: e })
        )
      );
  }

  buscarDocumento(numeroDocumento: string, tokenB2C: string): Observable<any> {
    return this.http
      .get<any>(
        `${graph.listUsers}?attrs=TipodeDocumento,TipoDeValidacion,IdentidadValida&username=${numeroDocumento}`,
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${tokenB2C}`,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({ origin: 'buscando el usuario', error: e })
        )
      );
  }

  updateAtrributes(
    user: string,
    tokenB2C: string,
    valor: boolean,
    tipo: string,
    num: number
  ): Observable<any> {
    return this.http
      .put<any>(
        graph.userStatus,
        { userid: user, enable: valor },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${tokenB2C}`,
          }),
        }
      )
      .pipe(
        mergeMap(() =>
          this.http
            .put<any>(
              graph.userAttributes,
              {
                userid: user,
                attributes: [
                  { key: 'IdentidadValida', value: `${valor}` },
                  { key: 'NumeroDeIntentos', value: `${num}` },
                  { key: 'TipoDeValidacion', value: `${tipo}` },
                ],
              },
              {
                headers: new HttpHeaders({
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  Authorization: `Bearer ${tokenB2C}`,
                }),
              }
            )
            .pipe(
              catchError((e) =>
                throwError({
                  origin: 'consulta de actualización de atributos',
                  error: e,
                })
              )
            )
        ),
        catchError((e) =>
          throwError({
            origin: 'consulta de actualización de atributos',
            error: e,
          })
        )
      );
  }

  getAtrributes(user: string, tokenB2C: string): Observable<any> {
    return this.http
      .post<any>(
        graph.userAttributes +
          '?attrs=TipodeDocumento,Numerodedocumento,TipoDeValidacion',
        { userid: user },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${tokenB2C}`,
          }),
        }
      )
      .pipe(
        catchError((e) =>
          throwError({ origin: 'consulta atributos de usuario', error: e })
        )
      );
  }

  disblockOnCredit(
    extension_TipodeDocumento,
    extension_Numerodedocumento
  ): Observable<any> {
    return this.http
      .post<any>(
        onCredit.login,
        { email: environment.emailCoxti, password: environment.passCoxti },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        mergeMap((tokenLogin) =>
          this.http
            .post<any>(
              onCredit.enableValidate,
              {
                identification: extension_TipodeDocumento,
                id_type: extension_Numerodedocumento,
                id_url: '2',
              },
              {
                headers: new HttpHeaders({
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
                  'Auth-Coxti': tokenLogin.access_token.token,
                }),
              }
            )
            .pipe(
              tap(
                (data) =>
                  (window.location.href =
                    window.location.href +
                    '/identityValidation?token=tokenDummie')
              ),
              catchError((e) =>
                throwError({ origin: 'habilitando formulario', error: e })
              )
            )
        ),
        catchError((e) =>
          throwError({ origin: 'login formulario Coxti', error: e })
        )
      );
  }

  responseForm(tokenEnableForm: string): Observable<any> {
    return this.http
      .post<any>(
        onCredit.login,
        { email: environment.emailCoxti, password: environment.passCoxti },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        mergeMap((tokenLogin) =>
          this.http
            .post<any>(
              onCredit.validationStatus,
              { token: tokenEnableForm },
              {
                headers: new HttpHeaders({
                  Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  'Auth-Coxti': `${tokenLogin.access_token.token}`,
                }),
              }
            )
            .pipe(
              catchError((e) =>
                throwError({
                  origin: 'consulta del estado de validación',
                  message: 'Por favor vuelva a validar la identidad',
                })
              )
            )
        ),
        catchError((e) =>
          throwError({
            origin: 'login formulario Coxti',
            message: 'Por favor vuelva a validar la identidad',
          })
        )
      );
  }

  deleteUser(user: string): Observable<any> {
    return this.http
      .request<any>('delete', graph.listUsers, {
        headers: new HttpHeaders({
          'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
          Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
        }),
        body: { userid: user },
      })
      .pipe(
        catchError((e) => throwError({ origin: 'eliminar cuenta', error: e }))
      );
  }

  updateAtrributesUsuarioDesbloqueo(
    user: string,
    tokenB2C: string,
    usuarioDesbloqueo: string
  ): Observable<any> {
    return this.http
      .put<any>(
        graph.userStatus,
        { userid: user },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${tokenB2C}`,
          }),
        }
      )
      .pipe(
        mergeMap(() =>
          this.http
            .put<any>(
              graph.userAttributes,
              {
                userid: user,
                attributes: [
                  { key: 'UsuarioDesbloqueo', value: `${usuarioDesbloqueo}` },
                ],
              },
              {
                headers: new HttpHeaders({
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  Authorization: `Bearer ${tokenB2C}`,
                }),
              }
            )
            .pipe(
              catchError((e) =>
                throwError({
                  origin: 'consulta de actualización de atributos',
                  error: e,
                })
              )
            )
        ),
        catchError((e) =>
          throwError({
            origin: 'consulta de actualización de atributos',
            error: e,
          })
        )
      );
  }

  updateAtrributesUsuarioNew(
    user: string,
    CorreoRegistro: string,
    MedioRegistro: string
  ): Observable<any> {
    return this.http
      .put<any>(
        graph.userStatus,
        { userid: user },
        {
          headers: new HttpHeaders({
            'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
            Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
          }),
        }
      )
      .pipe(
        mergeMap(() =>
          this.http
            .put<any>(
              graph.userAttributes,
              {
                userid: user,
                attributes: [
                  { key: 'CorreoRegistro', value: `${CorreoRegistro}` },
                  { key: 'MedioRegistro', value: `${MedioRegistro}` },
                  { key: 'Correo', value: `${CorreoRegistro}` },
                ],
              },
              {
                headers: new HttpHeaders({
                  'Ocp-Apim-Subscription-Key': environment.SubscriptionKey,
                  Authorization: `Bearer ${localStorage.getItem('tokenB2C')}`,
                }),
              }
            )
            .pipe(
              catchError((e) =>
                throwError({
                  origin: 'consulta de actualización de atributos',
                  error: e,
                })
              )
            )
        ),
        catchError((e) =>
          throwError({
            origin: 'consulta de actualización de atributos',
            error: e,
          })
        )
      );
  }
}
