<div class="contenedor">
    <div class="header">
        <h1>Solicitar crédito</h1>
        <hr />
        <mat-icon class="back-arrow" (click)="regresar()">arrow_back_ios_new</mat-icon>
    </div>
    <div class="contenido">
        <div class="info-empresa">
            <h6 style="margin-bottom: 2vh">Estás solicitando el crédito para:</h6>
            <div class="nombre">
                <h6>Famiempresa</h6>
                <h6>{{creditoPreaprobado.famiempresa}}</h6>
            </div>
            <div class="nombre">
                <h6>Actividad económica</h6>
                <h6>{{creditoPreaprobado.actividadEconomica}}</h6>
            </div>
        </div>
        <div class="pasos">
            <div class="paso1">
                <div class="circle">
                    <div>1</div>
                </div>
                <h6 style="margin-top: 1vh">Actualizar</h6>
                <h6 style="text-align: center;">datos</h6>
            </div>
            <hr />
            <div class="paso1">
                <div class="circle">
                    <div>2</div>
                </div>
                <h6 style="margin-top: 1vh; padding-left: 0.3em; ">Datos para</h6>
                <h6>desembolso</h6>
            </div>
            <hr />
            <div class="paso2">
                <div class="circle">
                    <div>3</div>
                </div>
                <h6 style="margin-top: 1vh">Datos del</h6>
                <h6 style="text-align: center;">seguro</h6>
            </div>
        </div>
        <div class="texto" style="margin-top: 2vh">
            <h6>Al tomar este crédito adquirirás un seguro.</h6>
            <a href="https://interactuar.org.co/seguro-de-vida-deudores/" target="_blank">Más información</a>
        </div>
        <div class="infoBeneficiarios">
            <img src="../../../assets/Images/info.svg">
            <h6>Ingresa mínimo un beneficiario y máximo tres.</h6>
        </div>
        <div class="beneficiario">
            <form [formGroup]="formSolicitarCreditos2" (ngSubmit)="onSubmit()">
                <div *ngFor="let control of formSolicitarCreditos2.controls; index as i">
                    <div [formGroupName]="i" class="i">
                        <div class="titulo">
                            <h6>Beneficiario N° {{i+1}}</h6>
                            <hr />
                        </div>
                        <div class="field">
                            <h5
                                *ngIf="beneficiarios.controls[i].get('nombreCompleto').invalid && (beneficiarios.controls[i].get('nombreCompleto').touched || beneficiarios.controls[i].get('nombreCompleto').dirty || errorSubmit )">
                                *Por favor ingrese un nombre de beneficiario válido.
                            </h5>
                            <label>Nombre completo del beneficiario<span
                                    style="color: red; font-weight: 500;">*</span></label>
                            <input formControlName="nombreCompleto"
                                placeholder="Ingresa nombre completo del beneficiario">
                        </div>
                        <div class="field">
                            <h5
                                *ngIf="beneficiarios.controls[i].get('tipoDocumento').invalid && (beneficiarios.controls[i].get('tipoDocumento').touched || beneficiarios.controls[i].get('tipoDocumento').dirty || errorSubmit)">
                                *Por favor de seleccione un tipo de identificación.
                            </h5>
                            <label>Tipo de identificación<span style="color: red; font-weight: 500;">*</span></label>
                            <select formControlName="tipoDocumento" id="sel" style="color: #757575"
                                onclick="document.getElementById('sel').style.color='var(--text-dark)'">
                                <option [ngValue]="null" selected disabled hidden>Selecciona</option>
                                <option *ngFor="let documento of tipoDocumento" [ngValue]="documento.nombre">
                                    {{documento.etiqueta}}
                                </option>
                            </select>
                        </div>
                        <div class="field">
                            <h5
                                *ngIf="beneficiarios.controls[i].get('numeroDocumento').invalid && (beneficiarios.controls[i].get('numeroDocumento').touched || beneficiarios.controls[i].get('numeroDocumento').dirty || errorSubmit)">
                                *Por favor valide el número de documento, no se permiten secuencias alfanuméricas.
                            </h5>
                            <label>Número de identificación<span style="color: red; font-weight: 500;">*</span></label>
                            <input formControlName="numeroDocumento" placeholder="Ingresa número de identificación">
                        </div>
                        <div class="field">
                            <h5
                                *ngIf="beneficiarios.controls[i].get('parentesco').invalid && (beneficiarios.controls[i].get('parentesco').touched || beneficiarios.controls[i].get('parentesco').dirty || errorSubmit)">
                                *Por favor de seleccione un parentesco.
                            </h5>
                            <label>Parentesco<span style="color: red; font-weight: 500;">*</span></label>
                            <select formControlName="parentesco" id="sel2" (change)="onChange(i)" style="color: #757575"
                                onclick="document.getElementById('sel2').style.color='var(--text-dark)'">
                                <option [ngValue]="null" selected disabled hidden>Selecciona</option>
                                <option *ngFor="let pariente of parentesco" [ngValue]="pariente.nombre">
                                    {{pariente.etiqueta}}
                                </option>
                            </select>
                        </div>
                        <div class="field" *ngIf="beneficiarios.controls[i].get('parentesco').value === 'Otro'">
                            <h5
                                *ngIf="beneficiarios.controls[i].get('cualParentesco').invalid && (beneficiarios.controls[i].get('cualParentesco').touched || beneficiarios.controls[i].get('cualParentesco').dirty || errorSubmit)">
                                *Al seleccionar "Otro" como valor de parentesco, se debe de indicar cual es.
                            </h5>
                            <label>Otro parentesco<span style="color: red; font-weight: 500;">*</span></label>
                            <input formControlName="cualParentesco" placeholder="Ingresa el otro parentesco">
                        </div>
                        <div class="field">
                            <h5
                                *ngIf="beneficiarios.controls[i].get('porcentaje').invalid && (beneficiarios.controls[i].get('porcentaje').touched || beneficiarios.controls[i].get('porcentaje').dirty || errorSubmit)">
                                *Por favor ingrese un porcentaje válido, sólo se aceptan números y puntos.
                            </h5>
                            <label>Porcentaje asignado<span style="color: red; font-weight: 500;">*</span></label>
                            <div
                                style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
                                <input formControlName="porcentaje" style="width: calc(100% - 2.5em);"
                                    placeholder="Ingresa % de beneficio">
                                <mat-icon class="Info" (click)="showInfo()">info</mat-icon>
                            </div>
                        </div>
                        <div class="delete-beneficiario" (click)="deleteBeneficiario(i)"
                            *ngIf="formSolicitarCreditos2.value.length > 1">
                            <mat-icon class="delete"><span class="material-icons">
                                    remove_circle_outline
                                </span></mat-icon>
                            <h6>Eliminar beneficiario</h6>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="add-beneficiario" (click)="addBeneficiario()" style="margin-top:-0.5vh">
                        <mat-icon class="Add">add_circle_outline</mat-icon>
                        <h6>Agregar beneficiario</h6>
                    </div>
                </div>
                <div class="politica">
                    <h6>Al diligenciar este formulario, acepta nuestras
                        <a href="https://interactuar.org.co/aviso-de-privacidad/" target="_blank">
                            políticas de manejo y tratamiento de datos.
                        </a>
                    </h6>
                </div>
                <div class="buttons">
                    <button class="cancelar" (click)="regresar()" type="button">Regresar</button>
                    <button type="submit" class="continuar">Solicitar crédito</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="message" *ngIf="messageBack">
    <div class="card">
        <button class="x" (click)="continuarSolicitud()">X</button>
        <img src="../../../../assets/Images/warning.svg">
        <h6>Estás a punto de retornar al paso anterior, recuerda que tendrás que volver a llenar los datos
            bancarios del desembolso.</h6>
        <h6>¿Estás seguro de que deseas regresar?</h6>
        <div class="buttons">
            <button class="continuar" (click)="continuarSolicitud()">Continuar solicitud</button>
            <button class="cancelar" (click)="confirmarRegresar()">Si, regresar</button>
        </div>
    </div>
</div>

<div class="message" *ngIf="changePercentage">
    <div class="card">
        <img src="../../../../assets/Images/error.svg">
        <h6>La suma de los porcentajes asignados a los beneficiarios deben sumar el 100%.</h6>
        <div class="percentageBeneficiario">
            <div class="nombre">
                <label>Beneficiario</label>
                <label>Porcentaje </label>
            </div>
            <div *ngFor="let Beneficiario of formSolicitarCreditos2.value; index as i" class="porcentaje">
                <h6>{{Beneficiario.nombreCompleto}}</h6>
                <div class="valor">
                    <input value="{{Beneficiario.porcentaje}}" (input)="Beneficiario.porcentaje = $event.target.value">
                    <!-- (input) = Cada que se modifique el valor del input se modifica el valor del % del beneficiario y por ende el total -->
                    <h5>%</h5>
                </div>
            </div>
        </div>
        <h6 class="total" [ngStyle]="{'color': (totalPercentage()===100) ? 'green' : 'red'}">
            PORCENTAJE TOTAL: {{totalPercentage()}}%
        </h6>
        <div class="buttons">
            <button class="continuar" (click)="onSubmit()" [disabled]="totalPercentage()!==100">
                Continuar solicitud
            </button>
            <button class="cancelar" (click)="closeChangePercentage()">Regresar</button>
        </div>
    </div>
</div>