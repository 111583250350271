<div *ngIf="FNGJuridico" class="alert alert-warning">
  <p> No es posible generar el certificado para el crédito <strong>{{data.credito}}</strong> sólo lo podrá solicitar a
    través de nuestra línea 018000417019, escribirnos al WhatsApp <a href="https://wa.link/b55nmb" target="_blank">320 606 4004</a> o acércate a nuestra
    oficina más cercana.</p>
</div>

<div *ngIf="data.msnPersonalizado" class="alert alert-warning">
  <p><strong>{{data.msnPersonalizadoText}}</strong></p>
</div>

<div *ngIf="NoExisteData" class="alert alert-warning">
  <p>No existe información para generar el criticado.</p>
</div>

<div *ngIf="errorCreditos" class="alert alert-danger">
  <p>Error: <strong>{{data.mensaje.toUpperCase()}}</strong>, comunícate con nosotros para dar solución, llamando al 018000417019 o escríbenos al WhatsApp <a href="https://wa.link/b55nmb" target="_blank">
      320 606 4004</a></p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>OK</button>  
</div>