<div class="contenido">
  <div *ngIf="loadingDataTable" class="cargando-data">
    <h2 style="color: var(--text-semiDark)">Cargando información</h2>
    <div class="uploader-status">
      <mat-spinner [diameter]="70"></mat-spinner>
    </div>
  </div>

  <div class="render-data" [hidden]="loadingDataTable">
    <div class="buscar">
      <form [formGroup]="formNumeroDocumento" (ngSubmit)="onSubmitSearch()">
        <input
          formControlName="numeroDocumento"
          placeholder="Ingrese el número de documento a buscar"
          (input)="saverange()"
        />
        <button
          type="button"
          class="x"
          (click)="removeSearch()"
          *ngIf="documentoABuscar.value.length > 0 && !buscando"
        >
          X
        </button>
        <button type="submit">Buscar</button>
      </form>
      <h6
        *ngIf="
          documentoABuscar.value.length > 0 && !buscando && !resultadoDeBusqueda
        "
        style="text-align: center; font-weight: 300"
      >
        Por favor ingresa el número de documento completo y presiona buscar
      </h6>
      <h6 class="errorMonto" *ngIf="errorDocumentoVacio">
        *Por favor ingresar un número de documento.
      </h6>
      <div class="buscando" *ngIf="buscando">
        <h5>Buscando</h5>
        <div class="uploader-status">
          <mat-spinner [diameter]="40"></mat-spinner>
        </div>
      </div>
      <div class="descargarUsuarios" *ngIf="superAdmin">
        <button (click)="descargarUsuarios()" *ngIf="!loadingDownloadFIle">
          Descargar usuarios
        </button>
        <button disabled class="upload-status" *ngIf="loadingDownloadFIle">
          <mat-spinner [diameter]="25"></mat-spinner>
        </button>
      </div>
      <div
        class="resultadoDeBusqueda"
        *ngIf="
          documentoABuscar.value.length > 0 && !buscando && resultadoDeBusqueda
        "
      >
        <h6 style="font-weight: 300; margin-bottom: 2vh">
          Mostrando resultados de búsqueda para {{ documentoABuscar.value }}:
        </h6>
        <h6 style="text-align: center" *ngIf="busquenaNoEncontrada">
          No se econtraron resultados para el documento ingresado
        </h6>
        <div
          class="table-container mat-elevation-z8"
          *ngIf="!busquenaNoEncontrada"
        >
          <table mat-table [dataSource]="dataBusqueda">
            <ng-container matColumnDef="TipodeDocumento">
              <th mat-header-cell *matHeaderCellDef>Tipo de documento</th>
              <td mat-cell *matCellDef="let row">{{ row.TipodeDocumento }}</td>
            </ng-container>

            <ng-container matColumnDef="displayName">
              <th mat-header-cell *matHeaderCellDef>Número de documento</th>
              <td mat-cell *matCellDef="let row">
                {{ row.identities[0].issuerAssignedId }}
              </td>
            </ng-container>

            <ng-container matColumnDef="accountEnabled">
              <th mat-header-cell *matHeaderCellDef style="padding-left: 20px">
                Estado
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                style="font-weight: 500"
                [ngStyle]="{
                  color: row.accountEnabled === true ? 'green' : 'red'
                }"
              >
                {{ row.accountEnabled === true ? "HABILITADO" : "BLOQUEADO" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="TipoDeValidacion">
              <th mat-header-cell *matHeaderCellDef>Tipo de validación</th>
              <td mat-cell *matCellDef="let row">
                {{
                  row.IdentidadValida
                    ? row.IdentidadValida === true
                      ? "virtual"
                      : "No posee"
                    : "No posee"
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="EstadoDeValidacion">
              <th mat-header-cell *matHeaderCellDef>Estado de validación</th>
              <td mat-cell *matCellDef="let row">
                {{
                  row.IdentidadValida
                    ? row.IdentidadValida === true
                      ? "validada"
                      : "no validada"
                    : null
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="MotivoBloqueo">
              <th mat-header-cell *matHeaderCellDef>Motivo Bloqueo</th>
              <td mat-cell *matCellDef="let row">
                {{ row.MotivoBloqueo ? row.MotivoBloqueo : null }}
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th
                mat-header-cell
                *matHeaderCellDef
                style="display: flex; padding: 21px 0; justify-content: center"
              >
                Acción
              </th>
              <td mat-cell *matCellDef="let row" class="action">
                <div *ngIf="!row.accountEnabled" class="habilitar">
                  <!-- <button *ngIf="row.TipodeDocumento !== 'Nit'" style="margin-right: 0.5vw" (click)="habilitarOnCredit(row.id)">
                                        Habilitar onCredit
                                    </button> -->
                  <button (click)="habilitarPresencial(row.id)">
                    Habilitar presencial y/o telefónicamente
                  </button>
                </div>
                <button
                  *ngIf="row.accountEnabled"
                  class="bloqueo"
                  (click)="bloquearUsuario(row.id)"
                  style="margin-top: 7px; margin-bottom: 7px"
                >
                  Bloquear
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="options">
              <th mat-header-cell *matHeaderCellDef>Opciones</th>
              <td mat-cell *matCellDef="let row">
                <button
                  class="deleteButton"
                  (click)="
                    eliminarUsuario(
                      row.id,
                      row.TipodeDocumento,
                      row.identities[0].issuerAssignedId
                    )
                  "
                >
                  <mat-icon class="downArrow">delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>

    <div
      class="table-container mat-elevation-z8"
      [hidden]="documentoABuscar.value.length > 0"
    >
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="TipodeDocumento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Tipo de documento
          </th>
          <td mat-cell *matCellDef="let row">{{ row.TipodeDocumento }}</td>
        </ng-container>

        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Número de documento
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.identities[0].issuerAssignedId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="accountEnabled">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="padding-left: 20px"
          >
            Estado
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            style="font-weight: 500"
            [ngStyle]="{ color: row.accountEnabled === true ? 'green' : 'red' }"
          >
            {{ row.accountEnabled === true ? "HABILITADO" : "BLOQUEADO" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="TipoDeValidacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Tipo de validación
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              row.IdentidadValida
                ? row.IdentidadValida === true
                  ? "virtual"
                  : "No posee"
                : "No posee"
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="EstadoDeValidacion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Estado de validación
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              row.IdentidadValida
                ? row.IdentidadValida === true
                  ? "validada"
                  : "no validada"
                : null
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="MotivoBloqueo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Motivo Bloqueo
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.MotivoBloqueo ? row.MotivoBloqueo : null }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="display: flex; padding: 21px 0; justify-content: center"
          >
            Acción
          </th>
          <td mat-cell *matCellDef="let row" class="action">
            <div *ngIf="!row.accountEnabled" class="habilitar">
              <!-- <button *ngIf="row.TipodeDocumento !== 'Nit'" style="margin-right: 0.5vw" (click)="habilitarOnCredit(row.id)">
                                Habilitar onCredit
                            </button> -->
              <button (click)="habilitarPresencial(row.id)">
                Habilitar presencial y/o telefónicamente
              </button>
            </div>
            <button
              *ngIf="row.accountEnabled"
              class="bloqueo"
              (click)="bloquearUsuario(row.id)"
              style="margin-top: 7px; margin-bottom: 7px"
            >
              Bloquear
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="options" *ngIf="superAdmin">
          <th mat-header-cell *matHeaderCellDef>Opciones</th>
          <td mat-cell *matCellDef="let row">
            <button
              class="deleteButton"
              (click)="
                eliminarUsuario(
                  row.id,
                  row.TipodeDocumento,
                  row.identities[0].issuerAssignedId
                )
              "
            >
              <mat-icon class="downArrow">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
      <div class="cargandoMasUsuarios" *ngIf="loadingMoreUsers">
        <div class="uploader-status">
          <mat-spinner [diameter]="30"></mat-spinner>
        </div>
        <h6 style="margin-left: 3vw; margin-bottom: 0; color: var(--text-dark)">
          Cargando más usuarios
        </h6>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 25, 50, 100]"
        (page)="pageChange()"
      ></mat-paginator>
    </div>
  </div>
</div>
