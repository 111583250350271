import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { LoadingInfoService } from "../../reusableComponents/loading-info/loading-info.service"

@Component({
  selector: 'app-not-found-component',
  templateUrl: './not-found-component.component.html',
  styleUrls: ['./not-found-component.component.css']
})
export class NotFoundComponentComponent implements OnInit {
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;

  constructor(private loadingService: LoadingInfoService) { }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((value) => this.loadingInfo = value)
    this.loadingService.stopLoading()
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
