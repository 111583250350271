import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../../home/home.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../../environments/environment';
import { MsalService } from '@azure/msal-angular';
import { ModalCertificadoPazysalvoComponent } from '../../certificados/modal-certificado-pazysalvo/modal-certificado-pazysalvo.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorCertificadosComponent } from '../../certificados/error-certificados/error-certificados.component';
import { Subscription } from 'rxjs';
import { LoadingInfoService } from '../../../reusableComponents/loading-info/loading-info.service';
import { MessagesService } from '../../../reusableComponents/messages/messages.service';
import * as moment from 'moment';

export class Credits {
  origenDatos: string;
  fechaDesembolso: string;
  fechaCancelacion: string;
  estado: string;
  credito: string;
  cliente: {
    tipoId: string;
    nroId: string;
  };
}

@Component({
  selector: 'app-pazysalvo',
  templateUrl: './pazysalvo.component.html',
  styleUrls: ['./pazysalvo.component.css'],
})
export class PazysalvoComponent implements OnInit {
  tipoDocumento: string = '';
  numeroDocumento: string = '';
  descargar = false;
  datos = {};
  creditos: Credits;
  nombreCliente: string = '';
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  dataPaz: any;
  creditosPazYSalvo = [];
  fechaActual = new Date();

  //Configuracion de tablas paz y salvo
  displayedColumnsPaz: string[] = ['credito', 'estado', 'option'];
  datosPaz: Credits[] = [];
  dataSourcePaz: any;

  seleccion = new SelectionModel<Credits>(false, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private router: Router,
    private authService: MsalService,
    private homeService: HomeService,
    public Modal: MatDialog,
    private loadingService: LoadingInfoService,
    private messageService: MessagesService
  ) {}

  regresar() {
    localStorage.setItem('origenCertificado', '1');
    this.router.navigate(['/']);
  }

  getCreditos() {
    const mesesCacelacion = environment.mesesCacelacion;
    var fecha = new Date().toLocaleString('es-CL', {
      timeZone: 'America/Bogota',
    });
    var fechaActual = moment(fecha, 'DD-MM-YYYY');
    this.homeService
      .obtenerCreditos(this.tipoDocumento, this.numeroDocumento)
      .subscribe((data) => {
        const creditos = data.response.creditosVigCanClienteSalesForce.creditos;
        //Todos los créditos en Estado ‘VIGENTE’,’VENCIDO’ o ‘CASTIGADO’
        const creditosPazYSalvoEstados = creditos.filter(
          (estado) =>
            estado.estado.toUpperCase() == 'VIGENTE' ||
            estado.estado.toUpperCase() == 'VENCIDO' ||
            estado.estado.toUpperCase() == 'CASTIGADO' ||
            estado.estado.toUpperCase() == 'ACTIVO'
        );
        if (creditosPazYSalvoEstados.length > 0) {
          for (
            let index = 0;
            index < creditosPazYSalvoEstados.length;
            index++
          ) {
            const element = creditosPazYSalvoEstados[index];
            this.creditosPazYSalvo.push(element);
          }
        }

        //Todos los créditos en Estado ‘CANCELADO’ y cuya fechaCancelacion sea menor o igual a 12 meses (valor 12 parametrizable).
        const creditosPazYSalvoCancelados = creditos.filter(
          (cancelado) =>
            cancelado.estado.toUpperCase() == 'CANCELADO' &&
            fechaActual.diff(
              moment(cancelado.fechaCancelacion, 'YYYY-MM-DD'),
              'M'
            ) <= parseInt(mesesCacelacion.toString())
        );
        if (creditosPazYSalvoCancelados.length > 0) {
          for (
            let index = 0;
            index < creditosPazYSalvoCancelados.length;
            index++
          ) {
            const element = creditosPazYSalvoCancelados[index];
            this.creditosPazYSalvo.push(element);
          }
        }

        this.dataSourcePaz = new MatTableDataSource<any>(
          this.creditosPazYSalvo
        );
        this.dataSourcePaz.paginator = this.paginator;
      });
  }

  //seleccion de credito
  onCreditToggled(elemento: Credits) {
    this.seleccion.toggle(elemento);
    if (this.seleccion.selected.length > 0) {
      this.descargar = true;
    } else {
      this.descargar = false;
    }
  }

  zfill(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */
    var zero = '0'; /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return '-' + numberOutput.toString();
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return '-' + zero.repeat(width - length) + numberOutput.toString();
      } else {
        return zero.repeat(width - length) + numberOutput.toString();
      }
    }
  }

  openModalPazysalvo(): void {
    this.loadingService.startLoading();
    let seleccionado = this.seleccion.selected[0];

    if (
      seleccionado.origenDatos == 'SIIF' &&
      seleccionado.estado.toUpperCase() == 'CANCELADO'
    ) {
      var credito = this.zfill(Number(seleccionado.credito), 17);

      this.homeService.getToken().subscribe((data) => {
        this.homeService
          .consultarCreditoDetallePazYSalvo(
            credito,
            seleccionado.cliente.tipoId,
            seleccionado.cliente.nroId,
            data.request.token
          )
          .subscribe(
            (data) => {
              if (data.response.encabezado.mensaje.estado == 'ERROR') {
                this.loadingService.stopLoading();

                if (
                  data.response.encabezado.mensaje.descripcion ==
                    'Credito Juridico' ||
                  data.response.encabezado.mensaje.descripcion == 'Credito FNG'
                ) {
                  this.messageService.visibleMessage(
                    {
                      title: '',
                      imgName: 'error.svg',
                      texto1: ``,
                      msnCreditosJuridico: true,
                      button: 'Cerrar',
                      enlace: 'https://interactuar.org.co/oficinas/',
                      parametro: credito,
                    },
                    'Info'
                  );
                } else if (
                  data.response.encabezado.mensaje.descripcion ==
                  'No hay registros que cumplan las condiciones'
                ) {
                  this.messageService.visibleMessage(
                    {
                      title: '',
                      imgName: 'error.svg',
                      texto1: ``,
                      msnCreditosinregistro: true,
                      button: 'Cerrar',
                      parametro: credito,
                    },
                    'Info'
                  );
                } else {
                  this.messageService.visibleMessage(
                    {
                      title: 'Error inesperado',
                      imgName: 'error.svg',
                      texto1: `${data.response.encabezado.mensaje.descripcion}`,
                      msnCreditosSelect: true,
                      //msnServiceError: true,
                      button: 'Cerrar',
                    },
                    'Info'
                  );
                }
              } else {
                if (window.innerWidth <= 700) {
                  this.decarga(data.response.documento);
                } else {
                  this.datos = { base64: data.response.documento };
                  this.loadingService.stopLoading();
                  const dialogRef = this.Modal.open(
                    ModalCertificadoPazysalvoComponent,
                    {
                      data: this.datos,
                      maxWidth: '98vw',
                      maxHeight: '95vh',
                      panelClass: 'openDetalleContainer',
                    }
                  );
                }
              }
            },
            () => {
              this.homeService.getToken().subscribe((data) => {
                this.homeService
                  .consultarCreditoDetallePazYSalvo(
                    credito,
                    seleccionado.cliente.tipoId,
                    seleccionado.cliente.nroId,
                    data.request.token
                  )
                  .subscribe(
                    (data) => {
                      if (data.response.encabezado.mensaje.estado == 'ERROR') {
                        this.loadingService.stopLoading();

                        if (
                          data.response.encabezado.mensaje.descripcion ==
                            'Credito Juridico' ||
                          data.response.encabezado.mensaje.descripcion ==
                            'Credito FNG'
                        ) {
                          this.messageService.visibleMessage(
                            {
                              title: '',
                              imgName: 'error.svg',
                              texto1: ``,
                              msnCreditosJuridico: true,
                              button: 'Cerrar',
                              enlace: 'https://interactuar.org.co/oficinas/',
                              parametro: credito,
                            },
                            'Info'
                          );
                        } else if (
                          data.response.encabezado.mensaje.descripcion ==
                          'No hay registros que cumplan las condiciones'
                        ) {
                          this.messageService.visibleMessage(
                            {
                              title: '',
                              imgName: 'error.svg',
                              texto1: ``,
                              msnCreditosinregistro: true,
                              button: 'Cerrar',
                              parametro: credito,
                            },
                            'Info'
                          );
                        } else {
                          this.messageService.visibleMessage(
                            {
                              title: 'Error inesperado',
                              imgName: 'error.svg',
                              texto1: `${data.response.encabezado.mensaje.descripcion}`,
                              //msnServiceError: true,
                              msnCreditosSelect: true,
                              button: 'Cerrar',
                            },
                            'Info'
                          );
                        }
                      } else {
                        if (window.innerWidth <= 700) {
                          this.decarga(data.response.documento);
                        } else {
                          this.datos = { base64: data.response.documento };
                          this.loadingService.stopLoading();
                          const dialogRef = this.Modal.open(
                            ModalCertificadoPazysalvoComponent,
                            {
                              data: this.datos,
                              maxWidth: '98vw',
                              maxHeight: '95vh',
                              panelClass: 'openDetalleContainer',
                            }
                          );
                        }
                      }
                    },
                    (error) => {
                      this.loadingService.stopLoading();
                      this.messageService.visibleMessage(
                        {
                          //title: 'Error inesperado',
                          imgName: 'error.svg',
                          texto1: ``,
                          msnServiceError: true,
                          //msnCreditosSelect: true,
                          button: 'Cerrar',
                        },
                        'Info'
                      );
                    }
                  );
              });
            }
          );
      });
    } else if (
      seleccionado.origenDatos == 'SIIF' &&
      seleccionado.estado.toUpperCase() !== 'CANCELADO'
    ) {
      var credito = this.zfill(Number(seleccionado.credito), 17);

      this.homeService.getToken().subscribe((data) => {
        this.homeService
          .consultarCreditoDetalleEstadoDeuda(
            credito,
            seleccionado.cliente.tipoId,
            seleccionado.cliente.nroId,
            data.request.token
          )
          .subscribe(
            (data) => {
              if (data.response.encabezado.mensaje.estado == 'ERROR') {
                this.loadingService.stopLoading();

                if (
                  data.response.encabezado.mensaje.descripcion ==
                    'Credito Juridico' ||
                  data.response.encabezado.mensaje.descripcion == 'Credito FNG'
                ) {
                  this.messageService.visibleMessage(
                    {
                      title: '',
                      imgName: 'error.svg',
                      texto1: ``,
                      msnCreditosJuridico: true,
                      button: 'Cerrar',
                      enlace: 'https://interactuar.org.co/oficinas/',
                      parametro: credito,
                    },
                    'Info'
                  );
                } else if (
                  data.response.encabezado.mensaje.descripcion ==
                  'No hay registros que cumplan las condiciones'
                ) {
                  this.messageService.visibleMessage(
                    {
                      title: '',
                      imgName: 'error.svg',
                      texto1: ``,
                      msnCreditosinregistro: true,
                      button: 'Cerrar',
                      parametro: credito,
                    },
                    'Info'
                  );
                } else {
                  this.messageService.visibleMessage(
                    {
                      title: 'Error inesperado',
                      imgName: 'error.svg',
                      texto1: `${data.response.encabezado.mensaje.descripcion}`,
                      //msnServiceError: true,
                      msnCreditosSelect: true,
                      button: 'Cerrar',
                    },
                    'Info'
                  );
                }
              } else {
                if (window.innerWidth <= 700) {
                  this.decarga(data.response.documento);
                } else {
                  this.datos = { base64: data.response.documento };
                  this.loadingService.stopLoading();
                  const dialogRef = this.Modal.open(
                    ModalCertificadoPazysalvoComponent,
                    {
                      data: this.datos,
                      maxWidth: '98vw',
                      maxHeight: '95vh',
                      panelClass: 'openDetalleContainer',
                    }
                  );
                }
              }
            },
            (error) => {
              this.loadingService.stopLoading();
              this.messageService.visibleMessage(
                {
                  //title: 'Error inesperado',
                  imgName: 'error.svg',
                  texto1: ``,
                  msnServiceError: true,
                  //msnCreditosSelect: true,
                  button: 'Cerrar',
                },
                'Info'
              );
            }
          );
      });
    } else {
      let url = '';
      if (seleccionado.estado !== 'CANCELADO') {
        url = 'deuda/v1-0';
      } else {
        url = 'pazysalvo/v1-0';
      }

      this.homeService
        .consultarCreditoPazysalvo(
          seleccionado.credito,
          seleccionado.cliente.tipoId,
          seleccionado.cliente.nroId,
          url
        )
        .subscribe(
          (data) => {
            if (data.response.encabezado.mensaje.estado == 'ERROR') {
              this.loadingService.stopLoading();
              this.messageService.visibleMessage(
                {
                  //title: 'Error inesperado',
                  imgName: 'error.svg',
                  texto1: ``,
                  //msnCreditosSelect: true,
                  msnServiceError: true,
                  button: 'Cerrar',
                },
                'Info'
              );
            } else {
              if (window.innerWidth <= 700) {
                this.decarga(
                  data.response.certificadoPazySalvoORACLE
                    .certificadoPazySalvoPDF
                );
              } else {
                this.datos = {
                  base64:
                    data.response.certificadoPazySalvoORACLE
                      .certificadoPazySalvoPDF,
                };
                this.loadingService.stopLoading();
                const dialogRef = this.Modal.open(
                  ModalCertificadoPazysalvoComponent,
                  {
                    data: this.datos,
                    maxWidth: '98vw',
                    maxHeight: '95vh',
                    panelClass: 'openpazysalvoContainer',
                  }
                );
              }
            }
          },
          (error) => {
            this.messageService.visibleMessage(
              {
                //title: 'Error inesperado',
                imgName: 'error.svg',
                texto1: ``,
                msnServiceError: true,
                //msnCreditosSelect: true,
                button: 'Cerrar',
              },
              'Info'
            );
          }
        );
    }
  }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe(
      (value) => (this.loadingInfo = value)
    );
    this.messageSubscription = this.messageService.messageStatus.subscribe(
      (value) => (this.messageVisble = value)
    );
    const {
      idToken: { given_name, extension_TipodeDocumento },
    } = this.authService.getAccount();
    [this.numeroDocumento, this.tipoDocumento] = [
      CryptoJS.AES.decrypt(
        localStorage.getItem('user'),
        environment.constantKey
      ).toString(CryptoJS.enc.Utf8),
      extension_TipodeDocumento,
    ];
    [this.nombreCliente, this.numeroDocumento, this.tipoDocumento] = [
      given_name,
      CryptoJS.AES.decrypt(
        localStorage.getItem('user'),
        environment.constantKey
      ).toString(CryptoJS.enc.Utf8),
      extension_TipodeDocumento,
    ];
    this.getCreditos();
    //this.getToken()
    this.loadingService.stopLoading();
    this.dataSourcePaz = new MatTableDataSource<any>(this.dataPaz);
    this.dataSourcePaz.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
  }

  decarga(base64: any) {
    const data = 'data:application/octet-stream;base64,' + base64;
    const downloadLink = document.createElement('a');
    const fileName = `${new Date().toISOString()}.pdf`;
    downloadLink.href = data;
    downloadLink.download = fileName;
    downloadLink.click();
    this.loadingService.stopLoading();
  }

  getToken() {
    setTimeout(() => {
      this.getToken();
    }, 300000);

    this.homeService.getToken().subscribe(
      (data) => {
        var fecha = new Date().toLocaleString('es-CL', {
          timeZone: 'America/Bogota',
        });
        var fecha1 = moment(fecha, 'YYYY-MM-DD HH:mm:ss');
        var fecha2 = moment(data.request.fecha, 'YYYY-MM-DD HH:mm:ss');
        var minutos = fecha2.diff(fecha1, 'm');
        if (minutos < 0) {
          minutos = minutos * -1;
        }
        if (minutos >= 10) {
          this.homeService.getTokenSIIF().subscribe((data) => {
            localStorage.setItem('tokenSIIF', data.acces_token);
            this.homeService.setToken(data.acces_token).subscribe((data) => {});
          });
        } else {
          localStorage.setItem('tokenSIIF', data.request.token);
        }
      },
      (error) => {}
    );
  }
}
