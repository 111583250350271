export const environment = {
  ambiente: "prd",
  production: "true",
  constantKey: "Le!koA3vwX2ker@4!jfI6GoMsNaEPBglA4!!sZBW",
  emailCoxti: "sistematizado@interactuar.org.co",
  passCoxti: "interactuar()=?**;",
  clientIdOnCredit: "3MVG9A2kN3Bn17hto3il3.Q7Fa0Fe3Gyyf9Wp1R3xVVpbRSuxgbHnH0EmDRStgiOT_wjeBSocCUBEwSGhYX2z",
  clientSecretOnCredit: "14E26875ADE0CC57B718189A7370632D4055565789198B414135F84DC0D65C73",
  userNameOncredit: "integraciones2@interactuar.org.co",
  identityValidationCoxti: "https://interactuar.coxti.com/identityValidation?token=",
  passwordOncredit: "1nt3r890",
  secretTokenB2C: "mTO8Q~.GKz.p7ckqU-5-FeXIfdqu1M~pHndHVcMg",
  clientIdTokenB2C: "5f63f9a6-acff-4dd9-a8f9-fa9e526d6ffa",
  SubscriptionKey: "20d2dc7b5b3248fead50838bbc754835",
  urlBase: "https://creditodigital.interactuar.org.co",
  urlbaseB2C: "interactuarb2c",
  urlBaseApiManagement:"api.interactuar.org.co",
  clientIdB2c: "25c37e56-cbd1-44bd-be6c-769d3aaf7fa6",
  mesesCacelacion: 12,
  minutosCodigoOTP: 4,
  Semillainicialización: "LP83606c26eUc7q9",
  Semillasecreto: "Zf3c146c2y70cb9v8d907b1e0917c35X",
  UrlJsonServer: "https://micrositioback-jsonserver.azurewebsites.net",
  tokenApiManagment: "https://api.interactuar.org.co/api/b2c/pdn/v1.0.0/Token",
  userFlowsLogin: "https://interactuarb2c.b2clogin.com/interactuarb2c.onmicrosoft.com/B2C_1_AUTOGESTION_LoginRegistro",
  userFlowRecoverPassword:"https://interactuarb2c.b2clogin.com/interactuarb2c.onmicrosoft.com/B2C_1_AUTOGESTION_RecuperarContrasena",
  b2cScopes:"https://interactuarb2c.onmicrosoft.com/25c37e56-cbd1-44bd-be6c-769d3aaf7fa6",
  webApi:"https://api.interactuar.org.co.azure-api.net/credito/v1.0.0/speakers",
  userAttributes: "https://api.interactuar.org.co/api/graph/pdn/v1.0.0/UserAttributes",
  userStatus: "https://api.interactuar.org.co/api/graph/pdn/v1.0.0/UserStatus",
  listUsers: "https://api.interactuar.org.co/api/graph/pdn/v1.0.0/Users",
  sendNotification: "https://api.interactuar.org.co/api/salesforce/prd/v1.0.0/services/apexrest/notification",
  simulador:"https://apimplataformadigitalpdn.azure-api.net/api/oracle/v1.0.0"

};
