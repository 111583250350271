import { Component, OnInit, Inject } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog"
import { HomeService } from '../../home/home.service';
import { Subscription } from 'rxjs';
import { LoadingInfoService } from "../../../reusableComponents/loading-info/loading-info.service";
import { MessagesService } from "../../../reusableComponents/messages/messages.service";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { style } from '@angular/animations';


@Component({
  selector: 'app-modal-estado-credito',
  templateUrl: './modal-estado-credito.component.html',
  styleUrls: ['./modal-estado-credito.component.css']
})
export class ModalEstadoCreditoComponent implements OnInit {
  messageVisble: boolean = false;
  messageSubscription: Subscription;
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  public movil = false;
  totaDescueto = 0
  totalCapital = 0
  totalInteres = 0
  totaMora = 0
  totalOtrosConceptos
  paginaActual = 1
  paginaFina = 1
  page = 0;
  data_header
  data_1
  data_2
  data_3
  data_4
  data_5
  loadingDownloadFIle: boolean = true
  isMobileDevice: boolean = true
  fecha = new Date();
  hours = this.fecha.getHours();
  hoursF = ((this.hours + 11) % 12 + 1);
  valor: number 
  cuota: number
  valorAlAdia: number 
  valorcancelacion: number
  saldoCapital: number 
  interesCausado: number
  interesPorMora: number 
  otrosConceptos: number
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      creditoData: {
        creditoDataRecords: [
          {
            encabezado: [
              {
                email: string,
                operacion: string,
                origen: string,
                destino: string,
                fecha: string,
                credito: string
              }
            ],
            credito: [
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
              {
                name: string,
                value: string
              },
            ],
            creditoDataRecords: [
              {
                name: string,
                value: [
                  {
                    P_Fecha_Pago: string,
                    p_ident: string,
                    P_Fecha_Dcto: string,
                    P_Nombre_Dcto: string,
                    P_tipo_dcto: string,
                    P_valor_Dcto: string,
                    P_fecha_vto_cta: string,
                    P_nro_cta: string,
                    P_capital: string,
                    P_intereses: string,
                    P_Mora: string,
                    P_Dias_mora: string
                  }
                ]
              },
              {
                name: string,
                value: [
                  {
                    P_Fecha_Pago: string
                    p_ident: string
                    P_Fecha_Dcto: string
                    P_tipo_dcto: string
                    P_Nombre_Dcto: string
                    P_valor_Dcto: string
                    P_nro_cta: string
                    P_concepto: string
                    P_valor_concepto: string
                  }
                ]
              },
              {
                name: string,
                value: [
                  {
                    P_Fecha_Pago: string,
                    P_Fecha_Dcto: string,
                    P_Nombre_Dcto: string,
                    P_tipo_dcto: string,
                    P_valor_Dcto: string,
                    P_nro_cta: string,
                    P_capital: string,
                    P_intereses: string,
                    P_Mora: string,
                    P_ident: string
                  }
                ]
              },
              {
                name: string,
                value: [
                  {
                    P_Fecha_Pago: string,
                    P_Fecha_Dcto: string,
                    P_tipo_dcto: string,
                    P_Nombre_Dcto: string,
                    P_valor_Dcto: string,
                    P_nro_cta: string,
                    P_cocepto: string,
                    P_valor_concepto: string,
                    P_ident: string
                  }
                ]
              },
              {
                name: string,
                value: [
                  {
                    P_valor_aldia: string,
                    P_Valor_cancelacion_total: string,
                    p_proxima_fecha_pago: string,
                    p_fecha_cuota_mas_vencida: string,
                    P_saldo_capital: string,
                    P_interes_causado: string,
                    P_interes_mora: string,
                    P_otros_conceptos: string,
                    p_ident: string
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    public Modal: MatDialog,
    private homeService: HomeService,
    private loadingService: LoadingInfoService,
    private messageService: MessagesService

  ) {
    for (let index = 0; index < data.creditoData.creditoDataRecords[0].creditoDataRecords[0].value.length; index++) {
      this.totaDescueto = this.totaDescueto + Number(data.creditoData.creditoDataRecords[0].creditoDataRecords[0].value[index].P_valor_Dcto)
      this.totalCapital = this.totalCapital + Number(data.creditoData.creditoDataRecords[0].creditoDataRecords[0].value[index].P_capital)
      this.totalInteres = this.totalInteres + Number(data.creditoData.creditoDataRecords[0].creditoDataRecords[0].value[index].P_intereses)
      this.totaMora = this.totaMora + Number(data.creditoData.creditoDataRecords[0].creditoDataRecords[0].value[index].P_Mora)
    }

    for (let index = 0; index < data.creditoData.creditoDataRecords[0].creditoDataRecords[1].value.length; index++) {
      this.totalOtrosConceptos = this.totalOtrosConceptos + Number(data.creditoData.creditoDataRecords[0].creditoDataRecords[1].value[index].P_valor_concepto)
    }   

  }

  downloadPDF() {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((value) => this.loadingInfo = value)
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value)
    // Extraemos el
    const DATA = document.getElementById('htmlData');
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      background: 'white',
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      //Add Page 2

      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}.pdf`);
    });
    //this.loadingService.stopLoading();
  }

  cerrarModal() {
    this.Modal.closeAll();
  }

  ngOnInit(): void {
   this.valor = Number(this.data.creditoData.creditoDataRecords[0].credito[6].value)
   this.cuota = Number(this.data.creditoData.creditoDataRecords[0].credito[11].value)
   this.valorAlAdia = Number(this.data.creditoData.creditoDataRecords[0].creditoDataRecords[4].value[0].P_valor_aldia)
   this.valorcancelacion = Number(this.data.creditoData.creditoDataRecords[0].creditoDataRecords[4].value[0].P_Valor_cancelacion_total)
   this.saldoCapital =  Number(this.data.creditoData.creditoDataRecords[0].creditoDataRecords[4].value[0].P_saldo_capital)
   this.interesCausado = Number(this.data.creditoData.creditoDataRecords[0].creditoDataRecords[4].value[0].P_interes_causado)
   this.interesPorMora =  Number(this.data.creditoData.creditoDataRecords[0].creditoDataRecords[4].value[0].P_interes_mora)
   this.otrosConceptos = Number(this.data.creditoData.creditoDataRecords[0].creditoDataRecords[4].value[0].P_otros_conceptos)

    setTimeout(() => {
      this.cargarData()
    }, 1000)

  }


  ngOnDestroy(): void {
  }

  descargar() {

    /* let DATA = document.getElementById('htmlData');
        var doc = new jsPDF();
      var options = {
        pagesplit: false 
      };
      //let doc = new jsPDF('p', 'mm', 'a4');
      doc.html(DATA, {
        callback: function (doc) {
          doc.save();
        },
        x: 10,
        y: 10,
        options
      }); */

    if (window.innerWidth > 600) {
      this.loadingService.startLoading();
      this.Modal.closeAll();
    }

    let DATA: any = document.getElementById('htmlData');

    html2canvas(DATA).then((canvas) => {

      let fileWidth = 193;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let getHeight = PDF.internal.pageSize.getHeight();
      PDF.addImage(FILEURI, 'PNG', 10, 10, fileWidth, fileHeight);
     

      if (fileHeight > getHeight) {
        fileHeight = fileHeight - getHeight
        PDF.addPage();
        PDF.addImage(FILEURI, 'PNG', 10, 10, fileWidth, fileHeight);
      }

      if (window.innerWidth < 600) {
        window.open(PDF.output('bloburl'), '_blank');
      } else {
        PDF.save(`${new Date().toISOString()}.pdf`);
      }
      //PDF.addImage(FILEURI2, 'PNG', 10, 10, fileWidth, fileHeight);
      //PDF.output(`${new Date().toISOString()}.pdf`);   
    });

    setTimeout(() => this.loadingService.stopLoading(), 5000);

  }

  descargar2() {

    let DATA: any = document.getElementById('htmlData');

    html2canvas(DATA).then((canvas) => {
      let doc = new jsPDF("p", "mm", "a4");

      let a4Size = {
        w: this.convertPointsToUnit(595.28, "px"),
        h: this.convertPointsToUnit(841.89, "px")
      };

      let canvastoPrint = document.createElement("canvas");
      let ctx = canvastoPrint.getContext("2d");
      canvastoPrint.width = a4Size.w;
      canvastoPrint.height = a4Size.h;

      // Como mi ancho es mas grande y lo redimencionare, tomo cuanto corresponde esos 595 de el total de mi imagen
      let aspectRatioA4 = a4Size.w / a4Size.h;
      let rezised = canvas.width / aspectRatioA4;

      let printed = 0

      while (printed < canvas.height) {
        ctx.drawImage(
          canvas,
          0,
          printed,
          canvas.width,
          rezised,
          0,
          0,
          a4Size.w,
          a4Size.h
        );

        var imgtoPdf = canvastoPrint.toDataURL("image/png");
        let width = doc.internal.pageSize.getWidth() - 8;
        let height = doc.internal.pageSize.getHeight() - 8;
        if (this.page == 0) {
          // si es la primera pagina, va directo a doc
          doc.addImage(imgtoPdf, "JPEG", 8, 8, width, height);
        } else {
          // Si no ya tengo que agregar nueva hoja.
          let page = doc.addPage();
          page.addImage(imgtoPdf, "JPEG", 8, 8, width, height);
        }
        ctx.clearRect(0, 0, canvastoPrint.width, canvastoPrint.height); // Borro el canvas
        printed += rezised; //actualizo lo que ya imprimi
        this.page++; // actualizo mi pagina

      }

      doc.save("test.pdf");

    });
  }

  convertPointsToUnit(points, unit) {
    // Unit table from https://github.com/MrRio/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L791
    var multiplier;
    switch (unit) {
      case "pt":
        multiplier = 1;
        break;
      case "mm":
        multiplier = 72 / 25.4;
        break;
      case "cm":
        multiplier = 72 / 2.54;
        break;
      case "in":
        multiplier = 72;
        break;
      case "px":
        multiplier = 96 / 72;
        break;
      case "pc":
        multiplier = 12;
        break;
      case "em":
        multiplier = 12;
        break;
      case "ex":
        multiplier = 6;
      default:
        throw "Invalid unit: " + unit;
    }
    return points * multiplier;
  }

  export() {

    var padin = -525
    this.getDispositivo()

    if(this.isMobileDevice){
      var padin = -525
    }

    var docDefinition = {       
      pageMargins:235,      
        header: [
        {
          image: this.data_header,
          width: 500,
          margin: 50
        }        
      ], 
      
      footer: function (currentPage, pageCount) {
        return {
            table: {
                body: [
                    [
                        { text: "fjdghjkfhgkjfdhgkjfdhgfkjhgjkfhgjfdhgkdjfghkdfghdfkjghfdkgjhehjehfjdhfjdfdjffhgjfhukb", color:'#FEFEFE'},
                        { text: "Página " + currentPage.toString() + ' de ' + pageCount, alignment: 'right', margin: [0, padin, 50, 0], color:'#555353', fontSize:10}
                    ],
                ]
            },
            layout: 'noBorders'
        };
    }, 
        
      content: [{
        image: this.data_1,
        width: 500, 
        marginLeft:-175                 
      },
      {
        image: this.data_2,
        width: 500,
        marginLeft:-175
      },
      {
        image: this.data_3,
        width: 500,
        marginLeft:-175
      },
      {
        image: this.data_4,
        width: 500,
        marginLeft:-175
      },
      {
        image: this.data_5,
        width: 500,
        marginLeft:-182
      }                
      ],
                  
    };
    pdfMake.createPdf(docDefinition).download(`${new Date().toISOString()}.pdf`);
  }

  cargarData() {

    html2canvas(document.getElementById('data_header')).then((canvas) => {
      this.data_header = canvas.toDataURL('image/png');
    });
    html2canvas(document.getElementById('htmlData')).then((canvas) => {
      this.data_1 = canvas.toDataURL('image/png');
    });
    html2canvas(document.getElementById('htmlData1')).then((canvas) => {
      this.data_2 = canvas.toDataURL('image/png');
    });
    html2canvas(document.getElementById('htmlData2')).then((canvas) => {
      this.data_3 = canvas.toDataURL('image/png');
    });
    html2canvas(document.getElementById('htmlData3')).then((canvas) => {
      this.data_4 = canvas.toDataURL('image/png');
    });
    html2canvas(document.getElementById('htmlData4')).then((canvas) => {
      this.data_5 = canvas.toDataURL('image/png');
      setTimeout(() => {
        this.loadingDownloadFIle = false;
      }, 1000)
      
    });
    
  }

  getDispositivo(){
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    this.isMobileDevice = regexp.test(details);     
 }

 convetToNumber(Valor){
  return Number(Valor)
 }

}
