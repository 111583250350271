import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../../home/home.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import * as CryptoJS from "crypto-js";
import { environment } from "../../../../environments/environment";
import { MsalService } from '@azure/msal-angular';
import { ModalCertificadoPazysalvoComponent } from "../../certificados/modal-certificado-pazysalvo/modal-certificado-pazysalvo.component";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from 'rxjs';
import { LoadingInfoService } from "../../../reusableComponents/loading-info/loading-info.service";
import { MessagesService } from "../../../reusableComponents/messages/messages.service";
import * as moment from 'moment';
import { log } from 'console';


export class Credits {
  origenDatos: string;
  fechaDesembolso: string;
  fechaCancelacion: string;
  estado: string;
  credito: string;
  migrado: string;
  cliente: {
    tipoId: string;
    nroId: string;
  }
}

@Component({
  selector: 'app-anograbable',
  templateUrl: './anograbable.component.html',
  styleUrls: ['./anograbable.component.css']
})
export class AnograbableComponent implements OnInit {

  messageSubscription: Subscription;
  loadingInfo: boolean = false;
  loadingSubscription: Subscription;
  messageVisble: boolean = false;
  anosGrabableSelect = 0
  anosGrabables = new Array;
  descargarA = false
  numeroDocumento: string
  tipoDocumento: string
  nombreCliente: string
  datos = {}
  dataSourceAnio: any
  displayedColumnsPaz: string[] = ['credito', 'estado', 'option'];
  fechaActual = new Date()
  creditosYEstados: any
  creditosAnoGrabable: any = []
  seleccion = new SelectionModel<Credits>(false, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  descargar = false
  descargaPDF = 0


  constructor(
    private router: Router,
    private authService: MsalService,
    private homeService: HomeService,
    public Modal: MatDialog,
    private loadingService: LoadingInfoService,
    private messageService: MessagesService) { }

  regresar() {
    localStorage.setItem("origenCertificado", "1");
    this.router.navigate(["/"]);
  }

  getanos() {
    const anoPasado = this.fechaActual.getFullYear() - 1
    const anoGravable = anoPasado.toString()
    this.homeService.obtenerCreditos(this.tipoDocumento, this.numeroDocumento).subscribe(
      data => {
        const creditos = data.response.creditosVigCanClienteSalesForce.creditos

        //Todos los créditos en Estado ‘VIGENTE’,’VENCIDO’, ’ACTIVO’ o ‘CASTIGADO’
        const creditosPazYSalvoEstados = creditos.filter(estado => (estado.estado.toUpperCase() == "VIGENTE" || estado.estado.toUpperCase() == "VENCIDO" || estado.estado.toUpperCase() == "CASTIGADO" || estado.estado.toUpperCase() == "ACTIVO") && estado.fechaDesembolso.substring(0, 4) < this.fechaActual.getFullYear());
        if (creditosPazYSalvoEstados.length > 0) {
          for (let index = 0; index < creditosPazYSalvoEstados.length; index++) {
            const element = creditosPazYSalvoEstados[index];
            this.creditosAnoGrabable.push(element)
          }
        }

        //Todos los créditos en Estado ‘CANCELADO’ y cuya fechaCancelacion sea menor o igual a 12 meses (valor 12 parametrizable).
        const creditosPazYSalvoCancelados = creditos.filter(cancelado => (cancelado.estado.toUpperCase() == "CANCELADO") && cancelado.fechaCancelacion.substring(0, 4) == anoGravable);
        if (creditosPazYSalvoCancelados.length > 0) {
          for (let index = 0; index < creditosPazYSalvoCancelados.length; index++) {
            const element = creditosPazYSalvoCancelados[index];
            this.creditosAnoGrabable.push(element)
          }
        }

        //Todos los créditos en Estado ‘CANCELADO’ en el año actual pero que vaya el año gravable no estaban ‘CANCELADO’
        const creditosPazYSalvoCanceladosAnoActual = creditos.filter(cancelado => (cancelado.estado.toUpperCase() == "CANCELADO") && cancelado.fechaCancelacion.substring(0, 4) == this.fechaActual.getFullYear() && cancelado.fechaDesembolso.substring(0, 4) < this.fechaActual.getFullYear());
        if (creditosPazYSalvoCanceladosAnoActual.length > 0) {
          for (let index = 0; index < creditosPazYSalvoCanceladosAnoActual.length; index++) {
            const element = creditosPazYSalvoCanceladosAnoActual[index];
            this.creditosAnoGrabable.push(element)
          }
        }

        this.dataSourceAnio = new MatTableDataSource<any>(this.creditosAnoGrabable);
        this.dataSourceAnio.paginator = this.paginator;

      });
  }

  certicadoAnoGrabable(ano) {
    if (ano !== "") {
      this.descargarA = true
      this.anosGrabableSelect = ano;
    } else {
      this.descargarA = false
    }

  }

  //seleccion de credito
  onCreditToggled(elemento: Credits) {
    this.seleccion.toggle(elemento);
    if (this.seleccion.selected.length > 0) {
      this.descargar = true;
    } else {
      this.descargar = false;
    }
  }

  zfill(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */
    var zero = "0"; /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return ("-" + numberOutput.toString());
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
      } else {
        return ((zero.repeat(width - length)) + numberOutput.toString());
      }
    }
  }

  descargarAnoGrabable() {
    this.loadingService.startLoading();
    let seleccionado = this.seleccion.selected[0];
    let currentTime = new Date();
    let year = currentTime.getFullYear()
    if (seleccionado.origenDatos == 'SIIF' && seleccionado.migrado == 'NO') {
      var credito = this.zfill(Number(seleccionado.credito), 17)
      this.homeService.getToken().subscribe(
        data => {
          this.homeService.consultarCreditoDetalleAnoGravable(credito, seleccionado.cliente.tipoId, seleccionado.cliente.nroId, data.request.token,year-1).subscribe(
            data => {
              if (data.response.encabezado.mensaje.estado == "ERROR") {
                this.loadingService.stopLoading();
                this.messageService.visibleMessage(
                  {
                    title: 'Error inesperado',
                    imgName: "error.svg",
                    texto1: `${data.response.encabezado.mensaje.descripcion}`,
                    //msnServiceError: true,
                    msnCreditosSelect: true,
                    button: "Cerrar",
                  },
                  "Info"
                )
              } else {

                this.getPDF(data.response.encabezado.mensaje.idDocumento);

                /*if (window.innerWidth <= 700) {
                  this.decarga(data.response.documento)
                } else {
                  this.datos = { base64: data.response.documento }
                  this.loadingService.stopLoading();
                  const dialogRef = this.Modal.open(ModalCertificadoPazysalvoComponent, {
                    data: this.datos,
                    maxWidth: '98vw',
                    maxHeight: '95vh',
                    panelClass: 'openDetalleContainer',
                  });
                }*/
              }

            }, error => {
              this.loadingService.stopLoading();
              this.messageService.visibleMessage(
                {
                  //title: 'Error inesperado',
                  imgName: "error.svg",
                  texto1: ``,
                  msnServiceError: true,
                  //msnCreditosSelect: true,
                  button: "Cerrar",
                },
                "Info"
              )
            })
        })

    } else {

      this.homeService.getAnoGrabable(year - 1, seleccionado.credito).subscribe(
        data => {
          if (data.response.encabezado.mensaje.estado == "ERROR") {
            this.loadingService.stopLoading();
            this.messageService.visibleMessage(
              {
                //title: 'Error inesperado',
                imgName: "error.svg",
                texto1: ``,
                msnServiceError: true,
                //msnCreditosSelect: true,
                button: "Cerrar",
              },
              "Info"
            )
          } else {
            if (window.innerWidth <= 700) {
              this.decarga(data.response.certificadoAnioGravableORACLE.certificadoAnioGravablePDF)
            } else {
              this.datos = { base64: data.response.certificadoAnioGravableORACLE.certificadoAnioGravablePDF }
              this.loadingService.stopLoading();
              const dialogRef = this.Modal.open(ModalCertificadoPazysalvoComponent, {
                data: this.datos,
                maxWidth: '98vw',
                maxHeight: '95vh',
                panelClass: 'openDetalleContainer',
              });
            }
          }
        }, error => {
          this.loadingService.stopLoading();
          this.messageService.visibleMessage(
            {
              //title: 'Error inesperado',
              imgName: "error.svg",
              texto1: ``,
              msnServiceError: true,
              //msnCreditosSelect: true,
              button: "Cerrar",
            },
            "Info"
          )

          /* this.Modal.open(ErrorCertificadosComponent, {
            data: {
              msnPersonalizadoText: 'El sistema no le puede generar su certificado, ya que no encontró ningún crédito con Interactuar para el año que solicita.',
              msnPersonalizado: true
            },
            maxWidth: '70vw',
            maxHeight: '95vh',
            panelClass: 'openDetalleContainer',
          }); */
        });
    }

  }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingService.loadingStatus.subscribe((value) => this.loadingInfo = value)
    this.messageSubscription = this.messageService.messageStatus.subscribe(value => this.messageVisble = value)
    const { idToken: { given_name, extension_TipodeDocumento } } = this.authService.getAccount();
    [this.numeroDocumento, this.tipoDocumento] = [CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8), extension_TipodeDocumento];
    [this.nombreCliente, this.numeroDocumento, this.tipoDocumento] = [given_name, CryptoJS.AES.decrypt(localStorage.getItem("user"), environment.constantKey).toString(CryptoJS.enc.Utf8), extension_TipodeDocumento];
    this.getanos()
    //this.getToken()
    this.loadingService.stopLoading();
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
  }

  decarga(base64: any) {
    const data = 'data:application/octet-stream;base64,' + base64
    const downloadLink = document.createElement('a');
    const fileName = `${new Date().toISOString()}.pdf`;
    downloadLink.href = data;
    downloadLink.download = fileName;
    downloadLink.click();
    this.loadingService.stopLoading();
  }
  getToken() {
    setTimeout(() => {
      this.getToken();
    }, 300000);

    this.homeService.getToken().subscribe(
      data => {
        var fecha = new Date().toLocaleString('es-CL', { timeZone: 'America/Bogota' })
        var fecha1 = moment(fecha, "YYYY-MM-DD HH:mm:ss");
        var fecha2 = moment(data.request.fecha, "YYYY-MM-DD HH:mm:ss");
        var minutos = fecha2.diff(fecha1, 'm')
        if (minutos < 0) {
          minutos = minutos * -1;
        }
        if (minutos >= 5) {
          this.homeService.getTokenSIIF().subscribe(data => {
            localStorage.setItem("tokenSIIF", data.acces_token);
            this.homeService.setToken(data.acces_token).subscribe(data => {

            })
          })
        } else {
          localStorage.setItem("tokenSIIF", data.request.token);
        }

      },
      error => { }
    )
  }

  getPDF(idDocumento) {
    this.homeService.getPDFSIIF(idDocumento).subscribe(
      data => {
        if (data.length == 0) {
          this.descargaPDF++
          var idTimeout = setTimeout(() => {
            this.getPDF(idDocumento);
          }, 3000);

          if (this.descargaPDF >= 36) {
            this.descargaPDF = 0
            this.loadingService.stopLoading();
            clearTimeout(idTimeout);
            this.messageService.visibleMessage(
              {
                //title: 'Error inesperado',
                imgName: "error.svg",
                texto1: ``,
                msnServiceError: true,
                //msnCreditosSelect: true,
                button: "Cerrar",

              },
              "Info"
            )
          }

        } else if (data[0].request.encabezado.mensaje.estado == 'EXITO') {
          clearTimeout(idTimeout);
          this.descargaPDF = 0
          this.deletePDF(data[0].id)
          if (window.innerWidth <= 700) {
            this.decarga(data[0].request.documento)
          } else {
            this.datos = { base64: data[0].request.documento }
            this.loadingService.stopLoading();
            const dialogRef = this.Modal.open(ModalCertificadoPazysalvoComponent, {
              data: this.datos,
              maxWidth: '98vw',
              maxHeight: '95vh',
              panelClass: 'openDetalleContainer',
            });
          }

        } else if (data[0].request.encabezado.mensaje.estado == 'ERROR') {

          clearTimeout(idTimeout);
          this.descargaPDF = 0
          this.deletePDF(data[0].id)
          this.loadingService.stopLoading();
          this.messageService.visibleMessage(
            {
              //title: 'Error inesperado',
              imgName: "error.svg",
              texto1: ``,
              msnServiceError: true,
              //msnCreditosSelect: true,
              button: "Cerrar",
            },
            "Info"
          )
        }
      })
  }

  deletePDF(idDocumento) {
    this.homeService.deletePDFSIIF(idDocumento).subscribe(
      data => {
      })
  }

}
